import styled from "styled-components";

export const Container = styled.div`
  background-color: ${(props) =>
    props.type === "analysis"
      ? "#DFEEF5"
      : props.type === "normal"
      ? "#E8F5F3"
      : props.type === "changed" || props.type === "changedNoSelected"
      ? "#F8E7E7"
      : "#F9F9F9"};
  border-radius: 8px;
  display: flex;
  flex-direction: row;
  height: 150px;
  min-width: 360px;
  width: 32%;
  padding: 16px;
  margin-bottom: 4px;
  border: ${(props) => (props.changedSelected ? "solid 1px #C73734" : "none")};

  :hover {
    opacity: ${(props) => (props.type === "changed" ? "0.7" : "1")};
    cursor: ${(props) => (props.type === "changed"  ? "pointer" : "normal")};
  }
`;

export const TypeContainer = styled.div``;

export const PercentageContainer = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  justify-content: end;
  align-items: flex-end;
`;

export const PercentageText = styled.p`
  margin: 0;
  padding: 0;
  font-size: 60px;
  color: ${(props) =>
    props.type === "analysis"
      ? "none"
      : props.type === "normal"
      ? "#B6DEDA"
      : props.type === "changed" || props.type === "changedNoSelected"
      ? "#E9B2B1"
      : "none"};
  line-height: 60px;
`;

export const LeftInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const HeaderInfo = styled.div`
  display: flex;
  margin-top: 10px;
`;

export const NumberInfo = styled.div`
  color: ${(props) =>
    props.type === "analysis"
      ? "#0073AB"
      : props.type === "normal"
      ? "#40A99E"
      : props.type === "changed" || props.type === "changedNoSelected"
      ? "#C73734"
      : "none"};
  font-size: 48px;
  margin-top: 6px;
`;

export const ImageInfo = styled.img`
  width: 24px;
  height: 24px;
  margin-right: 16px;
  user-select: none;
  pointer-events: none;
`;

export const TextHeader = styled.p`
  margin: 0;
  padding: 0;
  color: #000000;
  font-size: 18px;
`;
