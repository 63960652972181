import React, { createContext, useState, useContext, useEffect } from "react";

const GeneralContext = createContext();

const DEFAULT_TIME_REQUEST = 15000;

const GeneralProvider = ({ children }) => {
  let timingRequests;

  const [resetTimeout, setResetTimeout] = useState(false);
  const [timerFunction, setTimerFunction] = useState(false);
  const [automaticUpdate, setAutomaticUpdate] = useState(false);
  const [updateCharts, setUpdateCharts] = useState(false);

  const [loading, setLoading] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const [panelAccess, setPanelAccess] = useState([]);
  const [selectedPage, setSelectedPage] = useState("/");
  const [currentSubtitle, setCurrentSubtitle] = useState("");
  const [globalUser, setGlobalUser] = useState(null);
  const [signInError, setSignInError] = useState(null);
  const [forgotPasswordError, setForgotPasswordError] = useState(null);
  const [confirmForgotPasswordError, setConfirmForgotPasswordError] =
    useState(null);
  const [completeRegistrationError, setCompleteRegistrationError] =
    useState(null);
  const [completeUserData, setCompleteUserData] = useState({
    email: "",
    receivedUser: "",
  });

  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    timingRequests = setTimeout(() => {
      handleResetTimeout();
      if (!loading && automaticUpdate) {
        setTimerFunction(new Date());
      }
    }, DEFAULT_TIME_REQUEST);

    return () => clearTimeout(timingRequests);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resetTimeout]);

  const handleResetTimeout = () => {
    setResetTimeout(!resetTimeout);
    clearTimeout(timingRequests);
  };

  const updateManually = () => {
    setTimerFunction(new Date());
  };

  const setUser = (value) => {
    try {
      setGlobalUser(value);
      const jsonValueString = JSON.stringify(value);
      localStorage.setItem("@user", jsonValueString);
    } catch (e) {}
  };

  const getUser = () => {
    try {
      const jsonValue = localStorage.getItem("@user");
      if (jsonValue !== null) {
        setGlobalUser(JSON.parse(jsonValue));
        return;
      }

      setGlobalUser(null);
    } catch (e) {}
  };

  return (
    <GeneralContext.Provider
      value={{
        isAdmin,
        loading,
        globalUser,
        panelAccess,
        signInError,
        updateCharts,
        selectedPage,
        timerFunction,
        currentSubtitle,
        automaticUpdate,
        completeUserData,
        forgotPasswordError,
        completeRegistrationError,
        confirmForgotPasswordError,
        setConfirmForgotPasswordError,
        setCompleteRegistrationError,
        setForgotPasswordError,
        setCompleteUserData,
        handleResetTimeout,
        setAutomaticUpdate,
        setCurrentSubtitle,
        setSelectedPage,
        setUpdateCharts,
        setSignInError,
        updateManually,
        setPanelAccess,
        setLoading,
        setIsAdmin,
        setUser,
        getUser,
      }}
    >
      {children}
    </GeneralContext.Provider>
  );
};

function useGeneralProvider() {
  const context = useContext(GeneralContext);

  if (!context) {
    throw new Error(
      "useGeneralProvider must be used within an GeneralProvider."
    );
  }

  return context;
}

export { GeneralProvider, useGeneralProvider };
