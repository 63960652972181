import styled from "styled-components";

export const Container = styled.div`
  padding-top: 40px;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
`;

export const TitleText = styled.p`
  margin: 0 0 10px 0;
  padding: 0;
  color: #38393d;
  font-size: 16px;
`;

export const ContentWrapper = styled.div`
  width: calc(100% - 200px);
`;

export const FilterContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-bottom: 20px;
`;

export const FooterContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 32px 0 32px 0;
`;

export const ButtonsContainer = styled.div`
  display: flex;
`;

export const HeaderBackSection = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 24px;
`;

export const HeaderBackArrowImg = styled.img`
  width: 32px;
  height: 32px;
  margin-right: 8px;
  cursor: pointer;

  :hover {
    opacity: 0.6;
  }
`;

export const HeaderBackText = styled.p`
  color: #38393d;
  font-size: 20px;
  font-weight: 500;
`;
