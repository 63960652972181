import styled from "styled-components";
import { styled as materialStyled } from "@mui/material/styles";
import { Accordion, AccordionSummary, AccordionDetails } from "@mui/material";

const CustomDivScroll = styled.div`
  ::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }
  ::-webkit-scrollbar-button {
    width: 0px;
    height: 0px;
  }
  ::-webkit-scrollbar-thumb {
    background: #0073ab;
    border-radius: 20px;
  }
  ::-webkit-scrollbar-thumb:hover {
    background: #0073ab;
  }
  ::-webkit-scrollbar-thumb:active {
    background: #0073ab;
  }
  ::-webkit-scrollbar-track {
    background: transparent;
    border-radius: 50px;
  }
  ::-webkit-scrollbar-track:hover {
    background: transparent;
  }
  ::-webkit-scrollbar-track:active {
    background: transparent;
  }
  ::-webkit-scrollbar-corner {
    background: transparent;
  }
`;

export const GlobalContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const GroupContainer = styled(CustomDivScroll)`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow: auto;
  padding-right: 20px;
`;

export const HeaderContainer = styled.div`
  display: flex;
  align-items: flex-end;

  button {
    margin-left: 20px;
  }
`;

export const InputGroup = styled.div`
  display: flex;
  flex-direction: column;
  width: 300px;

  input {
    min-width: 300px;
  }
`;

export const InputLabel = styled.p`
  color: #1a1a1a;
  font-size: 16px;
  margin-bottom: 6px;
`;

export const SelectionContainer = styled.div`
  width: 100%;
  margin-top: 20px;
  margin-bottom: 20px;
  display: grid;
  grid-template-columns: repeat(auto-fill, 160px);
  gap: 4px 4px;
`;

export const AddedGridItem = styled.div`
  width: 160px;
  height: 160px;
`;

export const AddedImageContainer = styled.div`
  position: relative;
  user-select: none;
`;

export const ContainerGalleryVideo = styled.div`
  display: flex;
  position: relative;
  
  :hover {
    opacity: 0.7;
  }
`;

export const PlayVideo = styled.div`
  cursor: pointer;
  position: absolute;
  z-index: 999;
  top: 40px;
  left: 40px;
`;

export const AddedImage = styled.img`
  position: absolute;
  width: 160px;
  height: 160px;
  min-width: 160px;
  min-height: 160px;
  object-fit: cover;
  border: 1px solid #e1e1e1;
  border-radius: 20px;

  :last-of-type {
    margin: 0;
  }
`;

export const RemoveImageContainer = styled.div`
  background-color: #ff2f2f;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  position: absolute;
  top: 10px;
  right: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  svg {
    fill: #ffffff;
    width: 12px;
    height: 12px;
  }

  :hover {
    opacity: 0.7;
  }
`;

export const DiagnosisContainer = styled.div`
  background-color: #fbfbfb;
`;

export const DiagnosisHeader = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  padding: 10px;
`;

export const DiagnosisBody = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  margin-top: 20px;
  padding-bottom: 20px;

  :first-of-type {
    padding: 0;
  }
`;

export const DiagnosisTitle = styled.p`
  font-size: 18px;
  color: #1a1a1a;
  font-weight: bold;
`;

export const DiagnosisSubTitle = styled.p`
  font-size: 16px;
  color: #1a1a1a;
  margin-left: 10px;
`;

export const CustomAccordion = materialStyled(Accordion)({
  border: `1px solid #EAEAEA`,
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&:before": {
    display: "none",
  },
});

export const CustomAccordionSummary = materialStyled(AccordionSummary)({
  backgroundColor: "#FBFBFB",
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper": {
    "& svg": {
      width: "28px",
      height: "28px",
    },
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: "5px",
  },
});

export const CustomAccordionDetails = materialStyled(AccordionDetails)({
  padding: "10px",
  borderTop: "1px solid rgba(0, 0, 0, .125)",
  fontSize: "12px",
  color: "#525252",
});

export const GalleryContainer = styled(CustomDivScroll)`
  height: 100%;
  min-width: fit-content;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-left: 1px solid #e1e1e1;
  padding-left: 20px;
  padding-right: 20px;
  overflow-y: auto;
`;

export const GalleryImageContainer = styled.div`
  margin-bottom: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  user-select: none;
`;

export const GalleryImage = styled.img`
  width: 160px;
  height: 160px;
  min-width: 160px;
  min-height: 160px;
  object-fit: cover;
  border: 1px solid #e1e1e1;
  border-radius: 20px;

  :last-of-type {
    margin: 0;
  }
`;

export const AddImageContainer = styled.span`
  height: 32px;
  width: 32px;
  min-height: 32px;
  min-width: 32px;
  border-radius: 50%;
  border: ${({ disabled }) =>
    disabled ? "2px solid #e1e1e1" : "2px solid #0073ab"};
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 20px;
  cursor: ${({ disabled }) => (disabled ? "unset" : "pointer")};

  svg {
    fill: ${({ disabled }) => (disabled ? "#e1e1e1" : "#0073ab")};
  }

  :hover {
    opacity: ${({ disabled }) => (disabled ? 1 : 0.7)};
  }
`;

export const EmptyContainer = styled.div`
  margin-top: 10px;
  width: 100%;
  font-size: 12px;
`;
