import ReactSlider from "react-slider";
import styled from "styled-components";

const CustomDivScroll = styled.div`
  ::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }
  ::-webkit-scrollbar-button {
    width: 0px;
    height: 0px;
  }
  ::-webkit-scrollbar-thumb {
    background: #0073ab;
    border-radius: 20px;
  }
  ::-webkit-scrollbar-thumb:hover {
    background: #0073ab;
  }
  ::-webkit-scrollbar-thumb:active {
    background: #0073ab;
  }
  ::-webkit-scrollbar-track {
    background: transparent;
    border-radius: 50px;
  }
  ::-webkit-scrollbar-track:hover {
    background: transparent;
  }
  ::-webkit-scrollbar-track:active {
    background: transparent;
  }
  ::-webkit-scrollbar-corner {
    background: transparent;
  }
`;

export const Container = styled(CustomDivScroll)`
  background-color: #fefefe;
  width: 100%;
  height: 100%;
  overflow: auto;
  padding: 0 10px;`

export const ContainerKonva = styled.div`
    display: grid;
    grid-template-columns: 85% 15%;
    grid-template-rows: 85vh auto auto;
    grid-column-gap: 10px;
    overflow-x: hidden;

  ::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }
  ::-webkit-scrollbar-button {
    width: 0px;
    height: 0px;
  }
  ::-webkit-scrollbar-thumb {
    background: #0073ab;
    border-radius: 20px;
  }
  ::-webkit-scrollbar-thumb:hover {
    background: #0073ab;
  }
  ::-webkit-scrollbar-thumb:active {
    background: #0073ab;
  }
  ::-webkit-scrollbar-track {
    background: transparent;
    border-radius: 50px;
  }
  ::-webkit-scrollbar-track:hover {
    background: transparent;
  }
  ::-webkit-scrollbar-track:active {
    background: transparent;
  }
  ::-webkit-scrollbar-corner {
    background: transparent;
  }

  @media(max-width: 1200px){
        grid-template-columns: 80% 20%;
  }

  @media(min-width: 1600px){
        grid-template-columns: 90% 10%;
  }

    @media(min-width: 2000px){
        grid-template-columns: 90% 10%;
  }

    @media(min-width: 2200px){
        grid-template-columns: 93% 7%;
  }
`

export const WrapperKonva = styled.div`
    grid-column: 1/2;
    grid-row: 1/2;
    border: dashed 1px;
    `

export const Wrapper = styled.div`
    height: 100%;
    grid-column: 2/2;
    grid-row: 1/1;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-end;
    padding: 0 10px;
`
export const SliderContainer = styled.div`
  grid-column: 1/1;
  grid-row: 2/2;
  display: flex;
  align-items: center;
  margin: 10px 0;
`;

export const StyledSlider = styled(ReactSlider)`
  height: 25px;
  display: flex;
  align-items: center;
  width: 100%;
`;

export const TempContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  user-select: none;
  margin: 0 5px;
  height: 100%;

`;


const StyledThumb = styled.div`
  height: 25px;
  line-height: 25px;
  width: 50px;
  text-align: center;
  background-color: #000;
  color: #fff;
  border-radius: 6px;
  cursor: grab;
`;

export const Thumb = (props, state) => (
    <StyledThumb {...props}>{state.valueNow.toFixed(2)}</StyledThumb>
);

const StyledTrack = styled.div`
  height: 10px;
  display: flex;
  align-items: center;
  background: ${(props) =>
        props.index === 2 ? "#ddd" : props.index === 1 ? "#0073AB" : "#ddd"};
  border-radius: 6px;
`;

export const Track = (props, state) => (
    <StyledTrack {...props} index={state.index} />
);

export const ToolsContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 36px;
  align-items: flex-end;
`;

export const NoImageText = styled.p`
  font-size: 16px;
`;

export const SavedImageContainer = styled.div`
  display: flex;
`;

export const SavedImageElement = styled.img`
  max-width: 100%;
  max-height: 100%;
`;

export const DeltaBtnWrapper = styled.div`
  margin-left: 40px;
  display: ${(props) => props.galleryLeft ? 'flex' : 'none'};
  flex-direction: row;
  align-items: center;
`;

export const CleanDeltaTextBtn = styled.p`
  font-size: 14px;
  cursor: pointer;
  text-decoration: underline;
  margin-left: 20px;
`;

export const ContainerButtonToggle = styled.div`
  display: flex;
  padding-left: 20px;
`;

export const ContainerFooter = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  grid-column: 1/2;
  grid-row: 3/4;
`