import React from "react";

import { Container } from "./styles";

function TabOption({ label, selectedTab, setSelectedTab, tabValue }) {
  return (
    <Container
      onClick={() => setSelectedTab(tabValue)}
      isSelected={selectedTab === tabValue}
    >
      {label}
    </Container>
  );
}

export default TabOption;
