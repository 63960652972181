import React, { useState } from "react";

// Styles
import { Container } from "./styles";

export default function TextArea(props) {
  const {
    value,
    defaultValue,
    placeholder,
    onChange,
    onBlur,
    inputError,
    inputSuccess,
    isPassword,
    maxLength,
    autoComplete,
    disabled,
  } = props;
  const [inputFocused, setInputFocused] = useState(false);

  return (
    <Container
      disabled={disabled}
      inputSuccess={inputSuccess}
      inputError={inputError}
      isOnFocus={inputFocused}
      isPassword={isPassword}
    >
      <textarea
        defaultValue={defaultValue}
        disabled={disabled}
        maxLength={maxLength}
        value={value}
        onBlur={(e) => {
          if (onBlur) {
            onBlur(e);
          }
          setInputFocused(false);
        }}
        autoComplete={autoComplete}
        onFocus={() => setInputFocused(true)}
        onChange={onChange}
        placeholder={placeholder}
      />
    </Container>
  );
}
