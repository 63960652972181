import styled from "styled-components";

export const Container = styled.div`
  display: flex;
`;

export const CustomButton = styled.button`
  padding: 0 10px 0 10px;
  height: 40px;
  width: 40px;
  color: ${(props) => (!props.selected ? "#a2a2a2" : "#ffffff")};
  background-color: ${(props) => (!props.selected ? "#F1F1F1" : "#0073AB")};
  border: none;
  border-radius: 4px;
  display: flex;
  justify-content: space-between;
  align-items: center;


`;
