import styled from "styled-components";

export const LeftContainer = styled.div`
  display: flex;
  height: 100%;
  flex-direction: column;
  border-right: 1px solid #e1e1e1;
`;

export const RightContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow: auto;
  padding-left: 20px;
`;

//Modal Simulator Player Video
export const ContainerModal = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: center;
`;

export const ContainerPlayerVideo = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

