const resultsENUM = [
  { id: 0, name: "Normal" },
  { id: 1, name: "Alterado" },
];

const reasonsENUM = [
  { id: 0, name: "Temperatura" },
  { id: 1, name: "Sintoma" },
  { id: 2, name: "AI" },
  { id: 3, name: "Índice" },
];

const statusMyListsENUM = [
  { id: 0, name: "Em processamento" },
  { id: 1, name: "Concluído" },
  { id: 2, name: "Erro" },
];

const panelTypesMyListsENUM = [
  { id: 0, name: "Cliente" },
  { id: 1, name: "Científico" },
];

const progressENUM = [
  { id: 0, name: "Incompleto" },
  { id: 1, name: "Completo" },
];

const statusENUM = [
  { id: 0, name: "Ativo", checked: true },
  { id: 1, name: "Inativo", checked: false },
];

const reportGenerationStatusENUM = [
  { id: 1, name: "Sim", checked: false },
  { id: 0, name: "Não", checked: false },
];

const collectTypesENUM = [
  { id: 1, name: "Científico" },
  { id: 2, name: "Laudo" },
  { id: 3, name: "Ambos" },
];

const booleanStatusENUM = [
  { id: true, name: "Ativo" },
  { id: false, name: "Inativo" },
];

const reportStatusENUM = [
  {
    color: "#D2FFE5",
    description: "Análise com laudo gerado",
    reportLabel: "Visualizar",
    id: 1,
  },
  {
    color: "#ffc768",
    description: "Análise com laudo processando",
    reportLabel: "Gerando",
    id: 3,
  },
  {
    color: "#FFF8BB",
    description: "Análise com diagnóstico",
    reportLabel: "Gerar",
    id: 2,
  },
  {
    color: "#FFFFFF",
    description: "Análise sem laudo e diagnóstico",
    reportLabel: "Esta análise não possui diagnóstico",
    id: 0,
  },
];

const initialFiltersClient = {
  companyIds: [],
  devices: [],
  periodFrom: "",
  periodTo: "",
  results: [],
  reasons: [],
  textSearch: "",
  progress: [],
  page: 1,
};

const initialFlowFiltersClient = {
  companyIds: [],
  devices: [],
  segments: [],
  reportStatuses: [],
  periodFrom: "",
  periodTo: "",
  results: [],
  reasons: [],
  textSearch: "",
  progress: [],
  page: 1,
};

const initialFiltersMyListsScreenings = {
  status: [],
  periodFrom: "",
  periodTo: "",
  codeOrName: "",
  panelTypes: [],
  page: 1,
};

const initialFiltersCustomers = {
  status: [0],
  collectTypes: [],
  textSearch: "",
  page: 1,
};

const initialCustomerValues = {
  company: {
    companyName: "",
    companyTag: "",
    companyEmail: "",
    contactName: "",
    contactEmail: "",
    companyTypeId: null,
    companyStatus: null,
  },
  files: {
    logo: "",
    waterMark: "",
  },
};

const initialFiltersCustomerUsers = {
  companyId: null,
  status: [1],
  textSearch: "",
  page: 1,
};

const initialFiltersCustomerAnydesks = {
  companyId: null,
  status: [1],
  textSearch: "",
  page: 1,
};

const initialCustomerUsersValues = {
  userName: "",
  userEmail: "",
  userStatus: null,
};

const initialCustomerAnydesksValues = {
  anydeskName: "",
  anydeskSerial: "",
  status: null,
};

const initialCustomerNewCompaniesValues = {
  companyId: 0,
  companyName: ""
};

const initialReportValuesStructure = {
  screeningId: "",
  reportTypeId: "",
  examDate: "",
  requester: {
    name: "",
    councilTypeId: "",
    councilNumber: "",
  },
  examiner: {
    name: "",
    councilTypeId: "",
    councilNumber: "",
  },
  evaluator: {
    name: "",
    councilTypeId: "",
    councilNumber: "",
  },
};

const gaussianBlurSigma = 1.25;

export {
  resultsENUM,
  reasonsENUM,
  statusMyListsENUM,
  panelTypesMyListsENUM,
  progressENUM,
  statusENUM,
  reportGenerationStatusENUM,
  booleanStatusENUM,
  collectTypesENUM,
  reportStatusENUM,
  initialFiltersClient,
  initialFlowFiltersClient,
  initialFiltersMyListsScreenings,
  initialFiltersCustomers,
  initialCustomerValues,
  initialFiltersCustomerUsers,
  initialCustomerUsersValues,
  initialFiltersCustomerAnydesks,
  initialCustomerAnydesksValues,
  initialCustomerNewCompaniesValues,
  initialReportValuesStructure,
  gaussianBlurSigma
};
