import React , { useState } from "react";

// Libs
import moment from "moment";
import { FiExternalLink } from "react-icons/fi";

// Imgs
import DefaultUser from "assets/default-user.png";

// Components
import ResultText from "../../components/ResultText";
import ModalAlert from "components/ModalAlert";

// Context
import { useGeneralProvider } from "contexts/general";

// Services
import DashboardService from "services/dashboard";

// Constants
import { reportStatusENUM } from "../../../../../../constants";

// Utils
import { getDefaultPalleteRgb, applyGaussianBlur } from "utils";

// Constants
import {
  minTemperatureCelsius,
  maxTemperatureCapCelsius
} from "../../../../../../components/ThermalManipulation/constants";

import {
  DataContainer,
  HeaderTextContainer,
  HeaderDivider,
  HeaderTitle,
  HeaderSubtitle,
  BodyHeaderText,
  InfoCardContainer,
  EmptyContainer,
  ContentContainer,
  ImageCaptureSection,
  ImageCaptureContainer,
  ImageCapture,
  ImageCaptureInfoContainer,
  GeneralInformationBody,
  GeneralInformationRow,
  GeneralInformationGroup,
  GeneralInformationGroupContent,
  GroupDataContainer,
  ItemText,
} from "./styles";

import { CSVLink } from "react-csv";

import { PDFDownloadLink, Document, Page, Text, View, StyleSheet, Font } from '@react-pdf/renderer';

const PALETTE_APPLIED_TITLE = 'Pallete applied';

function BodySection({
  generalData,
  selectedItem,
  handleSeeGroupByID,
  setShowModalGenerateReport,
}) {
  const palleteRgb = getDefaultPalleteRgb();
  const { globalUser } = useGeneralProvider();
  const userPassService = new DashboardService(globalUser);  

  const [showModalAlert, setShowModalAlert] = useState(false);
  const handleReportClick = ({ reportStatus, link, update }) => {
    if (reportStatus === 3) {
      return;
    }

    if (reportStatus === 1) {
      window.open(link, "_blank");
      return;
    }

    if (reportStatus === 2) {
      if (update) {
        setShowModalAlert(true);
      }
      setShowModalGenerateReport(true);
      return;
    }
  };

  // Extrai o "raw" da imagem com base no RGB de cada pixel e calcula a temperatura
  const handleGetTemperature = (green, blue) => {
      const raw = green * 256 + blue;

      const M = 0.02569698246480104;
      const B = 64.74694198003411;

      const temp = M * raw - B;

      return temp;
  };

  const handleInitialMapping = (imageData) => {
      const dataLength = imageData.data.length;
      const pixelLength = 4;

      const temperaturePixelArray = [];

      for (let i = 0; i <= dataLength - pixelLength; i += pixelLength) {
        const green = imageData.data[i + 1];
        const blue = imageData.data[i + 2];

        const temp = handleGetTemperature(green, blue);

        if (temp >= minTemperatureCelsius && temp <= maxTemperatureCapCelsius) {
          temperaturePixelArray.push({ t: temp, p: i });
        }
      }

      const currentImageSortedData = temperaturePixelArray.sort((a, b) => {
        if (a.t < b.t) return -1;
        if (a.t > b.t) return 1;
        return 0;
      });

      return currentImageSortedData;
  };

  const handleTemperaturePerPixel = (imageData, currentImageValues) => {
      const temperaturePixelArray = [];

      for (let i = 0; i < currentImageValues.length; i++) {
        temperaturePixelArray.push(currentImageValues[i]);
      }
      return temperaturePixelArray;
  };

  const handlePixelTransform = (imageData, currentImageValues) => {
      const temperaturePixelArray = handleTemperaturePerPixel(imageData, currentImageValues);
      const expandedPalette = [];

      const pixelsPerColor = Math.ceil(
        temperaturePixelArray.length / palleteRgb.length
      );

      const reversedPalette = [...palleteRgb].reverse();

      for (let i = 0; i < reversedPalette.length; i++) {
        for (let j = 0; j < pixelsPerColor; j++) {
            expandedPalette.push(reversedPalette[i]);
        }
      }

      if (expandedPalette.length !== 0) {
        for (let i = 0; i < temperaturePixelArray.length; i++) {
            const currentColor = expandedPalette[i] || [0, 0, 0];

            // red
            imageData.data[temperaturePixelArray[i].p] = currentColor[0];
            // green
            imageData.data[temperaturePixelArray[i].p + 1] = currentColor[1];
            // blue
            imageData.data[temperaturePixelArray[i].p + 2] = currentColor[2];
        }
      }
      return imageData;
  };

  const validURL = (str) => {
      var pattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // domain name
      '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
      '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
      '(\\#[-a-z\\d_]*)?$','i'); // fragment locator
      return !!pattern.test(str);
  }

  const getTransformedImage = async (imageUrl) => {    
      let reload = false;
      if (validURL(imageUrl.target.currentSrc)) {
        const image = await userPassService.getImageBase64ByUrl(imageUrl.target.currentSrc);
        imageUrl.target.src = image.data;
        imageUrl.target.title = null;
        reload = true;
      }

      if (!reload && imageUrl.target.title !== PALETTE_APPLIED_TITLE) {
        // Create an empty canvas element
        const canvas = document.createElement("canvas");
        canvas.width = imageUrl.target.naturalWidth;
        canvas.height = imageUrl.target.naturalHeight;

        // Copy the image contents to the canvas
        const ctx = canvas.getContext("2d");
        ctx.drawImage(imageUrl.target, 0, 0);
        const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
        const currentImageValues = handleInitialMapping(imageData);
        const newImageData = handlePixelTransform(imageData, currentImageValues);
        applyGaussianBlur(imageData);
        ctx.putImageData(newImageData, 0, 0);
        imageUrl.target.title = PALETTE_APPLIED_TITLE;
        imageUrl.target.src = canvas.toDataURL();
      }  
  };

  const headers = [
    'ID Análise',
    'Data e Hora',
    'Identificador' 
  ];
  
  const data = [
    selectedItem.screeningId,
    moment.parseZone(selectedItem.period).format("DD/MM/YYYY - HH:mm"),
    selectedItem.identifier ? selectedItem.identifier : "Sem ID"
  ];

  if (generalData != null && generalData.form != null) {
    for (let i = 0; i < generalData.form.length; i++) {
      const formItem = generalData.form[i];
      if(
        formItem.name !== 'visualDiagramMetadataImageURL' &&
        formItem.name !== 'visualDiagramMetadataMarkdownColor' &&
        formItem.name !== 'visualDiagramMetadataPointsArray'
        ) {
          headers.push(formItem.description);

          switch(formItem.type) {
            case 'TEXT':
            case 'TEXT_AREA':
              if (formItem.stringValue != null && formItem.stringValue != "None" && formItem.stringValue != "") {
                data.push(formItem.stringValue);
              } else {
                data.push("Indefinido");
              }
              break;
            case 'CHECKBOX_LIST':
              if (formItem.arrayValue.length > 0) {
                const selectedOptions = [];
                for (let j = 0; j < formItem.arrayValue.length; j++) {
                  if (formItem.arrayValue[j].objectValue.selected == true) {
                    selectedOptions.push(formItem.arrayValue[j].objectValue.description);
                  }
                }
                if (selectedOptions.length > 0) {
                  data.push(selectedOptions.join());
                } else {
                  data.push("Sem opções selecionadas");
                }
              } else {
                data.push("Indefinido");
              }
              break;
            default:
              data.push("");
          }
        }
    }
  }

  const csvData = [
    headers,
    data
  ];

  const styles = StyleSheet.create({
    page: {
      padding: 30,
      flexDirection: 'column',
      backgroundColor: '#ffffff',
    },
    section: {
      flexGrow: 1,
    },
    textHeader: {
      width: '100%',
      fontSize: 18
    },
    textSubHeader: {
      fontSize: 14,
      marginTop: 24,
    },
    dataContainer: {
      flexDirection: 'column',
      justifyContent: 'flex-start',
      marginTop: 12
    },
    label: {
      fontSize: 10,
      marginRight: 5,
      marginTop: 2
    },
    value: {
      fontSize: 10,
      marginTop: 5
    }
  });

  const PdfData = () => (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={styles.section}>
          <Text style={styles.textHeader}>Anamnese</Text>
          
          <Text style={styles.textSubHeader}>Análise</Text>

          <View style={styles.dataContainer}>
            <Text style={styles.label}>ID Análise:</Text>
            <Text style={styles.value}>{selectedItem.screeningId}</Text>
          </View>
          <View style={styles.dataContainer}>
            <Text style={styles.label}>Data e Hora</Text>
            <Text style={styles.value}>{moment.parseZone(selectedItem.period).format("DD/MM/YYYY - HH:mm")}</Text>
          </View>
          <View style={styles.dataContainer}>
            <Text style={styles.label}>Identificador:</Text>
            <Text style={styles.value}>{selectedItem.identifier ? selectedItem.identifier : "Sem ID"}</Text>
          </View>

          <Text style={styles.textSubHeader}>Formulário preenchido</Text>

          {generalData?.form?.length !== 0 ? (
                generalData?.form?.map((formItem, index) => (
                  formItem.name !== 'visualDiagramMetadataImageURL' &&
                  formItem.name !== 'visualDiagramMetadataMarkdownColor' &&
                  formItem.name !== 'visualDiagramMetadataPointsArray' ? (
                    <View style={styles.dataContainer}>
                      <Text style={styles.label}>{formItem.description}:</Text>
                      
                      {(() => {
                        switch(formItem.type) {
                          case 'TEXT':
                          case 'TEXT_AREA':
                            if (formItem.stringValue != null && formItem.stringValue != "None" && formItem.stringValue != "") {
                              return <Text style={styles.value}>{formItem.stringValue}</Text>;
                            } else {
                              return <Text style={styles.value}>Indefinido</Text>;
                            }
                          case 'CHECKBOX_LIST':
                            if (formItem.arrayValue.length > 0) {
                              const selectedOptions = [];
                              for (let j = 0; j < formItem.arrayValue.length; j++) {
                                if (formItem.arrayValue[j].objectValue.selected == true) {
                                  selectedOptions.push(formItem.arrayValue[j].objectValue.description);
                                }
                              }
                              if (selectedOptions.length > 0) {
                                return <Text style={styles.value}>{selectedOptions.join(', ')}</Text>;
                              } else {
                                return <Text style={styles.value}>Sem opções selecionadas</Text>;
                              }
                            } else {
                              data.push("Indefinido");
                            }
                          default:
                            return <Text style={styles.label}>Indefinido</Text>;
                        }
                      })()}

                    </View>
                  ): null
                ))
              ) : (
                <View style={styles.dataContainer}>
                      <Text style={styles.textLeft}>Sem respostas de formulário</Text>
                </View>
              )}

        </View>
      </Page>
    </Document>
  );


  return (
    <ContentContainer>
      <HeaderTextContainer>
        <HeaderTitle>Captura da imagem</HeaderTitle>
        <HeaderDivider />
        <HeaderSubtitle>{"Câmera:"}</HeaderSubtitle>
        <InfoCardContainer>{generalData?.cameraMake || "-"}</InfoCardContainer>
      </HeaderTextContainer>
      <ImageCaptureSection>
        <ImageCaptureContainer>
          <ImageCapture src={generalData?.visibleImage || DefaultUser} />
        </ImageCaptureContainer>
        <ImageCaptureContainer>
          <ImageCapture 
            src={generalData?.thermalImage || DefaultUser}
            onLoad={generalData?.thermalImage ? (event) => getTransformedImage(event) : null}
          />
        </ImageCaptureContainer>
        <ImageCaptureContainer>
          <ImageCaptureInfoContainer>
            <DataContainer>
              <BodyHeaderText>Data e Hora</BodyHeaderText>
              <InfoCardContainer>
                {moment.parseZone(selectedItem.period).format("DD/MM/YYYY - HH:mm")}
              </InfoCardContainer>
            </DataContainer>

            <DataContainer>
              <BodyHeaderText>Identificador</BodyHeaderText>
              <InfoCardContainer>
                {selectedItem.identifier ? selectedItem.identifier : "Sem ID"}
              </InfoCardContainer>
            </DataContainer>

            <DataContainer>
              <BodyHeaderText>Nome</BodyHeaderText>
              <InfoCardContainer>
                {selectedItem.patientName ? selectedItem.patientName : ""}
              </InfoCardContainer>
            </DataContainer>

            <DataContainer>
              <BodyHeaderText>Dispositivo</BodyHeaderText>
              <InfoCardContainer>{selectedItem.device}</InfoCardContainer>
            </DataContainer>

            <DataContainer>
              <BodyHeaderText>ID Análise</BodyHeaderText>
              <InfoCardContainer>{selectedItem.screeningId}</InfoCardContainer>
            </DataContainer>

            <DataContainer>
              <BodyHeaderText>Resultado</BodyHeaderText>
              <ResultText result={selectedItem.result} />
            </DataContainer>
          </ImageCaptureInfoContainer>
        </ImageCaptureContainer>
      </ImageCaptureSection>

      {/* General Info */}

      <GeneralInformationBody>
        <GeneralInformationRow>
          {/* Group */}
          <GeneralInformationGroup>
            <BodyHeaderText>Temperatura Corporal</BodyHeaderText>
            <GeneralInformationGroupContent>
              <GroupDataContainer>
                <ItemText>Média</ItemText>
                <InfoCardContainer>
                  {generalData?.bodyTemperature || ""}
                </InfoCardContainer>
              </GroupDataContainer>
            </GeneralInformationGroupContent>
          </GeneralInformationGroup>

          {/* Group */}
          <GeneralInformationGroup>
            <BodyHeaderText>Temperatura de Referência</BodyHeaderText>
            <GeneralInformationGroupContent>
              <GroupDataContainer>
                <ItemText>Câmera</ItemText>
                <InfoCardContainer>
                  {generalData?.referenceTemperature?.camera || ""}
                </InfoCardContainer>
              </GroupDataContainer>

              <GroupDataContainer>
                <ItemText>Termopar</ItemText>
                <InfoCardContainer>
                  {generalData?.referenceTemperature?.thermocouple || ""}
                </InfoCardContainer>
              </GroupDataContainer>

              <GroupDataContainer>
                <ItemText>Offset</ItemText>
                <InfoCardContainer>
                  {generalData?.referenceTemperature?.offset || ""}
                </InfoCardContainer>
              </GroupDataContainer>
            </GeneralInformationGroupContent>
          </GeneralInformationGroup>
        </GeneralInformationRow>

        <GeneralInformationRow>
          {/* Group */}
          <GeneralInformationGroup>
            <BodyHeaderText>Grupos de imagens e diagnósticos</BodyHeaderText>
            <GeneralInformationGroupContent>
              {generalData?.diagnosticGroups?.length !== 0 ? (
                generalData?.diagnosticGroups?.map((group, index) => (
                  <GroupDataContainer key={`group-${index}`}>
                    <InfoCardContainer
                      isGroup
                      isActivated={group.groupStatus === 1}
                      onClick={() => handleSeeGroupByID(group.groupId)}
                    >
                      {group.groupName}
                      <FiExternalLink />
                    </InfoCardContainer>
                  </GroupDataContainer>
                ))
              ) : (
                <EmptyContainer>Sem grupos.</EmptyContainer>
              )}
            </GeneralInformationGroupContent>
          </GeneralInformationGroup>

          <GeneralInformationGroup>
            <BodyHeaderText>Laudo</BodyHeaderText>
            {generalData?.diagnosticReport ? (
              <GeneralInformationGroupContent>
                {generalData?.diagnosticReport?.reportStatus === 0 ? (
                  <EmptyContainer>
                    {reportStatusENUM.find(
                      (reportStatus) => reportStatus.id === 0
                    ).reportLabel || ""}
                  </EmptyContainer>
                ) : (
                  <GroupDataContainer laudo={true}>
                    <InfoCardContainer
                      isGroup={
                        generalData?.diagnosticReport?.reportStatus === 3
                          ? false
                          : true
                      }
                      onClick={() =>
                        handleReportClick({
                          reportStatus: generalData?.diagnosticReport?.reportStatus,
                          link: generalData?.diagnosticReport?.reportLink,
                        })
                      }
                      laudo={true}
                    >
                      {reportStatusENUM.find(
                        (reportStatus) =>
                          reportStatus.id ===
                            generalData?.diagnosticReport?.reportStatus
                      )?.reportLabel || ""}
                      <FiExternalLink />
                    </InfoCardContainer>
                    {generalData?.diagnosticReport?.reportStatus === 1 ? (
                      <InfoCardContainer
                        isGroup={true}
                        onClick={() =>
                          handleReportClick({
                            reportStatus: 2,
                            link: generalData?.diagnosticReport?.reportLink,
                            update: true
                          })
                        }
                        laudo={true}
                      >
                        {reportStatusENUM.find(
                          (reportStatus) =>
                            reportStatus.id ===
                              2
                        )?.reportLabel || ""}
                        <FiExternalLink />
                      </InfoCardContainer>
                    ): null}
                  </GroupDataContainer>
                )}
              </GeneralInformationGroupContent>
            ) : null}
          </GeneralInformationGroup>
        </GeneralInformationRow>

        <GeneralInformationRow>
          {/* Group */}
          <GeneralInformationGroup>
            <BodyHeaderText>Sintomas respondidos</BodyHeaderText>
            <GeneralInformationGroupContent>
              {generalData?.symptoms?.length !== 0 ? (
                generalData?.symptoms?.map((symptom, index) => (
                  <GroupDataContainer key={`symptom-${index}`}>
                    <InfoCardContainer>{symptom}</InfoCardContainer>
                  </GroupDataContainer>
                ))
              ) : (
                <EmptyContainer>Sem sintomas.</EmptyContainer>
              )}
            </GeneralInformationGroupContent>
          </GeneralInformationGroup>
          {/* <GeneralInformationGroup>
            <BodyHeaderText>Anamnese</BodyHeaderText>
            <GeneralInformationGroupContent>
              <CSVLink data={csvData} filename={"anamnese_" + selectedItem.screeningId + ".csv"}>CSV</CSVLink>
              <PDFDownloadLink document={<PdfData />} fileName={"anamnese_" + selectedItem.screeningId + ".pdf"} style={{marginLeft: 5}}>
                {({ blob, url, loading, error }) =>
                  loading ? 'Carregando...' : 'PDF'
                }
              </PDFDownloadLink>
            </GeneralInformationGroupContent>
          </GeneralInformationGroup> */}
        </GeneralInformationRow>

        <GeneralInformationRow>
          {/* Group */}
          <GeneralInformationGroup>
            <BodyHeaderText>Morfometria</BodyHeaderText>
            <GeneralInformationGroupContent>
              <GroupDataContainer>
                <ItemText>Sexo</ItemText>
                <InfoCardContainer>
                  {generalData?.morphometry?.gender}
                </InfoCardContainer>
              </GroupDataContainer>

              <GroupDataContainer>
                <ItemText>Idade</ItemText>
                <InfoCardContainer>
                  {generalData?.morphometry?.age}
                </InfoCardContainer>
              </GroupDataContainer>

              <GroupDataContainer>
                <ItemText>Peso</ItemText>
                <InfoCardContainer>
                  {generalData?.morphometry?.weight}
                </InfoCardContainer>
              </GroupDataContainer>

              <GroupDataContainer>
                <ItemText>Altura</ItemText>
                <InfoCardContainer>
                  {generalData?.morphometry?.height}
                </InfoCardContainer>
              </GroupDataContainer>
            </GeneralInformationGroupContent>
          </GeneralInformationGroup>
        </GeneralInformationRow>

        <GeneralInformationRow>
          {/* Group */}
          <GeneralInformationGroup>
            <BodyHeaderText>Formulário</BodyHeaderText>
            <GeneralInformationGroupContent>
              {generalData?.questions?.length !== 0 ? (
                generalData?.questions?.map((questionItem, index) => (
                  <GroupDataContainer key={`question-${index}`}>
                    <ItemText>{questionItem.question}</ItemText>
                    <InfoCardContainer>{questionItem.answer}</InfoCardContainer>
                  </GroupDataContainer>
                ))
              ) : (
                <EmptyContainer>Sem respostas de formulário.</EmptyContainer>
              )}
            </GeneralInformationGroupContent>
          </GeneralInformationGroup>
        </GeneralInformationRow>
      </GeneralInformationBody>
      {showModalAlert ? (
        <ModalAlert 
          setShowModal={setShowModalAlert} 
          message={'A análise já tem um laudo vinculado e será sobrescrito.'} />
      ) : null}
    </ContentContainer>
  );
}

export default BodySection;
