import styled from "styled-components";

export const Container = styled.div`
  width: 48px;
  height: calc(100vh - 64px);
  padding: 18px 0 18px 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  border-right: 1px solid #f1f1f1;
  user-select: none;
`;

export const MenuIconContainer = styled.div`
  width: 32px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${(props) => (props.selected ? "#e2f4ff" : "#FEFEFE")};
  border-radius: 4px;
  cursor: pointer;
  margin-bottom: 20px;

  :hover {
    opacity: 0.7;
  }
`;

export const ImageIcon = styled.img`
  width: 24px;
  height: 24px;
  pointer-events: none;
`;
