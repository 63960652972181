/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";

// Libs
import { toast } from "react-toastify";

// Components
import Input from "components/Input";
import Button from "components/Button";

// Services
import DashboardService from "services/dashboard";

// Constants
import { initialCustomerUsersValues } from "../../../../constants";

// Utils
import { isEmailvalid } from "../../../../utils";

// Context
import { useGeneralProvider } from "contexts/general";

// Imgs
import CloseIconSvg from "assets/close-icon.svg";

// Styles
import {
  BackDrop,
  Container,
  HeaderContainer,
  HeaderText,
  HeaderCloseContainer,
  ImageWrapper,
  CloseIcon,
  BodyContainer,
  FooterContainer,
  InputGroup,
  InputLabel,
} from "./styles";

function AddCustomerUser({ setShowModal, handleCleanFilters, companyId }) {
  const { setLoading, globalUser } = useGeneralProvider();
  const userPassService = new DashboardService(globalUser);

  const [isSaveEnabled, setIsSaveEnabled] = useState(false);
  const [userData, setUserData] = useState(initialCustomerUsersValues);

  useEffect(() => {
    handleEnableButtonSave();
  }, [userData]);

  const handleCreateUser = () => {
    setLoading(true);

    userPassService
      .createCompanyUser({ companyId: companyId, ...userData })
      .then(() => {
        toast.success("Usuário criado com sucesso!");
        handleCleanFilters();
        setShowModal(false);
      })
      .catch((err) => {
        setLoading(false);

        if (err?.response?.data?.message) {
          toast.error(err.response.data.message);
        } else {
          toast.error("Falha ao criar usuário");
        }
      });
  };

  const handleEnableButtonSave = () => {
    let auxIsSaveEnabled = true;

    if (
      !userData.userName ||
      !userData.userEmail ||
      !isEmailvalid(userData.userEmail)
    ) {
      auxIsSaveEnabled = false;
    }

    setIsSaveEnabled(auxIsSaveEnabled);
  };

  return (
    <BackDrop>
      <Container>
        <HeaderContainer>
          <HeaderCloseContainer>
            <ImageWrapper />
            <HeaderText>Adicionar Usuário</HeaderText>
            <ImageWrapper onClick={() => setShowModal(false)}>
              <CloseIcon src={CloseIconSvg} />
            </ImageWrapper>
          </HeaderCloseContainer>
        </HeaderContainer>

        <BodyContainer>
          <InputGroup>
            <InputLabel>Nome</InputLabel>
            <Input
              maxLength={255}
              value={userData.userName}
              onChange={(e) =>
                setUserData({
                  ...userData,
                  userName: e.target.value,
                })
              }
            />
          </InputGroup>

          <InputGroup>
            <InputLabel>E-mail</InputLabel>
            <Input
              inputError={!isEmailvalid(userData.userEmail)}
              maxLength={255}
              value={userData.userEmail}
              onChange={(e) =>
                setUserData({
                  ...userData,
                  userEmail: e.target.value,
                })
              }
            />
          </InputGroup>
        </BodyContainer>

        <FooterContainer>
          <Button
            label={"Adicionar"}
            disabled={!isSaveEnabled}
            onClick={() => {
              handleCreateUser();
            }}
          />
        </FooterContainer>
      </Container>
    </BackDrop>
  );
}

export default AddCustomerUser;
