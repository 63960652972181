import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  padding: 8px;
  border: none;
  outline: none;
  background-color: unset;

  :hover {
    background-color: #f9f9f9;
    border-radius: 6px;

    img {
      transition: all 0.4s;
      transform: rotate(360deg);
    }
  }
`;

export const LabelText = styled.p`
  margin: 0;
  padding: 0;
  color: #38393d;
  font-size: 14px;
  margin-right: 16px;
`;

export const ReloadImg = styled.img`
  width: 24px;
  height: 24px;
`;
