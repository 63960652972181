import React from "react";
import usePagination from "@mui/material/usePagination";

// Imgs
import ArrowRight from "assets/arrow-right.svg";
import ArrowDouble from "assets/arrow-double.svg";

// Styles
import {
  Container,
  NavWrapper,
  PageOrder,
  NavigationButton,
  EllipsisText,
  ImageArrow,
} from "./styles";

function Pagination({ currentPage, onPageChange, totalPages }) {
  const { items } = usePagination({
    count: totalPages,
    siblingCount: 1,
    page: currentPage,
    onChange: (e, value) => {
      if (value !== currentPage) {
        onPageChange(value);
      }
    },
    showFirstButton: true,
    showLastButton: true,
  });

  return (
    <Container>
      <NavWrapper>
        <PageOrder>
          {items.map(
            ({ page, type, selected, onChange, disabled, ...item }, index) => {
              let children = null;

              if (type === "start-ellipsis" || type === "end-ellipsis") {
                children = <EllipsisText>...</EllipsisText>;
              } else if (type === "page") {
                children = (
                  <NavigationButton selected={selected} {...item}>
                    {page}
                  </NavigationButton>
                );
              } else {
                children = (
                  <NavigationButton disabled={disabled} {...item}>
                    <ImageArrow
                      disabled={disabled}
                      type={type}
                      src={
                        type === "first" || type === "last"
                          ? ArrowDouble
                          : ArrowRight
                      }
                    />
                  </NavigationButton>
                );
              }

              return <li key={index}>{children}</li>;
            }
          )}
        </PageOrder>
      </NavWrapper>
    </Container>
  );
}

export default Pagination;
