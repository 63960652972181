import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  margin-top: 10px;
  margin-bottom: 10px;
  display: ${(props) => props.galleryLeft ? 'block' : 'none'};
`;

export const LabelText = styled.p`
  height: 24px;
`;

export const DescriptionContainer = styled.div`
  display: flex;
  align-items: center;
  background-color: #e6e6e6;
  height: 38px;
  border-radius: 6px;
  padding-left: 8px;
  padding-right: 8px;
`;

export const DescriptionText = styled.p`
  font-size: 16px;
`;
