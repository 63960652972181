import React from "react";

// Images
import AnalysisIconCard from "assets/analysisIconCard.svg";
import NormalIconCard from "assets/normalIconCard.svg";
import ChangedIconCard from "assets/changedIconCard.svg";

import {
  Container,
  TypeContainer,
  LeftInfoContainer,
  HeaderInfo,
  NumberInfo,
  TextHeader,
  ImageInfo,
  PercentageContainer,
  PercentageText,
} from "./styles";

function InfoCard({
  percentage,
  type,
  total,
  changedSelected,
  setChangedSelected,
}) {
  return (
    <Container
      type={type}
      changedSelected={changedSelected}
      onClick={() => {
        if (type === "changed") {
          setChangedSelected(!changedSelected);
        }
      }}
    >
      <TypeContainer>
        <LeftInfoContainer>
          <HeaderInfo>
            <ImageInfo
              src={
                type === "analysis"
                  ? AnalysisIconCard
                  : type === "normal"
                  ? NormalIconCard
                  : type === "changed" || type === "changedNoSelected"
                  ? ChangedIconCard
                  : null
              }
            />
            <TextHeader>
              {type === "analysis"
                ? "Análises"
                : type === "normal"
                ? "Normais"
                : type === "changed" || type === "changedNoSelected" 
                ? "Alteradas"
                : ""}
            </TextHeader>
          </HeaderInfo>
          <NumberInfo type={type}>{total}</NumberInfo>
        </LeftInfoContainer>
      </TypeContainer>
      <PercentageContainer>
        <PercentageText type={type}>
          {type !== "analysis"
            ? percentage
              ? `${percentage}%`
              : `${0}%`
            : null}
        </PercentageText>
      </PercentageContainer>
    </Container>
  );
}

export default InfoCard;
