import React from "react";

// Libs
import { Stage, Layer, Rect, Circle } from "react-konva";
import useImage from 'use-image';

import {
  Container
} from "./styles";

//var selectedPainAreas = [[35.789,22.235],[36.123,23.557],[34.126,24.231]];
//var imageUrl = 'https://apicorestack-apicoredashboardassets81a11534-1fj7sth44d3gg.s3.amazonaws.com/Predikta/fullbodytemplate.svg';

var circle = {
  circleRadius: 10,
}

function KonvaImagePoints({
  color,
  url,
  points,
  width,
  height,
  offset_x,
  offset_y
}) {

  const [image] = useImage(url);

  const handleClick = () => {}

  return (
    <Container>
      <Stage width={width} height={height}>
        <Layer>
          <Rect 
            x={0} 
            y={0}
            width={width}
            height={height}
            stroke="black"
          />
          <Rect 
            x={offset_x} 
            y={offset_y}
            width={width}
            height={height}
            //stroke="black"
            fillPatternImage={image}
            fillPatternRepeat="no-repeat"
            onClick={handleClick}
          />
          {points.map((area, key) => {
            return (
            <Circle 
              key={key}
              x={area[0]} 
              y={area[1]} 
              radius={circle.circleRadius} 
              fill={color} 
            />)
          })}
        </Layer>
      </Stage>
    </Container>
  );
}

export default KonvaImagePoints;
