import React, { useState } from "react";

// Styles
import { Container, EyeIcon, EyeOffIcon } from "./styles";

export default function Input(props) {
  const {
    value,
    label,
    type,
    placeholder,
    onChange,
    onBlur,
    inputError,
    inputSuccess,
    isPassword,
    maxLength,
    autoComplete,
    disabled,
    smallSize,
    customWidth
  } = props;
  const [inputFocused, setInputFocused] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  return (
    <Container
      disabled={disabled}
      inputSuccess={inputSuccess}
      inputError={inputError}
      isOnFocus={inputFocused}
      isPassword={isPassword}
      smallSize={smallSize}
      customWidth={customWidth}
    >
      {label && <span>{label}</span>}
      <input
        disabled={disabled}
        maxLength={maxLength}
        value={value}
        onBlur={(e) => {
          if (onBlur) {
            onBlur(e);
          }
          setInputFocused(false);
        }}
        autoComplete={autoComplete}
        onFocus={() => setInputFocused(true)}
        onChange={onChange}
        type={isPassword ? (showPassword ? "text" : "password") : type}
        placeholder={placeholder}
      />
      {isPassword ? (
        <>
          {showPassword ? (
            <EyeIcon
              title="Esconder Senha"
              onClick={() => setShowPassword(!showPassword)}
            />
          ) : (
            <EyeOffIcon
              title="Mostrar Senha"
              onClick={() => setShowPassword(!showPassword)}
            />
          )}
        </>
      ) : null}
    </Container>
  );
}
