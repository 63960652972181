import React from "react";

// Imgs
import MainLogo from "assets/main-logo.svg";

// Components
import SwitchButton from "../SwitchButton";
import ReloadButton from "../ReloadButton";

// Context
import { useGeneralProvider } from "contexts/general";

import { Container, ImgLogo, MainTitle, TitleContainer } from "./styles";

function Header() {
  const { selectedPage, currentSubtitle, automaticUpdate } =
    useGeneralProvider();

  return (
    <Container>
      <TitleContainer>
        <ImgLogo src={MainLogo} />
        <MainTitle>{`Predikta Dash${
          currentSubtitle ? ` • ${currentSubtitle}` : ""
        }`}</MainTitle>
      </TitleContainer>

      {selectedPage === "/" ? null : (
        <TitleContainer>
          <SwitchButton />
          {automaticUpdate ? null : <ReloadButton />}
        </TitleContainer>
      )}
    </Container>
  );
}

export default Header;
