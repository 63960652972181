import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(56, 57, 61, 0.2);
  z-index: 99999;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
`;
