import styled from "styled-components";

import { IoMdEyeOff, IoMdEye } from "react-icons/io";

export const Container = styled.div`
  span {
    background-color: white;
    font-size: 14px;
    position: relative;
    right: -9px;
    bottom: -10px;
    padding: 0 2px 0 2px;
    user-select: none;
    color: #6d6464;
  }

  input {
    height: ${({ smallSize }) => (smallSize ? "40px" : "48px")};
    border: ${(props) =>
      props.inputError
        ? "1px solid #ef1515"
        : props.inputSuccess
        ? "1px solid #40A99E"
        : "1px solid #9D9E9D"};
    border-radius: 4px;
    outline: none;
    padding: ${(props) =>
      props.isPassword ? "0 45px 0 10px" : "0 10px 0 10px"};
    background-color: ${(props) => (props.disabled ? "#F2F2F2" : "#ffffff")};
    font-size: 14px;
    width: ${({ customWidth }) => (customWidth ? `${customWidth}px` : "100%")};
    transition: all 0.5s;
  }

  input::-ms-reveal,
  input::-ms-clear {
    display: none;
  }
`;

export const EyeIcon = styled(IoMdEye)`
  position: absolute;
  margin-left: -36px;
  margin-top: 14px;
  color: #484848;
  font-size: 23px;

  :hover {
    cursor: pointer;
    opacity: 0.7;
  }
`;

export const EyeOffIcon = styled(IoMdEyeOff)`
  position: absolute;
  margin-left: -36px;
  margin-top: 14px;
  color: #484848;
  font-size: 23px;

  :hover {
    cursor: pointer;
    opacity: 0.7;
  }
`;
