import React, { useState } from "react";

// Libs
import moment from "moment";

// Imgs
import DownloadIcon from "assets/download-icon.svg";
import NotFound from "assets/not-found.svg";

// Components
import StatusText from "./components/StatusText";
import HeaderSort from "./components/HeaderSort";

import {
  TableContainer,
  THead,
  TBody,
  TR,
  TH,
  TD,
  IconWrapper,
  ActionIcon,
  EmptyListContainer,
  NotFoundImg,
  EmptyListText,
} from "./styles";

function ComplexTableSavedLists({ data, setData }) {
  const [isSorted, setIsSorted] = useState(false);

  const handleSortContent = (objKey) => {
    const sortedData = isSorted
      ? data.sort((a, b) => {
          if (a[objKey] < b[objKey]) return -1;
          if (a[objKey] > b[objKey]) return 1;
          return 0;
        })
      : data.sort((a, b) => {
          if (a[objKey] > b[objKey]) return -1;
          if (a[objKey] < b[objKey]) return 1;
          return 0;
        });

    setIsSorted(!isSorted);
    setData([...sortedData]);
  };

  const handlePanelType = (id) => {
    let panelName = "";

    switch (id) {
      case 0:
        panelName = "Cliente";
        break;

      case 1:
        panelName = "Científico";
        break;

      default:
        break;
    }

    return panelName;
  };

  return (
    <TableContainer>
      {/* Header */}
      <THead>
        <TR>
          <HeaderSort
            label="Código"
            sortContent={() => handleSortContent("requestId")}
          />
          <HeaderSort
            label="Nome"
            sortContent={() => handleSortContent("reportName")}
          />
          <HeaderSort
            label="Data e Hora"
            sortContent={() => handleSortContent("createdAt")}
          />
          <HeaderSort
            label="Status"
            sortContent={() => handleSortContent("status")}
          />
          <HeaderSort
            label="Tipo de Painel"
            sortContent={() => handleSortContent("panelType")}
          />
          <TH>Download</TH>
        </TR>
      </THead>

      {/* Body */}
      <TBody>
        {data && data.length !== 0 ? (
          data.map((item, index) => (
            <React.Fragment key={`item-${index}`}>
              <TR>
                <TD>{item.requestId}</TD>
                <TD>{item.reportName ? item.reportName : ""}</TD>
                <TD>
                  {item.createdAt
                    ? moment.parseZone(item.createdAt).format("DD/MM/YYYY - HH:mm")
                    : ""}
                </TD>
                <TD>
                  <StatusText status={item.status} />
                </TD>
                <TD>{handlePanelType(item.panelType)}</TD>
                <TD>
                  {item.downloadLink ? (
                    <IconWrapper href={item.downloadLink} download>
                      <ActionIcon src={DownloadIcon} />
                    </IconWrapper>
                  ) : null}
                </TD>
              </TR>
            </React.Fragment>
          ))
        ) : (
          <tr>
            <td colSpan={8}>
              <EmptyListContainer>
                <NotFoundImg src={NotFound} />
                <EmptyListText>
                  Não encontramos resultado para sua busca.
                </EmptyListText>
              </EmptyListContainer>
            </td>
          </tr>
        )}
      </TBody>
    </TableContainer>
  );
}

export default ComplexTableSavedLists;
