import styled from "styled-components";

const CustomDivScroll = styled.div`
  ::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }
  ::-webkit-scrollbar-button {
    width: 0px;
    height: 0px;
  }
  ::-webkit-scrollbar-thumb {
    background: #0073ab;
    border-radius: 20px;
  }
  ::-webkit-scrollbar-thumb:hover {
    background: #0073ab;
  }
  ::-webkit-scrollbar-thumb:active {
    background: #0073ab;
  }
  ::-webkit-scrollbar-track {
    background: transparent;
    border-radius: 50px;
  }
  ::-webkit-scrollbar-track:hover {
    background: transparent;
  }
  ::-webkit-scrollbar-track:active {
    background: transparent;
  }
  ::-webkit-scrollbar-corner {
    background: transparent;
  }
`;

export const DataContainer = styled.div`
  margin-bottom: 8px;

  :last-of-type {
    margin: 0;
  }
`;

export const HeaderTextContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  margin-bottom: 10px;
`;

export const HeaderDivider = styled.span`
  height: 16px;
  width: 2px;
  background-color: #e1e1e1;
  margin: 0 8px 0 8px;
`;

export const HeaderTitle = styled.p`
  color: #38393d;
  font-size: 18px;
  font-weight: bold;
`;

export const HeaderSubtitle = styled.p`
  color: #38393d;
  font-size: 14px;
  font-weight: bold;
  margin-right: 4px;
`;

export const BodyHeaderText = styled.p`
  font-size: 14px;
  font-weight: bold;
  align-self: flex-start;
  margin-bottom: 6px;
`;

export const InfoCardContainer = styled.div`
  background-color: ${({ isActivated }) =>
    isActivated ? "#0073AB" : "#f1f1f1"};
  padding: 4px 6px 4px 6px;
  align-items: center;
  color: ${({ isActivated }) => (isActivated ? "#FFFFFF" : "#38393d")};
  display: flex;
  width: fit-content;
  flex-wrap: wrap;
  font-size: 14px;
  border-radius: 4px;
  word-break: break-all;
  cursor: ${({ isGroup }) => (isGroup ? "pointer" : "unset")};
  margin-right: ${({ laudo }) => (laudo ? "10px" : null)};

  :hover {
    opacity: ${({ isGroup }) => (isGroup ? 0.7 : 1)};
  }

  svg {
    margin-left: 10px;
    width: 20px;
    height: 20px;
  }
`;

export const EmptyContainer = styled.div`
  margin-top: 10px;
  width: 100%;
  font-size: 12px;
`;

export const ContentContainer = styled(CustomDivScroll)`
  display: flex;
  flex-direction: column;
  /* padding: 0 20px 20px 20px; */
  overflow: auto;
`;

export const ImageCaptureSection = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
`;

export const ImageCaptureContainer = styled.div`
  border: 1px solid #e1e1e1;
  width: 280px;
  min-width: 280px;
  height: 396px;
  min-height: 396px;
  border-radius: 8px;
  margin-right: 30px;
  overflow: hidden;

  :last-of-type {
    margin: 0;
  }
`;

export const ImageCapture = styled.img`
  width: 280px;
  height: 396px;
  object-fit: cover;
`;

export const ImageCaptureInfoContainer = styled(CustomDivScroll)`
  height: 100%;
  padding: 10px;
  display: flex;
  flex-direction: column;
`;

export const GeneralInformationBody = styled.div`
  margin-top: 30px;
  padding-bottom: 20px;
`;

export const GeneralInformationRow = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-bottom: 20px;

  :last-of-type {
    margin: 0;
  }
`;

export const GeneralInformationGroup = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 70px;

  :last-of-type {
    margin: 0;
  }
`;

export const GeneralInformationGroupContent = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`;

export const GroupDataContainer = styled.div`
  display: flex;
  flex-direction: ${({ laudo }) => (laudo ? "row" : "column")};
  margin-right: 16px;
  margin-bottom: 8px;

  :last-of-type {
    margin: 0;
  }
`;

export const ItemText = styled.p`
  color: #38393d;
  font-size: 12px;
  align-self: flex-start;
  margin-bottom: 6px;
`;

export const ContainerUploadFiles = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 5px;
  border-width: 2px;
  border-radius: 2px;
  border-style: dashed;
  background-color: #fafafa;
  color: #bdbdbd;
  outline: none;
  width: 100%;
`;

export const UploadIconImg = styled.img`
  width: 30px;
  height: 30px;
  margin-bottom: 5px;
`;

export const UploadText = styled.p`
  font-size: 16px;
  color: ${(props) => (props.actived ? "#2773AB" : "#38393D")};
  font-weight: bold;
`;