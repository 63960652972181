import { HttpStatus } from "constants/HttpStatus";
import axios from "axios";
import { toast } from "react-toastify";

export default class HttpService {
  constructor(props) {
    let service = axios.create({
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${props.token}`,
      },
    });

    service.interceptors.response.use(
      (response) => {
        response.responseType = "arraybuffer";
        return response;
      },
      (error) => {
        if (error.response?.status === HttpStatus.UNAUTHORIZED) {
          toast.error("Não autorizado! Você será redirecionado.");
          setTimeout(() => {
            localStorage.setItem("@user", null);
            window.location.reload();
          }, 4000);
        } else {
          return Promise.reject(error);
        }
      }
    );

    this.props = props;
    this.service = service;
    this.baseUrl = process.env.REACT_APP_BASE_URL_API;
  }

  get(path) {
    let url = this.baseUrl + path;
    return this.service.get(url);
  }

  getImage(path) {
    let url = this.baseUrl + path;
    return this.service.get(url, {
      headers: {
        "Content-Type": "application/json",
        "Accept": "image/png",
        Authorization: `Bearer ${this.props.token}`,
      },
      data: null
    })
  }

  put(path, payload) {
    let url = this.baseUrl + path;
    return this.service.put(url, payload);
  }

  patch(path, payload) {
    let url = this.baseUrl + path;
    return this.service.patch(url, payload);
  }

  post(path, payload) {
    let url = this.baseUrl + path;
    return this.service.post(url, payload);
  }

  delete(path) {
    let url = this.baseUrl + path;
    return this.service.delete(url);
  }
}
