import React from "react";

// Libs
import Dropzone from "react-dropzone";
import { toast } from "react-toastify";

// Imgs
import UploadIcon from "assets/upload-icon.svg";

// Styles
import {
  ContainerFilesForm,
  ContainerUploadFiles,
  UploadTitleText,
  UploadIconImg,
  UploadText,
  ViewImageContainer,
  ViewImage,
  RemoveFileText,
} from "./styles";

function FilesForm({ companyData, setCompanyData }) {
  return (
    <ContainerFilesForm>
      <UploadTitleText>Logo</UploadTitleText>
      {companyData.files.logo ? (
        <ViewImageContainer>
          <ViewImage src={companyData.files.logo} alt="logo" />
          <RemoveFileText
            onClick={() => {
              setCompanyData({
                ...companyData,
                files: {
                  ...companyData.files,
                  logo: null,
                },
              });
            }}
          >
            Remover
          </RemoveFileText>
        </ViewImageContainer>
      ) : (
        <Dropzone
          accept={"image/jpeg,image/png"}
          maxFiles={1}
          multiple={false}
          maxSize={1000000}
          onDropAccepted={(acceptedFiles) => {
            const reader = new FileReader();

            reader.readAsDataURL(acceptedFiles[0]);
            reader.onload = (event) => {
              setCompanyData({
                ...companyData,
                files: {
                  ...companyData.files,
                  logo: event.target.result,
                },
              });
            };
          }}
          onDropRejected={() => {
            toast.error(
              "Verifique se o tamanho da imagem não ultrapassa 1MB e se possui um dos formatos: JPEG ou PNG"
            );
          }}
        >
          {({ getRootProps, getInputProps }) => (
            <ContainerUploadFiles {...getRootProps()}>
              <input {...getInputProps()} />
              <UploadIconImg src={UploadIcon}></UploadIconImg>
              <UploadText>Arraste os arquivos aqui</UploadText>
              <UploadText>ou</UploadText>
              <UploadText actived>Buscar no Computador</UploadText>
            </ContainerUploadFiles>
          )}
        </Dropzone>
      )}

      <UploadTitleText>Marca D'agua</UploadTitleText>
      {companyData.files.waterMark ? (
        <ViewImageContainer>
          <ViewImage src={companyData.files.waterMark} alt="waterMark" />
          <RemoveFileText
            onClick={() => {
              setCompanyData({
                ...companyData,
                files: {
                  ...companyData.files,
                  waterMark: null,
                },
              });
            }}
          >
            Remover
          </RemoveFileText>
        </ViewImageContainer>
      ) : (
        <Dropzone
          accept={"image/jpeg,image/png"}
          maxFiles={1}
          multiple={false}
          maxSize={1000000}
          onDropAccepted={(acceptedFiles) => {
            const reader = new FileReader();

            reader.readAsDataURL(acceptedFiles[0]);
            reader.onload = (event) => {
              setCompanyData({
                ...companyData,
                files: {
                  ...companyData.files,
                  waterMark: event.target.result,
                },
              });
            };
          }}
          onDropRejected={() => {
            toast.error(
              "Verifique se o tamanho da imagem não ultrapassa 1MB e se possui um dos formatos: JPEG ou PNG"
            );
          }}
        >
          {({ getRootProps, getInputProps }) => (
            <ContainerUploadFiles {...getRootProps()}>
              <input {...getInputProps()} />
              <UploadIconImg src={UploadIcon}></UploadIconImg>
              <UploadText>Arraste os arquivos aqui</UploadText>
              <UploadText>ou</UploadText>
              <UploadText actived>Buscar no Computador</UploadText>
            </ContainerUploadFiles>
          )}
        </Dropzone>
      )}
    </ContainerFilesForm>
  );
}

export default FilesForm;
