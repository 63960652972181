import React from "react";

// Components
import Button from "components/Button";

// Libs
import { toast } from "react-toastify";

// Services
import DashboardService from "services/dashboard";

// Context
import { useGeneralProvider } from "contexts/general";

// Imgs
import CloseIconSvg from "assets/close-icon.svg";

import {
  BackDrop,
  Container,
  ImageWrapper,
  CloseIcon,
  Header,
  HeaderText,
  FooterContainer,
} from "./styles";

function ModalConfirmResendFirstAccessEmail({
  setShowModal,
  companyId,
  userId,
  userEmail,
  handleCleanFilters,
}) {
  const { setLoading, globalUser } = useGeneralProvider();
  const userPassService = new DashboardService(globalUser);

  const handleResendFirstAccessEmail = () => {
    setLoading(true);

    userPassService
      .updateCompanyUserPassword({
        companyId: companyId,
        userId: userId,
        userEmail: userEmail,
      })
      .then(() => {
        toast.success("E-mail de primeiro acesso reenviado com sucesso!");
        handleCleanFilters();
        setShowModal(false);
      })
      .catch((err) => {
        setLoading(false);

        if (err?.response?.data?.message) {
          toast.error(err.response.data.message);
        } else {
          toast.error("Falha ao reenviar o e-mail de primeiro acesso do usuário");
        }
      });
  };

  return (
    <BackDrop>
      <Container>
        <Header>
          <ImageWrapper />
          <HeaderText>Deseja reenviar o e-mail de primeiro acesso deste usuário?</HeaderText>
          <ImageWrapper onClick={() => setShowModal(false)}>
            <CloseIcon src={CloseIconSvg} />
          </ImageWrapper>
        </Header>
        <FooterContainer>
          <Button label="Confirmar" onClick={handleResendFirstAccessEmail} />
        </FooterContainer>
      </Container>
    </BackDrop>
  );
}

export default ModalConfirmResendFirstAccessEmail;
