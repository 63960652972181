import React from "react";

// Imgs
import CloseIconSvg from "assets/close-icon.svg";

import {
  BackDrop,
  Container,
  Header,
  ImageWrapper,
  CloseIcon,
  BodyText,
  FooterContainer,
  Button,
  InvertedButton,
} from "./styles";

function ModalConfirmRemove({ showModal, setShowModal, confirm }) {
  return (
    <BackDrop>
      <Container>
        <Header>
          <ImageWrapper
            onClick={() => setShowModal({ show: false, value: "" })}
          >
            <CloseIcon src={CloseIconSvg} />
          </ImageWrapper>
        </Header>
        <BodyText>
          {
            "Deseja remover esta imagem? Esta ação será confirmada quando o grupo for atualizado."
          }
        </BodyText>
        <FooterContainer>
          <InvertedButton
            onClick={() => setShowModal({ show: false, value: "" })}
          >
            Cancelar
          </InvertedButton>
          <Button
            onClick={() => {
              confirm();
              setShowModal({ show: false, value: showModal.value });
            }}
          >
            Continuar
          </Button>
        </FooterContainer>
      </Container>
    </BackDrop>
  );
}

export default ModalConfirmRemove;
