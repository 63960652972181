// Utils
import { getDefaultPalleteRgb } from "utils";

export const defaultPaletteValue = { name: "Rain", nameKey: "rain", palleteRgb: getDefaultPalleteRgb() };

export const paletteStageWidth = 40;

export const tempStageWidth = 60;

export const paletteMargin = 20;

export const imageTextHeight = 20;

export const defaultVirtualCanvasWidth = () =>
    window.innerWidth - paletteStageWidth - tempStageWidth - 380;

export const defaultVirtualCanvasHeight = () => window.innerHeight + 100;

export const minTemperatureCelsius = 5;

export const maxTemperatureCapCelsius = 1000;
