import styled from "styled-components";

const CustomDivScroll = styled.div`
  ::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }
  ::-webkit-scrollbar-button {
    width: 0px;
    height: 0px;
  }
  ::-webkit-scrollbar-thumb {
    background: #0073ab;
    border-radius: 20px;
  }
  ::-webkit-scrollbar-thumb:hover {
    background: #0073ab;
  }
  ::-webkit-scrollbar-thumb:active {
    background: #0073ab;
  }
  ::-webkit-scrollbar-track {
    background: transparent;
    border-radius: 50px;
  }
  ::-webkit-scrollbar-track:hover {
    background: transparent;
  }
  ::-webkit-scrollbar-track:active {
    background: transparent;
  }
  ::-webkit-scrollbar-corner {
    background: transparent;
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  padding-bottom: 20px;
`;

export const GalleryContainer = styled(CustomDivScroll)`
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: auto;
`;

export const GalleryImage = styled.img`
  width: 160px;
  height: 160px;
  object-fit: cover;
  position: absolute;
  border: 1px solid #e1e1e1;
  border-radius: 20px;

  cursor: pointer;

  :hover {
    opacity: 0.7;
  }

  :last-of-type {
    margin: 0;
  }
`;

export const ContainerGalleryVideo = styled.div`
  display: flex;
  position: relative;
  
  :hover {
    opacity: 0.7;
  }
`;

export const PlayVideo = styled.div`
  cursor: pointer;
  position: absolute;
  z-index: 999;
  top: 40px;
  left: 40px;
`;

export const GalleryImageContainer = styled.div`
  width: 160px;
  min-width: 160px;
  min-height: 160px;
  margin-bottom: 20px;
  overflow: hidden;
  position: relative;
  z-index: 0;
`;

export const EmptyContainer = styled.div`
  margin-top: 10px;
  width: 100%;
  font-size: 12px;
`;

export const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 20px;
  padding-bottom: 10px;
`;

export const HeaderText = styled.p`
  color: #38393d;
  font-size: 18px;
  font-weight: bold;
  margin-right: 20px;
`;

export const GroupContainer = styled.div`
  width: 240px;
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid #e1e1e1;
  margin-bottom: 10px;
  padding-bottom: 20px;
  user-select: none;
`;

export const GroupHeader = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  height: 32px;
  align-items: center;
  margin-bottom: 4px;
`;

export const GroupTitle = styled.p`
  color: #0270d6;
  font-size: 16px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const EditGroupIconContainer = styled.div`
  height: 28px;
  width: 28px;
  min-height: 28px;
  min-width: 28px;
  background-color: #f1f1f1;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 20px;
  cursor: pointer;

  svg {
    width: 18px;
    height: 18px;
    fill: #38393d;
  }

  :hover {
    opacity: 0.7;
  }
`;

export const GroupImagesContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 6px 5px;
  cursor: pointer;

  :hover {
    opacity: 0.7;
  }
`;

export const GroupImageWrapper = styled.div`
  border-radius: 20px;
  width: 115px;
  height: 115px;
  min-width: 115px;
  min-height: 115px;
  overflow: hidden;
  border: 1px solid #e1e1e1;
  position: relative;
`;

export const MoreInfoGroupImage = styled.span`
  background-color: rgba(0, 0, 0, 0.4);
  display: flex;
  width: 115px;
  height: 115px;
  justify-content: center;
  align-items: center;
  position: absolute;
  opacity: ${(props) => (props.index === 3 ? 1 : 0)};
  font-size: 26px;
  color: #ffffff;
`;

export const GroupImage = styled.img`
  width: 115px;
  height: 115px;
  object-fit: cover;
  position: absolute;
`;
