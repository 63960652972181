import styled from "styled-components";

export const Container = styled.div`
  height: 100vh;
  display: flex;
  flex-direction: row;
`;

export const BackContainer = styled.div`
  height: 100%;
  width: 40%;
  background-color: #f8feff;
  display: flex;
  justify-content: center;
  align-items: center;
  user-select: none;
  pointer-events: none;
`;

export const LoginContainer = styled.div`
  width: 60%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const LoginFormContainer = styled.form`
  display: flex;
  flex-direction: column;
  width: 432px;
`;

export const ImageBg = styled.img`
  max-width: 100%;
  height: auto;
  width: auto;
  max-height: 100%;
  transform: rotate(-25deg);
`;

export const LoginLogo = styled.img`
  width: 65px;
  height: 76px;
  margin-bottom: 24px;
  user-select: none;
  pointer-events: none;
`;

export const TitleText = styled.p`
  margin: 0 0 16px 0;
  padding: 0;
  color: #1a1a1a;
  font-size: 32px;
`;
export const BodyText = styled.p`
  margin: 0 0 50px 0;
  padding: 0;
  color: #525252;
  font-size: 20px;
`;

export const LoginBottomContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 56px;
`;

export const ForgetPasswordText = styled.p`
  margin: 0;
  padding: 0;
  color: #38393d;
  font-size: 14px;
  text-decoration: underline;
  cursor: pointer;
`;

export const ErrorText = styled.p`
  margin: 0;
  padding: 0;
  color: #c73734;
  font-size: 12px;
  margin-top: 8px;
`;
