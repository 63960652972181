/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from "react";

// Libs
import { useHistory } from "react-router-dom";

// Components
import ModalSignout from "components/ModalSignout";

// Context
import { useGeneralProvider } from "contexts/general";

// Imgs
import Clients from "assets/clients.svg";
import ClientsSelected from "assets/clients-selected.svg";
import Scientific from "assets/scientific.svg";
import ScientificTest from "assets/collect.svg";
import ScientificSelected from "assets/scientific-selected.svg";
import ScientificSelectedTest from "assets/collect-selected.svg";
import Samples from "assets/samples.svg";
import SampleSelected from "assets/samples-selected.svg";
import Lists from "assets/lists.svg";
import ListsSelected from "assets/lists-selected.svg";
import Customers from "assets/customers.svg";
import CustomersSelected from "assets/customers-selected.svg";
import SignoutImage from "assets/signout.svg";

import { Container, MenuIconContainer, ImageIcon } from "./styles";

function SideBar() {
  const history = useHistory();
  const { selectedPage, isAdmin, panelAccess } = useGeneralProvider();
  const [showModalSignout, setShowModalSignout] = useState(false);

  return (
    <Container>
      {showModalSignout ? (
        <ModalSignout setShowModal={setShowModalSignout} />
      ) : null}
      <div>
        {isAdmin ? (
          <MenuIconContainer
            selected={selectedPage === "/clientes"}
            onClick={() => history.push("/clientes")}
            title="Gerenciar Clientes"
          >
            <ImageIcon
              src={selectedPage === "/clientes" ? CustomersSelected : Customers}
            />
          </MenuIconContainer>
        ) : null}

        {panelAccess[0]?.hasAccess ? (
          <MenuIconContainer
            selected={selectedPage === "/painel-cliente"}
            onClick={() => history.push("/painel-cliente")}
            title="Cliente"
          >
            <ImageIcon
              src={
                selectedPage === "/painel-cliente"
                  ? ClientsSelected
                  : Clients
              }
            />
          </MenuIconContainer>
        ) : null}

        {panelAccess[1]?.hasAccess ? (
          <MenuIconContainer
            selected={selectedPage === "/painel-cientifico"}
            onClick={() => history.push("/painel-cientifico")}
            title="Científico"
          >
            <ImageIcon
              src={
                selectedPage === "/painel-cientifico"
                  ? ScientificSelected
                  : Scientific
              }
            />
          </MenuIconContainer>
        ) : null}

        {panelAccess[2]?.hasAccess ? (
          <MenuIconContainer
            selected={selectedPage === "/painel-laudo"}
            onClick={() => history.push("/painel-laudo")}
            title="Laudo"
          >
            <ImageIcon
              src={
                selectedPage === "/painel-laudo"
                  ? ScientificSelectedTest
                  : ScientificTest
              }
            />
          </MenuIconContainer>
        ) : null}

        {panelAccess[3]?.hasAccess ? (
          <MenuIconContainer
          selected={selectedPage === "/listas-salvas"}
          onClick={() => history.push("/listas-salvas")}
          title="Minhas Listas"
        >
            <ImageIcon
              src={
                selectedPage === "/listas-salvas" ? ListsSelected : Lists
              }
            />
        </MenuIconContainer>
        ) : null}

      </div>

      <div>
        <MenuIconContainer
          onClick={() => setShowModalSignout(true)}
          title="Encerrar Sessão"
        >
          <ImageIcon src={SignoutImage} />
        </MenuIconContainer>
      </div>
    </Container>
  );
}

export default SideBar;
