import React, { useState, useEffect } from "react";

// Libs
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";

// Context
import { useGeneralProvider } from "contexts/general";

// Images
import LoginBG from "assets/login-bg.svg";
import LoginLogoImg from "assets/login-logo.svg";

// Components
import Input from "components/Input";
import Button from "components/Button";
import SuccessPassword from "./successPassword";

// Services
import { completeRegistration } from "services/authentication";

// Styles
import {
  Container,
  BackContainer,
  LoginContainer,
  LoginFormContainer,
  ImageBg,
  LoginLogo,
  TitleText,
  BodyText,
  LoginBottomContainer,
  ForgetPasswordText,
  ErrorText,
  TextSubContainer,
} from "./styles";

function CompleteRegistration() {
  const history = useHistory();

  const {
    setLoading,
    completeUserData,
    completeRegistrationError,
    setCompleteRegistrationError,
  } = useGeneralProvider();

  const [email, setEmail] = useState("");
  const [receivedUser, setReceivedUser] = useState(null);
  const [emailError, setEmailError] = useState(null);
  const [password, setPassword] = useState("");
  const [passwordError, setPasswordError] = useState(null);
  const [confirmPassword, setConfirmPassword] = useState("");
  const [confirmPasswordError, setConfirmPasswordError] = useState(null);

  const [isSuccessPassword, serIsSuccessPassword] = useState(false);
  const [btnConfirmEnabled, setBtnConfirmEnabled] = useState(false);

  useEffect(() => {
    setEmail(completeUserData?.email);
    setReceivedUser(completeUserData?.receivedUser);
  }, [completeUserData]);

  useEffect(() => {
    if (completeRegistrationError) {
      handleCompleteRegistrationError(completeRegistrationError);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [completeRegistrationError]);

  useEffect(() => {
    if (
      password &&
      !passwordError &&
      confirmPassword &&
      !confirmPasswordError
    ) {
      setBtnConfirmEnabled(true);
    } else {
      setBtnConfirmEnabled(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [passwordError, confirmPasswordError]);

  const handleCompleteRegistrationError = (completeRegistrationError) => {
    switch (completeRegistrationError) {
      case "UserNotFoundException":
        setEmailError("E-mail não cadastrado.");
        break;

      default:
        toast.error("Não foi possível concluir seu cadastro! Tente novamente.");
        break;
    }

    setCompleteRegistrationError(null);
  };

  return (
    <Container>
      <BackContainer>
        <ImageBg alt="bg" src={LoginBG} />
      </BackContainer>
      <LoginContainer>
        <LoginFormContainer>
          <LoginLogo alt="logo" src={LoginLogoImg} />
          <TitleText>Predikta Dash</TitleText>
          <BodyText>Concluir cadastro</BodyText>

          <>
            {isSuccessPassword ? (
              <SuccessPassword />
            ) : (
              <>
                <Input label={"E-mail"} value={email} disabled />
                {emailError ? <ErrorText>{emailError}</ErrorText> : null}

                <form>
                  <Input
                    inputError={passwordError}
                    maxLength={15}
                    label={"Senha"}
                    value={password}
                    autoComplete="off"
                    isPassword
                    onChange={(e) => {
                      if (e.target.value) {
                        if (e.target.value !== confirmPassword) {
                          setConfirmPasswordError(
                            "As senhas não são iguais. Tente novamente."
                          );
                        } else {
                          setConfirmPasswordError(null);
                        }

                        if (
                          e.target.value.length >= 6 &&
                          e.target.value.length <= 15
                        ) {
                          if (
                            /^(?=.*[a-z])(?=.*\d)(?=.*[#$%&@)\\.\]{`~^=+:;|'",<>?/}[(*_!-])[A-Za-z\d#$%&@)\\.\]{`~^=+:;|'",<>?/}[(*_!-]{6,}$/.test(
                              e.target.value
                            )
                          ) {
                            setPasswordError(null);
                          } else {
                            setPasswordError(
                              "A senha não cumpre os requerimentos."
                            );
                          }
                        } else {
                          setPasswordError(
                            "A senha não cumpre os requerimentos."
                          );
                        }
                      } else {
                        setPasswordError(null);
                      }
                      setPassword(e.target.value);
                    }}
                  />
                </form>
                {passwordError ? <ErrorText>{passwordError}</ErrorText> : null}

                <form>
                  <Input
                    inputError={confirmPasswordError}
                    maxLength={15}
                    label={"Confirmação de senha"}
                    value={confirmPassword}
                    autoComplete="off"
                    isPassword
                    onChange={(e) => {
                      if (password !== e.target.value) {
                        setConfirmPasswordError(
                          "As senhas não são iguais. Tente novamente."
                        );
                      } else {
                        setConfirmPasswordError(null);
                      }
                      setConfirmPassword(e.target.value);
                    }}
                  />
                </form>
                {confirmPasswordError ? (
                  <ErrorText>{confirmPasswordError}</ErrorText>
                ) : null}
                <TextSubContainer>
                  <p>Requerimentos:</p>
                  <p>- Entre 6 e 15 dígitos</p>
                  <p>- Ao menos 1 letra minúscula</p>
                  <p>- Ao menos 1 número</p>
                  <p>- Ao menos um símbolo (Ex.: $&@!?)</p>
                </TextSubContainer>
                <LoginBottomContainer>
                  <ForgetPasswordText
                    onClick={() => {
                      if (history.length > 2) {
                        history.goBack();
                        return;
                      }

                      history.push("/");
                    }}
                  >
                    Voltar
                  </ForgetPasswordText>
                  <Button
                    onClick={() => {
                      completeRegistration(
                        {
                          user: receivedUser,
                          password: password,
                        },
                        setLoading,
                        () => {
                          serIsSuccessPassword(true);
                        },
                        setCompleteRegistrationError
                      );
                    }}
                    disabled={!btnConfirmEnabled}
                    label={"Confirmar"}
                  />
                </LoginBottomContainer>
              </>
            )}
          </>
        </LoginFormContainer>
      </LoginContainer>
    </Container>
  );
}

export default CompleteRegistration;
