import styled from "styled-components";

export const Container = styled.div`
  textarea {
    height: 160px;
    min-height: 38px;
    border: ${(props) =>
      props.inputError
        ? "1px solid #ef1515"
        : props.inputSuccess
        ? "1px solid #40A99E"
        : "1px solid #9D9E9D"};
    border-radius: 4px;
    outline: none;
    padding: 10px;
    background-color: ${(props) => (props.disabled ? "#F2F2F2" : "#ffffff")};
    font-size: 14px;
    width: 100%;
    resize: vertical;
  }

  textarea::-ms-reveal,
  textarea::-ms-clear {
    display: none;
  }
`;
