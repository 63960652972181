/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";

// Libs
import { toast } from "react-toastify";

// Context
import { useGeneralProvider } from "contexts/general";

// Services
import DashboardService from "services/dashboard";
import { signOut } from "services/authentication";

// Components
import Header from "../Header";
import SideBar from "../SideBar";

import { Container, Body, BodyContent } from "./styles";

function AppContainer({ children }) {
  const [fetchedUser, setFetchedUser] = useState(false);
  const { setLoading, globalUser, setIsAdmin, setPanelAccess, setUser } =
    useGeneralProvider();
  const userPassService = new DashboardService(globalUser);

  useEffect(() => {
    verifyUserInfo();
  }, []);

  const verifyUserInfo = () => {
    setLoading(true);

    userPassService
      .getUserInfo()
      .then((o) => {
        if (o.data) {
          const response = o.data;

          setIsAdmin(response.isAdmin);
          setPanelAccess(response.panelAccess);

          setFetchedUser(true);
        }
      })
      .catch(() => {
        toast.error("Falha ao buscar informações de acesso do usuário");
        signOut(setLoading, setUser);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <Container>
      <Header />
      <Body>
        <SideBar />
        <BodyContent>{fetchedUser ? children : null}</BodyContent>
      </Body>
    </Container>
  );
}

export default AppContainer;
