import styled from "styled-components";

export const Container = styled.div`
  overflow: none;
`;

export const Body = styled.div`
  display: flex;
  flex-direction: row;
  height: 100%;
`;

export const BodyContent = styled.div`
  width: 100%;
  padding: 10px 0 26px 0;
  display: flex;
  justify-self: center;
  overflow: auto;
  max-height: calc(100vh - 64px);

  ::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }
  ::-webkit-scrollbar-button {
    width: 0px;
    height: 0px;
  }
  ::-webkit-scrollbar-thumb {
    background: #0073ab;
    border-radius: 20px;
  }
  ::-webkit-scrollbar-thumb:hover {
    background: #0073ab;
  }
  ::-webkit-scrollbar-thumb:active {
    background: #0073ab;
  }
  ::-webkit-scrollbar-track {
    background: transparent;
    border-radius: 50px;
  }
  ::-webkit-scrollbar-track:hover {
    background: transparent;
  }
  ::-webkit-scrollbar-track:active {
    background: transparent;
  }
  ::-webkit-scrollbar-corner {
    background: transparent;
  }
`;
