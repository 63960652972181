import React, { useRef, useState, useEffect } from "react";

// Imgs
import ArrowDown from "assets/arrow-down.svg";

// Libs
import DateRangePicker from "react-bootstrap-daterangepicker";
import moment from "moment";

import { Container, ButtonContainer, ArrowIcon, TextButton } from "./styles";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-daterangepicker/daterangepicker.css";

function RangeDatePicker({ label, setSelectedDate, resetDatePicker }) {
  const btnRef = useRef(null);
  const [isOnFocus, setIsOnFocus] = useState(false);
  const [isOnReset, setIsOnReset] = useState(false);

  useEffect(() => {
    setIsOnReset(true);
    setTimeout(() => {
      setIsOnReset(false);
    }, 0);
  }, [resetDatePicker]);

  return (
    <Container>
      {isOnReset ? (
        <ButtonContainer ref={btnRef} isOnFocus={isOnFocus}>
          <TextButton>{label}</TextButton>
          <ArrowIcon src={ArrowDown} isOnFocus={isOnFocus} />
        </ButtonContainer>
      ) : (
        <DateRangePicker
          initialSettings={{
            maxDate: moment().format("DD/MM/YYYY"),
            autoApply: true,
            locale: {
              format: "DD/MM/YYYY",
              monthNames: [
                "Janeiro",
                "Fevereiro",
                "Março",
                "Abril",
                "Maio",
                "Junho",
                "Julho",
                "Agosto",
                "Setembro",
                "Outubro",
                "Novembro",
                "Dezembro",
              ],
              daysOfWeek: ["D", "S", "T", "Q", "Q", "S", "S"],
            },
          }}
          onShow={() => setIsOnFocus(true)}
          onHide={() => setIsOnFocus(false)}
          onCallback={(start, end) => {
            const formatedStart = moment(start).format("YYYY-MM-DDT00:00:00");
            const formatedEnd = moment(end).format("YYYY-MM-DDT00:00:00");
            setSelectedDate({
              start: formatedStart,
              end: formatedEnd,
            });
          }}
        >
          <ButtonContainer ref={btnRef} isOnFocus={isOnFocus}>
            <TextButton>{label}</TextButton>
            <ArrowIcon src={ArrowDown} isOnFocus={isOnFocus} />
          </ButtonContainer>
        </DateRangePicker>
      )}
    </Container>
  );
}

export default RangeDatePicker;
