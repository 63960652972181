import React, { useState, useEffect } from "react";

// Libs
import { toast } from "react-toastify";

// Context
import { useGeneralProvider } from "contexts/general";

// Components
import Input from "components/Input";
import Button from "components/Button";
import SuccessPassword from "./successPassword";

// Services
import { forgotPassword, confirmForgotPassword } from "services/authentication";

// Styles
import {
  LoginBottomContainer,
  ForgetPasswordText,
  ErrorText,
  TextSubContainer,
  ResendContainer,
  TextResend,
  ResendBtn,
} from "./styles";

function SettingNewPassword({ email, setIsSettingNewPassword }) {
  const {
    setLoading,
    setForgotPasswordError,
    confirmForgotPasswordError,
    setConfirmForgotPasswordError,
  } = useGeneralProvider();

  const [pin, setPin] = useState("");
  const [pinError, setPinError] = useState(null);
  const [pinSuccess, setPinSuccess] = useState(false);

  const [password, setPassword] = useState("");
  const [passwordError, setPasswordError] = useState(null);
  const [confirmPassword, setConfirmPassword] = useState("");
  const [confirmPasswordError, setConfirmPasswordError] = useState(null);

  const [isSuccessPassword, serIsSuccessPassword] = useState(false);
  const [btnConfirmEnabled, setBtnConfirmEnabled] = useState(false);

  useEffect(() => {
    if (confirmForgotPasswordError) {
      handleConfirmForgotPasswordError(confirmForgotPasswordError);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [confirmForgotPasswordError]);

  useEffect(() => {
    if (
      pinSuccess &&
      password &&
      !passwordError &&
      confirmPassword &&
      !confirmPasswordError
    ) {
      setBtnConfirmEnabled(true);
    } else {
      setBtnConfirmEnabled(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pinSuccess, passwordError, confirmPasswordError]);

  const handleConfirmForgotPasswordError = (confirmForgotPasswordError) => {
    switch (confirmForgotPasswordError) {
      case "ExpiredCodeException":
        setPinError("PIN expirado. Solicite um novo.");
        setPin("");
        setPinSuccess(false);
        break;

      case "LimitExceededException":
        setPinError(
          "Limite de tentativas excedido! Tente novamente depois de algum tempo."
        );
        break;

      case "UserNotFoundException":
        setPinError("E-mail não cadastrado.");
        break;

      case "CodeMismatchException":
        setPinError("PIN incorreto. Tente novamente.");
        setPin("");
        setPinSuccess(false);
        break;

      default:
        toast.error(
          "Não foi possível cadastrar uma nova senha! Tente novamente."
        );
        break;
    }

    setConfirmForgotPasswordError(null);
  };

  return (
    <>
      {isSuccessPassword ? (
        <SuccessPassword />
      ) : (
        <>
          <form>
            <Input
              inputSuccess={pinSuccess}
              inputError={pinError}
              maxLength={6}
              autoComplete="off"
              label={"PIN"}
              value={pin}
              onChange={(e) => {
                if (e.target.value && e.target.value.length === 6) {
                  setPinSuccess(true);
                } else {
                  setPinSuccess(false);
                }
                setPinError(null);

                if (/^-?\d*$/.test(e.target.value)) {
                  setPin(e.target.value);
                }
              }}
            />
          </form>
          {pinError ? <ErrorText>{pinError}</ErrorText> : null}

          <ResendContainer>
            <TextResend>Não recebeu o código?</TextResend>
            <ResendBtn
              onClick={() => {
                forgotPassword(
                  {
                    username: email,
                  },
                  setLoading,
                  setForgotPasswordError,
                  () => {
                    toast.success(
                      "E-mail de recuperação de senha reenviado com sucesso."
                    );
                  }
                );
              }}
            >
              Reenviar
            </ResendBtn>
          </ResendContainer>

          <form>
            <Input
              inputError={passwordError}
              maxLength={15}
              label={"Senha"}
              value={password}
              autoComplete="off"
              isPassword
              onChange={(e) => {
                if (e.target.value) {
                  if (e.target.value !== confirmPassword) {
                    setConfirmPasswordError(
                      "As senhas não são iguais. Tente novamente."
                    );
                  } else {
                    setConfirmPasswordError(null);
                  }

                  if (
                    e.target.value.length >= 6 &&
                    e.target.value.length <= 15
                  ) {
                    if (
                      /^(?=.*[a-z])(?=.*\d)(?=.*[#$%&@)\\.\]{`~^=+:;|'",<>?/}[(*_!-])[A-Za-z\d#$%&@)\\.\]{`~^=+:;|'",<>?/}[(*_!-]{6,}$/.test(
                        e.target.value
                      )
                    ) {
                      setPasswordError(null);
                    } else {
                      setPasswordError("A senha não cumpre os requerimentos.");
                    }
                  } else {
                    setPasswordError("A senha não cumpre os requerimentos.");
                  }
                } else {
                  setPasswordError(null);
                }
                setPassword(e.target.value);
              }}
            />
          </form>
          {passwordError ? <ErrorText>{passwordError}</ErrorText> : null}

          <form>
            <Input
              inputError={confirmPasswordError}
              maxLength={15}
              label={"Confirmação de senha"}
              value={confirmPassword}
              autoComplete="off"
              isPassword
              onChange={(e) => {
                if (password !== e.target.value) {
                  setConfirmPasswordError(
                    "As senhas não são iguais. Tente novamente."
                  );
                } else {
                  setConfirmPasswordError(null);
                }
                setConfirmPassword(e.target.value);
              }}
            />
          </form>
          {confirmPasswordError ? (
            <ErrorText>{confirmPasswordError}</ErrorText>
          ) : null}
          <TextSubContainer>
            <p>Requerimentos:</p>
            <p>- Entre 6 e 15 dígitos</p>
            <p>- Ao menos 1 letra minúscula</p>
            <p>- Ao menos 1 número</p>
            <p>- Ao menos um símbolo (Ex.: $&@!?)</p>
          </TextSubContainer>
          <LoginBottomContainer>
            <ForgetPasswordText
              onClick={() => {
                setIsSettingNewPassword(false);
              }}
            >
              Voltar
            </ForgetPasswordText>
            <Button
              onClick={() => {
                confirmForgotPassword(
                  {
                    username: email,
                    password: password,
                    confirmationCode: pin,
                  },
                  setLoading,
                  setConfirmForgotPasswordError,
                  () => {
                    serIsSuccessPassword(true);
                  }
                );
              }}
              disabled={!btnConfirmEnabled}
              label={"Confirmar"}
            />
          </LoginBottomContainer>
        </>
      )}
    </>
  );
}

export default SettingNewPassword;
