import React from "react";

import { FaTrashAlt } from "react-icons/fa";

import { Container } from "./styles";

function RemoveImage({
  disabled,
  selectedImage,
  setBeforeRemove,
  imagesOnCanvas,
  onRemoveImageOnIndex
}) {
  const handleRemove = () => {
    handleRemoveFromCanvas();
  };

  const handleRemoveFromCanvas = () => {

    const itemIndex = imagesOnCanvas.findIndex(
      (image) => image.imageKey === selectedImage
    );

    const finalIndex = itemIndex > -1 ? itemIndex : null;

    onRemoveImageOnIndex(finalIndex);
  };

  return (
    <Container
      disabled={disabled}
      onClick={() => {
        if (!disabled) {
          setBeforeRemove();
          setTimeout(() => {
            handleRemove();
          }, 10);
        }
      }}
    >
      <FaTrashAlt />
    </Container>
  );
}

export default RemoveImage;
