import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
`;

export const TitleText = styled.p`
  margin: 0 0 10px 0;
  padding: 0;
  color: #38393d;
  font-size: 16px;
`;

export const InfoCardContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-bottom: 44px;
  margin-top: 20px;
`;

export const ContentWrapper = styled.div`
  width: calc(100% - 200px);
`;

export const FilterContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-bottom: 20px;
`;

export const FooterContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 32px 0 32px 0;
`;

export const ButtonsContainer = styled.div`
  display: flex;
`;
