import React from "react";

// Imgs
import Search from "assets/search.svg";

import { Container, SearchIcon, Input } from "./styles";

function TextFilter({ searchText, setSearchText, placeHolder }) {
  const handleTextChange = (e) => {
    setSearchText(e.target.value);
  };

  return (
    <Container>
      <SearchIcon src={Search} />
      <Input
        value={searchText}
        onChange={handleTextChange}
        placeholder={placeHolder}
      />
    </Container>
  );
}

export default TextFilter;
