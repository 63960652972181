import styled from "styled-components";

const CustomDivScroll = styled.div`
  ::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }
  ::-webkit-scrollbar-button {
    width: 0px;
    height: 0px;
  }
  ::-webkit-scrollbar-thumb {
    background: #0073ab;
    border-radius: 20px;
  }
  ::-webkit-scrollbar-thumb:hover {
    background: #0073ab;
  }
  ::-webkit-scrollbar-thumb:active {
    background: #0073ab;
  }
  ::-webkit-scrollbar-track {
    background: transparent;
    border-radius: 50px;
  }
  ::-webkit-scrollbar-track:hover {
    background: transparent;
  }
  ::-webkit-scrollbar-track:active {
    background: transparent;
  }
  ::-webkit-scrollbar-corner {
    background: transparent;
  }
`;

export const BackDrop = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(56, 57, 61, 0.2);
  z-index: 999;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
`;

export const Container = styled(CustomDivScroll)`
  background-color: #fefefe;
  /* width: 636px; */
  min-height: 200px;
  max-width: calc(100vw - 200px);
  max-height: calc(100vh - 100px);
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  overflow-x: auto;

  @media (max-width: 600px) {
    max-width: calc(100vw - 20px);
    max-height: calc(100vh - 20px);
  }
`;

export const HeaderContainer = styled.div`
  width: 100%;
  margin-bottom: 32px;
`;

export const HeaderText = styled.p`
  font-size: 16px;
  color: #38393d;
`;

export const HeaderCloseContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  height: 30px;
  padding-top: 16px;
  padding-right: 16px;
  padding-left: 16px;
  align-items: center;
`;

export const ImageWrapper = styled.div`
  cursor: pointer;
  width: 24px;
  height: 24px;

  :hover {
    opacity: 0.7;
  }
`;

export const CloseIcon = styled.img`
  width: 24px;
  height: 24px;
  user-select: none;
  pointer-events: none;
`;

export const BodyContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 0 16px 0 16px;
`;

export const FooterContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 16px;
  margin-top: 16px;

  span {
    width: 24px;
  }
`;

export const InputGroup = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 20px;
  min-width: 280px;

  :last-of-type {
    margin-right: 0;
  }
`;

export const InputLabel = styled.p`
  color: #1a1a1a;
  font-size: 14px;
  margin-bottom: 6px;
`;
