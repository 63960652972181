import React, { useState, useEffect } from "react";

// Libs
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";

// Images
import LoginBG from "assets/login-bg.svg";
import LoginLogoImg from "assets/login-logo.svg";

// Context
import { useGeneralProvider } from "contexts/general";

// Components
import Input from "components/Input";
import Button from "components/Button";
import SettingNewPassword from "./settingNewPassword";

// Services
import { forgotPassword } from "services/authentication";

// Styles
import {
  Container,
  BackContainer,
  LoginContainer,
  LoginFormContainer,
  ImageBg,
  LoginLogo,
  TitleText,
  BodyText,
  LoginBottomContainer,
  ForgetPasswordText,
  ErrorText,
  TextSubContainer,
} from "./styles";

function RecoverPassword() {
  const { setLoading, forgotPasswordError, setForgotPasswordError } =
    useGeneralProvider();
  const history = useHistory();
  const [isSettingNewPassword, setIsSettingNewPassword] = useState(false);

  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState(null);

  const [madeRequest, setMadeRequest] = useState(false);

  useEffect(() => {
    setMadeRequest(false);
  }, []);

  useEffect(() => {
    if (forgotPasswordError) {
      handleForgotPasswordError(forgotPasswordError);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [forgotPasswordError]);

  const handleForgotPasswordError = (forgotPasswordError) => {
    switch (forgotPasswordError) {
      case "LimitExceededException":
        setEmailError(
          "Limite de tentativas excedido! Tente novamente depois de algum tempo."
        );
        break;

      case "UserNotFoundException":
        setEmailError("E-mail não cadastrado.");
        break;

      default:
        toast.error("Ocorreu um erro no sistema. Por favor tente novamente.");
        break;
    }

    setForgotPasswordError(null);
  };

  const validateEmail = (text) => {
    let reg = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
    if (text) {
      if (reg.test(text) === false) {
        setEmail(text);
        setEmailError("E-mail inválido.");
        return false;
      } else {
        setEmail(text);
        setEmailError(null);
      }
    } else {
      setEmail(text);
      setEmailError(null);
    }
  };

  return (
    <Container>
      <BackContainer>
        <ImageBg alt="bg" src={LoginBG} />
      </BackContainer>
      <LoginContainer>
        <LoginFormContainer>
          <LoginLogo alt="logo" src={LoginLogoImg} />
          <TitleText>Predikta Dash</TitleText>
          <BodyText>Recuperação de senha</BodyText>

          {isSettingNewPassword ? (
            <SettingNewPassword
              email={email}
              setIsSettingNewPassword={setIsSettingNewPassword}
            />
          ) : (
            <>
              <Input
                label={"E-mail"}
                value={email}
                onChange={(e) => validateEmail(e.target.value)}
              />
              {emailError ? <ErrorText>{emailError}</ErrorText> : null}

              {madeRequest ? null : (
                <TextSubContainer>
                  Enviaremos um e-mail com um PIN para você recuperar a sua
                  senha.
                </TextSubContainer>
              )}

              <LoginBottomContainer>
                <ForgetPasswordText onClick={() => history.push("/")}>
                  Voltar
                </ForgetPasswordText>
                <Button
                  onClick={() => {
                    setMadeRequest(true);
                    forgotPassword(
                      {
                        username: email,
                      },
                      setLoading,
                      setForgotPasswordError,
                      () => {
                        toast.success(
                          "E-mail de recuperação de senha enviado com sucesso."
                        );
                        setIsSettingNewPassword(true);
                      }
                    );
                  }}
                  disabled={!email || emailError}
                  label={"Recuperar senha"}
                />
              </LoginBottomContainer>
            </>
          )}
        </LoginFormContainer>
      </LoginContainer>
    </Container>
  );
}

export default RecoverPassword;
