import React, { useEffect, useState } from "react";

// Libs
import moment from "moment";
import {FormControlLabel, Radio} from "@mui/material/";

import {
  InfoCardContainer,
  ItemText,
  GroupDataContainer
} from "./styles";

function FormSection({
  formItem,
  selectedItem,
}) {

  const [showCheckBox, setShowCheckBox] = useState(false);
  const [value, setValue] = useState('');
  const [arrayValue, setArrayValue] = useState([]);

  useEffect(() => {
    if (formItem.type === 'CHECKBOX_LIST'){
      setShowCheckBox(true)
    }
    if (formItem.stringValue){
      if (formItem.name === 'birthDate' || formItem.name === 'examDate') {
        setValue(moment.parseZone(formItem.stringValue).format("DD-MM-YYYY"))
       } else if (selectedItem.screeningSegment === 4) {
        let newValue = formItem.stringValue.split('\n')
        setArrayValue(newValue)
       } else {
        setValue(formItem.stringValue)
       }
    } else {
      setValue(formItem.stringValue)
    }
  }, []);

  return (
    <GroupDataContainer>
      <div>
        <ItemText>{formItem.description}</ItemText>
        {formItem && formItem.length !== 0 && showCheckBox && formItem.arrayValue.length !== 0 ? (
            formItem.arrayValue.map((item, index) => (
              <FormControlLabel disabled control={<Radio checked={item.objectValue.selected}/>} label={item.objectValue.description} />
            )
            )) : selectedItem.screeningSegment === 4 ? ( 
              arrayValue.map(item => (
                <InfoCardContainer size={formItem.size} type={formItem.type}>{item || '-'}</InfoCardContainer>
              ))
            ) : (
            <InfoCardContainer size={formItem.size} type={formItem.type}>{value || '-'}</InfoCardContainer>
          )}
      </div>
    </GroupDataContainer>
  );
}

export default FormSection;
