import Amplify, { Auth } from "aws-amplify";

const amplifyConfig = {
  Auth: {
    region: process.env.REACT_APP_AMPLIFY_REGION,
    userPoolId: process.env.REACT_APP_AMPLIFY_USER_POOL,
    userPoolWebClientId: process.env.REACT_APP_AMPLIFY_CLIENT_ID,
    authenticationFlowType: process.env.REACT_APP_AMPLIFY_FLOW_TYPE,
  },
};

Amplify.configure(amplifyConfig);

const signIn = (
  userData,
  setLoading,
  setUser,
  setSignInError,
  callbackCompleteRegistration
) => {
  setLoading(true);
  Auth.signIn(userData.username, userData.password)
    .then((res) => {
      if (res.challengeName === "NEW_PASSWORD_REQUIRED") {
        callbackCompleteRegistration(res);
      } else {
        setUser({
          token: res.signInUserSession.idToken.jwtToken,
          userAttributes: res.attributes,
        });
        setSignInError(null);
      }
    })
    .catch((err) => {
      setSignInError(err.code);
      setLoading(false);
    });
  // .finally(() => setLoading(false));
};

const completeRegistration = (
  userData,
  setLoading,
  callbackSuccess,
  setCompleteRegistrationError
) => {
  setLoading(true);

  Auth.completeNewPassword(userData.user, userData.password)
    .then(() => {
      setCompleteRegistrationError(null);
      callbackSuccess();
    })
    .catch((err) => {
      setCompleteRegistrationError(err);
    })
    .finally(() => {
      setLoading(false);
    });
};

const signOut = (setLoading, setUser) => {
  setLoading(true);
  Auth.signOut()
    .then(() => {
      setUser(null);
    })
    .catch((err) => {})
    .finally(() => {
      setLoading(false);
    });
};

const forgotPassword = (
  userData,
  setLoading,
  setForgotPasswordError,
  callback
) => {
  setLoading(true);
  Auth.forgotPassword(userData.username)
    .then(() => {
      setForgotPasswordError(null);
      callback();
    })
    .catch((err) => {
      setForgotPasswordError(err.code);
    })
    .finally(() => {
      setLoading(false);
    });
};

const confirmForgotPassword = (
  userData,
  setLoading,
  setConfirmForgotPasswordError,
  handleSuccess
) => {
  setLoading(true);
  Auth.forgotPasswordSubmit(
    userData.username,
    userData.confirmationCode,
    userData.password
  )
    .then((res) => {
      setConfirmForgotPasswordError(null);
      handleSuccess();
    })
    .catch((err) => {
      setConfirmForgotPasswordError(err.code);
    })
    .finally(() => {
      setLoading(false);
    });
};

export {
  signIn,
  completeRegistration,
  signOut,
  forgotPassword,
  confirmForgotPassword,
};
