/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import Select from "react-select";

//Icons
import DoubleArrow from "assets/double-arrow.svg";

import Button from "components/Button";
import TextArea from "components/TextArea";

import {
  Container,
  SectionTitle,
  InputLabelContainer,
  LabelText,
  Header,
  ArrowContainer,
  ActionIcon
} from "./styles";

const customStyles = {
  multiValueLabel: (provided) => ({
    ...provided,
    overflow: "none",
    textOverflow: "none",
    whiteSpace: "wrap",
  }),
};

function DiagnosisSection({ diagnosisData, updateListGroupDiagnostics, toggleRight, isAdmin }) {
  const [impression, setImpression] = useState("");
  const [pathologyGroups, setPathologyGroups] = useState([]);
  const [isDisabled, setIsDisabled] = useState(true);

  useEffect(() => {
    if (diagnosisData?.pathologyGroups.length) {
      setPathologyGroups(diagnosisData?.pathologyGroups);
    }

    if (diagnosisData?.diagnosticImpression) {
      setImpression(diagnosisData?.diagnosticImpression);
    }
  }, [diagnosisData]);

  useEffect(() => {
    handleButtonEnabled();
  }, [impression, pathologyGroups]);

  const handleButtonEnabled = () => {
    let buttonIsDisabled = true;

    if (impression) {
      buttonIsDisabled = false;
    }

    pathologyGroups.forEach((group) => {
      group.pathologies.forEach((pathologie) => {
        if (pathologie.selected === true) {
          buttonIsDisabled = false;
        }
      });
    });

    setIsDisabled(buttonIsDisabled);
  };

  return (
    <Container>
      <Header>
        <div onClick={() => { 
          toggleRight(false);
          }}>
          <ArrowContainer onClick={() => { 
            toggleRight(true);
            }}>
            <ActionIcon src={DoubleArrow} />
          </ArrowContainer>
        </div>
        <SectionTitle>Diagnóstico</SectionTitle>
      </Header>
      {isAdmin && pathologyGroups.map((pathologyGroup) => (
        <InputLabelContainer key={pathologyGroup.pathologyGroupId}>
          <LabelText>{pathologyGroup.pathologyGroupDescription}</LabelText>

          <Select
            isMulti
            getOptionLabel={(option) => option.pathologyDescription}
            getOptionValue={(option) => option.pathologyId}
            options={pathologyGroup.pathologies}
            defaultValue={pathologyGroup.pathologies.filter(
              (pathologie) => pathologie.selected
            )}
            onChange={(e) => {
              pathologyGroup.pathologies.map(
                (pathologie) => (pathologie.selected = false)
              );

              e.forEach((selectedOption) => {
                const pathologyIndex = pathologyGroup.pathologies.findIndex(
                  (pathology) =>
                    pathology.pathologyId === selectedOption.pathologyId
                );

                if (pathologyIndex > -1) {
                  pathologyGroup.pathologies[pathologyIndex].selected = true;
                }
              });

              setPathologyGroups([...pathologyGroups]);
            }}
            noOptionsMessage={() => "Sem opções."}
            placeholder=""
            styles={customStyles}
          />
        </InputLabelContainer>
      ))}

      <InputLabelContainer>
        <LabelText>Impressão diagnóstica</LabelText>

        <TextArea
          value={impression}
          onChange={(e) => setImpression(e.target.value)}
        />
      </InputLabelContainer>

      <Button
        label={"Salvar Alterações"}
        disabled={isDisabled}
        onClick={() => {
          updateListGroupDiagnostics({
            pathologyGroups: pathologyGroups,
            diagnosticImpression: impression,
          });
        }}
      />
    </Container>
  );
}

export default DiagnosisSection;
