import React, { useState } from "react";

// Libs
import moment from "moment";

// Imgs
import ActionIconSvg from "assets/action-icon.svg";
import ActionIconReportSvg from "assets/action-icon-report.svg";
import NotFound from "assets/not-found.svg";

// Constants
import { reportStatusENUM } from "../../../../constants";

// Components
import ResultText from "./components/ResultText";
import ProgressText from "./components/ProgressText";
import ReasonIcon from "./components/ReasonIcon";
import HeaderSort from "./components/HeaderSort";
import CustomTooltip from "components/CustomTooltip";

import {
  TableContainer,
  THead,
  TBody,
  TR,
  TH,
  TD,
  IconWrapper,
  ActionIcon,
  EmptyListContainer,
  NotFoundImg,
  EmptyListText,
  TDButtonsContainer,
} from "./styles";

function ComplexTableScientific({
  data,
  setData,
  setSelectedItem,
  setShowModal,
}) {
  const [isSorted, setIsSorted] = useState(false);

  const handleSortContent = (objKey) => {
    const sortedData = isSorted
      ? data.sort((a, b) => {
          if (a[objKey] < b[objKey]) return -1;
          if (a[objKey] > b[objKey]) return 1;
          return 0;
        })
      : data.sort((a, b) => {
          if (a[objKey] > b[objKey]) return -1;
          if (a[objKey] < b[objKey]) return 1;
          return 0;
        });

    setIsSorted(!isSorted);
    setData([...sortedData]);
  };

  const handleRowColor = (status, link) => {
    if (status === 1 && link === null) {
      status = 3;
    }
    return (
      reportStatusENUM.find((reportStatus) => reportStatus.id === status)
        ?.color || "#FFFFFF"
    );
  };

  const handleViewReport = (link) => {
    window.open(link, "_blank");
  };

  return (
    <TableContainer>
      {/* Header */}
      <THead>
        <TR>
          <HeaderSort
            label="Dispositivo"
            sortContent={() => handleSortContent("device")}
          />
          <HeaderSort
            label="ID Análise"
            sortContent={() => handleSortContent("screeningId")}
          />
          <HeaderSort
            label="Data e Hora"
            sortContent={() => handleSortContent("period")}
          />
          <HeaderSort
            label="Identificador"
            sortContent={() => handleSortContent("identifier")}
          />
          <HeaderSort
            label="Nome"
            sortContent={() => handleSortContent("patientName")}
          />
          <HeaderSort
            label="Progresso de Triagem"
            sortContent={() => handleSortContent("progress")}
          />
          <HeaderSort
            label="Resultado"
            sortContent={() => handleSortContent("result")}
          />
          <HeaderSort
            label="Motivo"
            sortContent={() => handleSortContent("reasons")}
          />

          <TH>Detalhes</TH>
        </TR>
      </THead>

      {/* Body */}
      <TBody>
        {data && data.length !== 0 ? (
          data.map((item, index) => (
            <React.Fragment key={`item-${index}`}>
              <TR color={handleRowColor(item.diagnosticReport?.reportStatus, item.diagnosticReport?.reportLink)}>
                <TD>{item.device}</TD>
                <TD>{item.screeningId}</TD>
                <TD>{moment.parseZone(item.period).format("DD/MM/YYYY - HH:mm")}</TD>
                <TD>{item.identifier ? item.identifier : "Sem ID"}</TD>
                <TD>{item.patientName ? item.patientName : ""}</TD>
                <TD>
                  <ProgressText progress={item.progress} />
                </TD>
                <TD>
                  <ResultText result={item.result} />
                </TD>
                <TD>
                  <ReasonIcon reasons={item.reasons} />
                </TD>
                <TD>
                  <TDButtonsContainer>
                    <CustomTooltip title="Visualizar detalhes">
                      <IconWrapper
                        onClick={() => {
                          setSelectedItem(item);
                          setShowModal(true);
                        }}
                      >
                        <ActionIcon src={ActionIconSvg} />
                      </IconWrapper>
                    </CustomTooltip>
                    {item.diagnosticReport?.reportStatus === 1 && item.diagnosticReport?.reportLink ? (
                      <CustomTooltip title="Visualizar laudo">
                        <IconWrapper
                          onClick={() => {
                            handleViewReport(item.diagnosticReport?.reportLink);
                          }}
                        >
                          <ActionIcon src={ActionIconReportSvg} />
                        </IconWrapper>
                      </CustomTooltip>
                    ) : null}
                  </TDButtonsContainer>
                </TD>
              </TR>
            </React.Fragment>
          ))
        ) : (
          <tr>
            <td colSpan={8}>
              <EmptyListContainer>
                <NotFoundImg src={NotFound} />
                <EmptyListText>
                  Não encontramos resultado para sua busca.
                </EmptyListText>
              </EmptyListContainer>
            </td>
          </tr>
        )}
      </TBody>
    </TableContainer>
  );
}

export default ComplexTableScientific;
