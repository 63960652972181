import React, { useState } from "react";

// Libs
import JSZip from 'jszip';
import saveAs from 'save-as';
import { MdFileDownload, MdDownloading } from "react-icons/md";

import { Container } from "./styles";

// Services
import DashboardService from "services/dashboard";

// Context
import { useGeneralProvider } from "contexts/general";

const validURL = (str) => {
  var pattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
  '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // domain name
  '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
  '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
  '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
  '(\\#[-a-z\\d_]*)?$','i'); // fragment locator
  return !!pattern.test(str);
};

function DownloadImages({
  name,
  uris,
  disabled
}) {
  const [loading, setLoading] = useState(false);
  const { globalUser } = useGeneralProvider();
  const userPassService = new DashboardService(globalUser);

  const sleep = ms => new Promise(r => setTimeout(r, ms));

  const downloadURI = (uris, name) => {
    if (!loading) {
      const zip = new JSZip();
      let count = 0;

      uris.forEach(async (uri, index) => {
        try {
          if (validURL(uri)) {
            setLoading(true);
            const base64Image = await userPassService.getImageBase64ByUrl(uri);
            zip.file(`${name} - ${index}.png`, base64Image.data.replace('data:', '').replace(/^.+,/, ''), { base64: true });
            count++;
            sleep(50);
            
            if(count === uris.length) {
              zip.generateAsync({type:'blob'}).then(content => {
                saveAs(content, `${name}.zip`);
              });
              setLoading(false);
            }
          }
        } catch (err) {
          console.log(err);
        }
      });
    }
  };

  return (
    <Container
      disabled={disabled || loading}
      onClick={() => downloadURI(uris, name)}
    >
      {loading ? <MdDownloading /> : <MdFileDownload /> } 
    </Container>
  );
}

export default DownloadImages;
