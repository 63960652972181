import React, { useState } from "react";

// Imgs
import TrashIconSvg from "assets/trash-icon.svg";
import EditIconSvg from "assets/edit-icon.svg";
import EmailsIconSvg from "assets/emails-icon.svg";
import NotFound from "assets/not-found.svg";

// Components
import CustomTooltip from "components/CustomTooltip";
import HeaderSort from "./components/HeaderSort";
import StatusText from "./components/StatusText";
import ModalConfirmUpdate from "./components/ModalConfirmUpdate";
import ModalConfirmResendFirstAccessEmail from "./components/ModalConfirmResendFirstAccessEmail";

import {
  TableContainer,
  THead,
  TBody,
  TR,
  TH,
  TD,
  IconWrapper,
  ActionIcon,
  EmptyListContainer,
  NotFoundImg,
  EmptyListText,
  ActionsContainer,
} from "./styles";

function ComplexTable({
  data,
  setData,
  companyId,
  selectedItem,
  setSelectedItem,
  setShowEditCustomerUser,
  handleCleanFilters,
}) {
  const [showModalUserStatusConfirm, setshowModalUserStatusConfirm] = useState(false);
  const [showModalResendFirstAccessEmailConfirm, setshowModalResendFirstAccessEmailConfirm] = useState(false);
  const [isSorted, setIsSorted] = useState(false);

  const handleSortContent = (objKey) => {
    const sortedData = isSorted
      ? data.sort((a, b) => {
          if (a[objKey] < b[objKey]) return -1;
          if (a[objKey] > b[objKey]) return 1;
          return 0;
        })
      : data.sort((a, b) => {
          if (a[objKey] > b[objKey]) return -1;
          if (a[objKey] < b[objKey]) return 1;
          return 0;
        });

    setIsSorted(!isSorted);
    setData([...sortedData]);
  };

  const handleInactivate = (item) => {
    setSelectedItem(item);
    setshowModalUserStatusConfirm(true);
  };

  const handleEdit = (item) => {
    setSelectedItem(item);
    setShowEditCustomerUser(true);
  };

  const handleResendFirstAccessEmail = (item) => {
    setSelectedItem(item);
    setshowModalResendFirstAccessEmailConfirm(true);
  };

  return (
    <>
      {showModalUserStatusConfirm ? (
        <ModalConfirmUpdate
          setShowModal={setshowModalUserStatusConfirm}
          companyId={companyId}
          userId={selectedItem.userId}
          handleCleanFilters={handleCleanFilters}
        />
      ) : null}

      {showModalResendFirstAccessEmailConfirm ? (
        <ModalConfirmResendFirstAccessEmail
          setShowModal={setshowModalResendFirstAccessEmailConfirm}
          companyId={companyId}
          userId={selectedItem.userId}
          userEmail={selectedItem.userEmail}
          handleCleanFilters={handleCleanFilters}
        />
      ) : null}

      <TableContainer>
        {/* Header */}
        <THead>
          <TR>
            <HeaderSort
              label="Nome"
              sortContent={() => handleSortContent("userName")}
            />
            <HeaderSort
              label="E-mail"
              sortContent={() => handleSortContent("userEmail")}
            />
            <HeaderSort
              label="Situação"
              sortContent={() => handleSortContent("userStatus")}
            />
            <TH>Ação</TH>
          </TR>
        </THead>

        {/* Body */}
        <TBody>
          {data && data.length !== 0 ? (
            data.map((item, index) => (
              <React.Fragment key={`item-${index}`}>
                <TR>
                  <TD>{item.userName || "-"}</TD>
                  <TD>{item.userEmail || "-"}</TD>
                  <TD>
                    <StatusText status={item.userStatus} />
                  </TD>
                  <TD>
                    <ActionsContainer>
                      <CustomTooltip title="Inativar">
                        <IconWrapper
                          disabled={item.userStatus === 1}
                          onClick={() => {
                            if (item.userStatus !== 1) {
                              handleInactivate(item);
                            }
                          }}
                        >
                          <ActionIcon src={TrashIconSvg} />
                        </IconWrapper>
                      </CustomTooltip>

                      <CustomTooltip title="Editar">
                        <IconWrapper
                          onClick={() => {
                            handleEdit(item);
                          }}
                        >
                          <ActionIcon src={EditIconSvg} />
                        </IconWrapper>
                      </CustomTooltip>

                      <CustomTooltip title="Reenviar e-mail primeiro acesso">
                        <IconWrapper
                          disabled={!item.firstAccess || item.userStatus === 1}
                          onClick={() => {
                            if (item.firstAccess && item.userStatus !== 1) {
                              handleResendFirstAccessEmail(item);
                            }
                          }}
                        >
                          <ActionIcon src={EmailsIconSvg} />
                        </IconWrapper>
                      </CustomTooltip>
                    </ActionsContainer>
                  </TD>
                </TR>
              </React.Fragment>
            ))
          ) : (
            <tr>
              <td colSpan={8}>
                <EmptyListContainer>
                  <NotFoundImg src={NotFound} />
                  <EmptyListText>
                    Não encontramos resultado para sua busca.
                  </EmptyListText>
                </EmptyListContainer>
              </td>
            </tr>
          )}
        </TBody>
      </TableContainer>
    </>
  );
}

export default ComplexTable;
