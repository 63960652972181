import React, { useState } from "react";

// Libs
import { toast } from "react-toastify";
import moment from "moment";

// Context
import { useGeneralProvider } from "contexts/general";

// Services
import DashboardService from "services/dashboard";

// Imgs
// import UnChecked from "assets/unchecked.svg";
// import Checked from "assets/checked.svg";
import ActionIconSvg from "assets/action-icon.svg";
import NotFound from "assets/not-found.svg";
import DefaultUser from "assets/default-user.png";

// Components
import ResultText from "./components/ResultText";
import ProgressText from "./components/ProgressText";
import ReasonIcon from "./components/ReasonIcon";
import HeaderSort from "./components/HeaderSort";
import CustomTooltip from "components/CustomTooltip";

// Utils
import { getDefaultPalleteRgb, applyGaussianBlur } from "utils";

// Constants
import {
  minTemperatureCelsius,
  maxTemperatureCapCelsius
} from "../../../../components/ThermalManipulation/constants";

import {
  TableContainer,
  THead,
  TBody,
  TR,
  TH,
  TD,
  IconWrapper,
  // CheckboxIcon,
  ActionIcon,
  ExpandContainer,
  InternalImage,
  ExpandBody,
  InternalText,
  TagContainer,
  InfoContainer,
  InfoRow,
  ImagesContainer,
  EmptyListContainer,
  NotFoundImg,
  EmptyListText,
} from "./styles";

const PALETTE_APPLIED_TITLE = 'Pallete applied';

function ComplexTable({ data, setData, setSelectedId }) {
  const palleteRgb = getDefaultPalleteRgb();
  const { loading, setLoading, globalUser } = useGeneralProvider();
  const userPassService = new DashboardService(globalUser);  

  const [isSorted, setIsSorted] = useState(false);
  const [image, setImage] = useState(null);
  const [heatImage, setHeatImage] = useState(null);
  const [temperature, setTemperature] = useState(null);
  const [symptoms, setSymptoms] = useState([]);

  const getGridItemDetail = (id) => {
    let returnExpand = false;
    setLoading(true);
    return userPassService
      .getGridItemDetail(id)
      .then((o) => {
        if (o.data) {
          const response = o.data;

          if (response.visibleImage) {
            setImage(response.visibleImage);
          } else {
            setImage(null);
          }

          if (response.thermalImage) {
            setHeatImage(response.thermalImage);
          } else {
            setHeatImage(null);
          }

          if (response.temperature) {
            setTemperature(response.temperature);
          } else {
            setTemperature(null);
          }

          if (response.symptoms) {
            setSymptoms(response.symptoms);
          } else {
            setSymptoms(null);
          }
        }

        returnExpand = true;
      })
      .catch(() => {
        toast.error("Falha ao carregar detalhes do registro");
        returnExpand = false;
      })
      .finally(() => {
        setLoading(false);
        return returnExpand;
      });
  };

  const handleItemExpand = (clickedScreeningId) => {
    let auxData = [...data];

    auxData.forEach((item) => {
      if (item.screeningId === clickedScreeningId) {
        if (item.expanded) {
          setSelectedId("");
          item.expanded = false;
        } else {
          setSelectedId(clickedScreeningId);
          getGridItemDetail(clickedScreeningId);
          item.expanded = true;
        }
      } else {
        item.expanded = false;
      }
    });

    setData(auxData);
  };

  const handleSortContent = (objKey) => {
    const sortedData = isSorted
      ? data.sort((a, b) => {
          if (a[objKey] < b[objKey]) return -1;
          if (a[objKey] > b[objKey]) return 1;
          return 0;
        })
      : data.sort((a, b) => {
          if (a[objKey] > b[objKey]) return -1;
          if (a[objKey] < b[objKey]) return 1;
          return 0;
        });

    setIsSorted(!isSorted);
    setData([...sortedData]);
  };

  // Extrai o "raw" da imagem com base no RGB de cada pixel e calcula a temperatura
  const handleGetTemperature = (green, blue) => {
      const raw = green * 256 + blue;

      const M = 0.02569698246480104;
      const B = 64.74694198003411;

      const temp = M * raw - B;

      return temp;
  };

  const handleInitialMapping = (imageData) => {
      const dataLength = imageData.data.length;
      const pixelLength = 4;

      const temperaturePixelArray = [];

      for (let i = 0; i <= dataLength - pixelLength; i += pixelLength) {
        const green = imageData.data[i + 1];
        const blue = imageData.data[i + 2];

        const temp = handleGetTemperature(green, blue);

        if (temp >= minTemperatureCelsius && temp <= maxTemperatureCapCelsius) {
          temperaturePixelArray.push({ t: temp, p: i });
        }
      }

      const currentImageSortedData = temperaturePixelArray.sort((a, b) => {
        if (a.t < b.t) return -1;
        if (a.t > b.t) return 1;
        return 0;
      });

      return currentImageSortedData;
  };

  const handleTemperaturePerPixel = (imageData, currentImageValues) => {
      const temperaturePixelArray = [];

      for (let i = 0; i < currentImageValues.length; i++) {
        temperaturePixelArray.push(currentImageValues[i]);
      }
      return temperaturePixelArray;
  };

  const handlePixelTransform = (imageData, currentImageValues) => {
      const temperaturePixelArray = handleTemperaturePerPixel(imageData, currentImageValues);
      const expandedPalette = [];

      const pixelsPerColor = Math.ceil(
        temperaturePixelArray.length / palleteRgb.length
      );

      const reversedPalette = [...palleteRgb].reverse();

      for (let i = 0; i < reversedPalette.length; i++) {
        for (let j = 0; j < pixelsPerColor; j++) {
            expandedPalette.push(reversedPalette[i]);
        }
      }

      if (expandedPalette.length !== 0) {
        for (let i = 0; i < temperaturePixelArray.length; i++) {
            const currentColor = expandedPalette[i] || [0, 0, 0];

            // red
            imageData.data[temperaturePixelArray[i].p] = currentColor[0];
            // green
            imageData.data[temperaturePixelArray[i].p + 1] = currentColor[1];
            // blue
            imageData.data[temperaturePixelArray[i].p + 2] = currentColor[2];
        }
      }
      return imageData;
  };

  const validURL = (str) => {
      var pattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // domain name
      '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
      '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
      '(\\#[-a-z\\d_]*)?$','i'); // fragment locator
      return !!pattern.test(str);
  }

  const getTransformedImage = async (imageUrl) => {    
      let reload = false;
      if (validURL(imageUrl.target.currentSrc)) {
        const image = await userPassService.getImageBase64ByUrl(imageUrl.target.currentSrc);
        imageUrl.target.src = image.data;
        imageUrl.target.title = null;
        reload = true;
      }

      if (!reload && imageUrl.target.title !== PALETTE_APPLIED_TITLE) {
        // Create an empty canvas element
        const canvas = document.createElement("canvas");
        canvas.width = imageUrl.target.naturalWidth;
        canvas.height = imageUrl.target.naturalHeight;

        // Copy the image contents to the canvas
        const ctx = canvas.getContext("2d");
        ctx.drawImage(imageUrl.target, 0, 0);
        const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
        const currentImageValues = handleInitialMapping(imageData);
        const newImageData = handlePixelTransform(imageData, currentImageValues);
        applyGaussianBlur(imageData);
        ctx.putImageData(newImageData, 0, 0);
        imageUrl.target.title = PALETTE_APPLIED_TITLE;
        imageUrl.target.src = canvas.toDataURL();
      }  
  };

  return (
    <TableContainer>
      {/* Header */}
      <THead>
        <TR>
          {/* <TH>
            <IconWrapper onClick={handleSelectAll}>
              <CheckboxIcon src={verifyAllSelection() ? Checked : UnChecked} />
            </IconWrapper>
          </TH> */}
          <HeaderSort
            label="Dispositivo"
            sortContent={() => handleSortContent("device")}
          />
          <HeaderSort
            label="ID Análise"
            sortContent={() => handleSortContent("screeningId")}
          />
          <HeaderSort
            label="Data e Hora"
            sortContent={() => handleSortContent("period")}
          />
          <HeaderSort
            label="Identificador"
            sortContent={() => handleSortContent("identifier")}
          />
          {/* <HeaderSort
            label="Identificação"
            sortContent={() => handleSortContent("identification")}
          /> */}
          <HeaderSort
            label="Progresso de Triagem"
            sortContent={() => handleSortContent("progress")}
          />
          <HeaderSort
            label="Resultado"
            sortContent={() => handleSortContent("result")}
          />
          <HeaderSort
            label="Motivo"
            sortContent={() => handleSortContent("reasons")}
          />
          <TH>Detalhes</TH>
        </TR>
      </THead>

      {/* Body */}
      <TBody>
        {data && data.length !== 0 ? (
          data.map((item, index) => (
            <React.Fragment key={`item-${index}`}>
              <TR>
                {/* <TD>
                  <IconWrapper
                    onClick={() => {
                      handleOptionSelection(index);
                    }}
                  >
                    <CheckboxIcon src={item.selected ? Checked : UnChecked} />
                  </IconWrapper>
                </TD> */}
                <TD>{item.device}</TD>
                <TD>{item.screeningId}</TD>
                <TD>{moment.parseZone(item.period).format("DD/MM/YYYY - HH:mm")}</TD>
                <TD>{item.identifier ? item.identifier : "Sem ID"}</TD>
                {/* <TD>
                  {item.identification
                    ? item.identification
                    : "Sem identificação"}
                </TD> */}
                <TD>
                  <ProgressText progress={item.progress} />
                </TD>
                <TD>
                  <ResultText result={item.result} />
                </TD>
                <TD>
                  <ReasonIcon reasons={item.reasons} />
                </TD>
                <TD>
                  <CustomTooltip title="Visualizar imagem">
                    <IconWrapper
                      onClick={() => handleItemExpand(item.screeningId)}
                    >
                      <ActionIcon src={ActionIconSvg} />
                    </IconWrapper>
                  </CustomTooltip>
                </TD>
              </TR>
              <tr>
                <td colSpan={9}>
                  <ExpandContainer expanded={item.expanded}>
                    {loading ? null : (
                      <>
                        <InternalText>Captura da imagem</InternalText>
                        <ExpandBody>
                          <ImagesContainer>
                            <InternalImage src={image ? image : DefaultUser} />
                            <InternalImage
                              src={heatImage ? heatImage : DefaultUser}
                              onLoad={heatImage ? (event) => getTransformedImage(event) : null}
                            />
                          </ImagesContainer>

                          <InfoContainer>
                            <InternalText>Temperatura</InternalText>
                            <InfoRow>
                              <TagContainer>{`${
                                temperature ? temperature : 0
                              }º`}</TagContainer>
                            </InfoRow>

                            <InternalText>Sintomas</InternalText>
                            <InfoRow>
                              {symptoms && symptoms.length !== 0
                                ? symptoms.map((symptom, index) => (
                                    <TagContainer key={`symptom-${index}`}>
                                      {symptom}
                                    </TagContainer>
                                  ))
                                : null}
                            </InfoRow>
                          </InfoContainer>
                        </ExpandBody>
                      </>
                    )}
                  </ExpandContainer>
                </td>
              </tr>
            </React.Fragment>
          ))
        ) : (
          <tr>
            <td colSpan={8}>
              <EmptyListContainer>
                <NotFoundImg src={NotFound} />
                <EmptyListText>
                  Não encontramos resultado para sua busca.
                </EmptyListText>
              </EmptyListContainer>
            </td>
          </tr>
        )}
      </TBody>
    </TableContainer>
  );
}

export default ComplexTable;
