import styled from "styled-components";

export const LeftContainer = styled.div`
  display: flex;
  justify-content: center;
  border-right: solid 1px #e1e1e1;
  width: ${(props) => (props.expandLeft && props.isDiagnosisRight ?
        '300px' : props.expandLeft ?
            '220px' : '60px')};
  overflow-x: hidden;
  transition: all 1s;
`;

export const MiddleContainer = styled.div`
  padding: 0 5px;
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow: auto;
`;

export const RightContainer = styled.div`
  width: ${(props) => props.expandRight ? "320px" : '60px'};
  min-width: ${(props) => props.expandRight ? "320px" : '60px'};
  display: flex;
  flex-direction: column;
  border-left: solid 1px #e1e1e1;
  overflow-x: hidden;
  transition: all 1s;
`;

export const ArrowContainer = styled.div`
  display: flex;
`;

export const ActionIconLeft = styled.img`
  width: 20px;
  height: 20px;
  user-select: none;
  pointer-events: none;
  overflow-x: hidden;
  transform: rotate(180deg);
  transition: all 1s;
`;

