import React from "react";

import { Container, CustomButton, ImgIcon } from "./styles";

function ButtonInverted({ label, onClick, disabled, icon }) {
  return (
    <Container>
      <CustomButton onClick={onClick} disabled={disabled}>
        {label}
        {icon ? <ImgIcon src={icon} /> : null}
      </CustomButton>
    </Container>
  );
}

export default ButtonInverted;
