import styled from "styled-components";

export const TableContainer = styled.table`
  width: 100%;
  font-size: 12px;
`;

export const THead = styled.thead``;

export const TBody = styled.tbody``;

export const TR = styled.tr`
  height: 44px;
  width: 100%;
`;

export const TH = styled.th`
  background-color: #f9f9f9;
  padding-left: 8px;
  color: #000000;
  font-weight: bold;
`;

export const ExpandContainer = styled.div`
  width: 200px;
  transition: all 0.3s;
  height: ${(props) => (props.expanded ? "186px" : "1px")};
  overflow: hidden;
  width: 100%;
  display: flex;
  flex-direction: column;
  border-bottom: solid 1px #ebebeb;
  padding-left: 12px;
`;

export const ExpandBody = styled.div`
  display: flex;
  flex-direction: row;
`;

export const InternalText = styled.p`
  margin: 0 0 8px 0;
  padding: 0;
  color: #000000;
  font-size: 12px;
`;

export const TagContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f1f1f1;
  height: 24px;
  padding: 0 8px 0 8px;
  margin-right: 8px;
  border-radius: 4px;
  margin-bottom: 4px;
`;

export const ImagesContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

export const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const InfoRow = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 16px;
  flex-wrap: wrap;
`;

export const InternalImage = styled.img`
  height: 126px;
  width: 104px;
  margin-right: 24px;
  object-fit: cover;
`;

export const TD = styled.td`
  background-color: #ffffff;
  padding-left: 8px;
  color: #38393d;
`;

export const IconWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
  cursor: pointer;

  :hover {
    opacity: 0.7;
  }
`;

export const CheckboxIcon = styled.img`
  width: 16px;
  height: 16px;
  user-select: none;
  pointer-events: none;
`;

export const ActionIcon = styled.img`
  width: 24px;
  height: 24px;
  user-select: none;
  pointer-events: none;
`;

export const EmptyListContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding-top: 50px;
`;

export const NotFoundImg = styled.img`
  width: 65px;
  height: 65px;
  margin-bottom: 18px;
`;

export const EmptyListText = styled.p`
  margin: 0;
  padding: 0;
  color: #000000;
  font-size: 16px;
`;
