import styled from "styled-components";

export const Container = styled.div``;

export const CustomButton = styled.button`
  cursor: ${(props) => (props.disabled ? "default" : "pointer")};
  padding: 0 24px 0 24px;
  height: 40px;
  color: ${(props) => (props.disabled ? "#a2a2a2" : "#0073AB")};
  font-size: 16px;
  font-weight: bold;
  background-color: #fefefe;
  border: 1px solid #0073ab;
  outline: none;
  border-radius: 4px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  user-select: none;
  width: max-content;
  min-width: 120px;

  :hover {
    opacity: 0.7;
  }
`;

export const ImgIcon = styled.img`
  width: 20px;
  height: 20px;
  margin-left: 8px;
`;
