import React from "react";

// Libs
import { Route } from "react-router-dom";

function PublicRoute(props) {
  return <Route {...props}>{props.children}</Route>;
}

export default PublicRoute;
