import React from "react";

import { Container, CustomButton } from "./styles";

function Button({ label, onClick, disabled, icon, menuIsLeft }) {
  return (
    <Container>
      <CustomButton onClick={onClick} disabled={disabled} menuIsLeft={menuIsLeft}>
        {label}
        {icon ? icon : null}
      </CustomButton>
    </Container>
  );
}

export default Button;
