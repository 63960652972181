import React, { useState } from "react";
import { useHistory } from "react-router-dom";

// Imgs
import TrashIconSvg from "assets/trash-icon.svg";
import EditIconSvg from "assets/edit-icon.svg";
import ExternalIconSvg from "assets/external-icon.svg";
import UserIconSvg from "assets/user-icon.svg";
import AnydeskIconSvg from "assets/anydesk-icon.svg";
import NotFound from "assets/not-found.svg";

// Constants
import { collectTypesENUM } from "../../../../constants";

// Components
import CustomTooltip from "components/CustomTooltip";
import HeaderSort from "./components/HeaderSort";
import StatusText from "./components/StatusText";
import ModalConfirmUpdate from "./components/ModalConfirmUpdate";

import {
  TableContainer,
  THead,
  TBody,
  TR,
  TH,
  TD,
  IconWrapper,
  ActionIcon,
  EmptyListContainer,
  NotFoundImg,
  EmptyListText,
  ActionsContainer,
} from "./styles";

function ComplexTable({
  data,
  setData,
  setSelectedItem,
  setIsEditingCustomer,
  setShowEditCustomer,
  handleCleanFilters,
}) {
  const history = useHistory();
  const [showModalConfirm, setShowModalConfirm] = useState(false);
  const [selectedData, setSelectedData] = useState(null);
  const [isSorted, setIsSorted] = useState(false);

  const handleSortContent = (objKey) => {
    const sortedData = isSorted
      ? data.sort((a, b) => {
          if (a[objKey] < b[objKey]) return -1;
          if (a[objKey] > b[objKey]) return 1;
          return 0;
        })
      : data.sort((a, b) => {
          if (a[objKey] > b[objKey]) return -1;
          if (a[objKey] < b[objKey]) return 1;
          return 0;
        });

    setIsSorted(!isSorted);
    setData([...sortedData]);
  };

  const handleInactivate = (item) => {
    setSelectedData(item);
    setShowModalConfirm(true);
  };

  const handleEdit = (item) => {
    setSelectedItem(item);
    setIsEditingCustomer(true);
    setShowEditCustomer(true);
  };

  const handleSeeDetails = (item) => {
    setSelectedItem(item);
    setIsEditingCustomer(false);
    setShowEditCustomer(true);
  };

  const handleSeeUsers = (item) => {
    history.push({
      pathname: "/usuarios-cliente",
      state: {
        companyId: item.companyId,
        companyName: item.companyName,
      },
    });
  };

  const handleSeeAnydesks = (item) => {
    history.push({
      pathname: "/anydesks-cliente",
      state: {
        companyId: item.companyId,
        companyName: item.companyName,
      },
    });
  };

  return (
    <>
      {showModalConfirm ? (
        <ModalConfirmUpdate
          setShowModal={setShowModalConfirm}
          companyId={selectedData.companyId}
          handleCleanFilters={handleCleanFilters}
        />
      ) : null}

      <TableContainer>
        {/* Header */}
        <THead>
          <TR>
            <HeaderSort
              label="Nome da Empresa"
              sortContent={() => handleSortContent("companyName")}
            />
            <HeaderSort
              label="Tag da Empresa"
              sortContent={() => handleSortContent("companyTag")}
            />
            <HeaderSort
              label="Nome da Pessoa Contato"
              sortContent={() => handleSortContent("contactName")}
            />
            <HeaderSort
              label="Tipo"
              sortContent={() => handleSortContent("companyTypeId")}
            />
            <HeaderSort
              label="Situação"
              sortContent={() => handleSortContent("companyStatus")}
            />

            <TH>Ação</TH>
          </TR>
        </THead>

        {/* Body */}
        <TBody>
          {data && data.length !== 0 ? (
            data.map((item, index) => (
              <React.Fragment key={`item-${index}`}>
                <TR>
                  <TD>{item.companyName || "-"}</TD>
                  <TD>{item.companyTag || "-"}</TD>
                  <TD>{item.contactName || "-"}</TD>
                  <TD>
                    {collectTypesENUM.find(
                      (type) => type.id === item.companyTypeId
                    ).name || "-"}
                  </TD>
                  <TD>
                    <StatusText status={item.companyStatus} />
                  </TD>
                  <TD>
                    <ActionsContainer>
                      <CustomTooltip title="Inativar">
                        <IconWrapper
                          disabled={item.companyStatus === 1}
                          onClick={() => {
                            if (item.companyStatus !== 1) {
                              handleInactivate(item);
                            }
                          }}
                        >
                          <ActionIcon src={TrashIconSvg} />
                        </IconWrapper>
                      </CustomTooltip>

                      <CustomTooltip title="Editar">
                        <IconWrapper
                          onClick={() => {
                            handleEdit(item);
                          }}
                        >
                          <ActionIcon src={EditIconSvg} />
                        </IconWrapper>
                      </CustomTooltip>

                      <CustomTooltip title="Visualizar">
                        <IconWrapper
                          onClick={() => {
                            handleSeeDetails(item);
                          }}
                        >
                          <ActionIcon src={ExternalIconSvg} />
                        </IconWrapper>
                      </CustomTooltip>

                      <CustomTooltip title="Administrar Usuários">
                        <IconWrapper
                          onClick={() => {
                            handleSeeUsers(item);
                          }}
                        >
                          <ActionIcon src={UserIconSvg} />
                        </IconWrapper>
                      </CustomTooltip>

                      <CustomTooltip title="Administrar Anydesks">
                        <IconWrapper
                          onClick={() => {
                            handleSeeAnydesks(item);
                          }}
                        >
                          <ActionIcon src={AnydeskIconSvg} />
                        </IconWrapper>
                      </CustomTooltip>
                    </ActionsContainer>
                  </TD>
                </TR>
              </React.Fragment>
            ))
          ) : (
            <tr>
              <td colSpan={8}>
                <EmptyListContainer>
                  <NotFoundImg src={NotFound} />
                  <EmptyListText>
                    Não encontramos resultado para sua busca.
                  </EmptyListText>
                </EmptyListContainer>
              </td>
            </tr>
          )}
        </TBody>
      </TableContainer>
    </>
  );
}

export default ComplexTable;
