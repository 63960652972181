import React from "react";

// Libs
import ReactLoading from "react-loading";

import { Container } from "./styles";

function CustomLoading() {
  return (
    <Container>
      <ReactLoading type={"bars"} height={50} width={50} />
    </Container>
  );
}

export default CustomLoading;
