import React from "react";

import { Container, Text } from "./styles";

function StatusText({ status }) {
  return (
    <Container
      color={
        status === 0
          ? "#D97E3F"
          : status === 1
          ? "#40A99E"
          : status === 2
          ? "#C73734"
          : "none"
      }
      backColor={
        status === 0
          ? "#FBF2EC"
          : status === 1
          ? "#ECF6F5"
          : status === 2
          ? "#F9EBEB"
          : "none"
      }
    >
      <Text>
        {status === 0
          ? "Em processamento"
          : status === 1
          ? "Concluído"
          : status === 2
          ? "Erro"
          : ""}
      </Text>
    </Container>
  );
}

export default StatusText;
