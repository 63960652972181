/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";

// Libs
import moment from "moment";
import { toast } from "react-toastify";
import { Box, Grid } from "@mui/material";

// Services
import DashboardService from "services/dashboard";

// Components
import GenericModal from "components/GenericModal";
import Button from "components/Button";
import CustomDatePicker from "components/CustomDatePicker";
import SelectInput from "components/SelectInput";
import Input from "components/Input";

// Context
import { useGeneralProvider } from "contexts/general";

// Constants
import { initialReportValuesStructure } from "../../../../../../constants";

import {
  Container,
  ResumeTitle,
  ResumeSubtitle,
  InputGroup,
  InputLabel,
  TopContainer,
  MiddleContainer,
  BottomContainer,
  FooterContainer,
} from "./styles";

function ModalGenerateReport({
  setShowModal,
  groupsWithDiagnosis = 0,
  screeningId,
  reportFiltersData,
  handleGetModalInfo
}) {
  const { setLoading, globalUser } = useGeneralProvider();
  const userPassService = new DashboardService(globalUser);

  const [isBtnEnabled, setIsBtnEnabled] = useState(false);
  const [reportFilters, setReportFilters] = useState([]);
  const [reportData, setReportData] = useState({
    ...initialReportValuesStructure,
    requester: {
      name: reportFiltersData.reportRequester.name,
      councilTypeId: reportFiltersData.reportRequester.councilTypeId,
      councilNumber: reportFiltersData.reportRequester.councilNumber
    },
    examiner: {
      name: reportFiltersData.reportExaminer.name,
      councilTypeId: reportFiltersData.reportExaminer.councilTypeId,
      councilNumber: reportFiltersData.reportExaminer.councilNumber
    },
    evaluator: {
      name: reportFiltersData.reportEvauator.name,
      councilTypeId: reportFiltersData.reportEvauator.councilTypeId,
      councilNumber: reportFiltersData.reportEvauator.councilNumber
    },
    examDate: new Date(),
  });

  useEffect(() => {
    handleGetInfoReport();
  }, []);

  useEffect(() => {
    handleEnableSaveButton();
  }, [reportData]);

  const handleGetInfoReport = () => {
    setLoading(true);

    Promise.all([
      userPassService
        .getDiagnosticReportFilters()
        .then((o) => {
          if (o.data) {
            setReportFilters(o.data);
          }
        })
        .catch(() => {
          toast.error("Falha ao carregar filtros de seleção");
        }),
    ])
      .catch(() => setLoading(false))
      .finally(() => setLoading(false));
  };

  const handleSaveReport = () => {
    setLoading(true);

    const requestBody = {
      ...reportData,
      examDate: moment.parseZone(reportData.examDate).format("YYYY-MM-DDT00:00:00"),
      screeningId: screeningId,
    };

    userPassService
      .createDiagnosticReport(requestBody)
      .then(() => {
        toast.success("Solicitação de geração do Laudo realizada com sucesso.");
        handleGetModalInfo();
        setShowModal(false);
      })
      .catch((err) => {
        setLoading(false);

        if (err?.response?.data?.message) {
          toast.error(err.response.data.message);
        } else {
          toast.error("Falha ao solicitar geração do Laudo");
        }

        toast.error("Falha ao carregar filtros de seleção");
      });
  };

  const handleEnableSaveButton = () => {
    let auxIsBtnEnabled = true;

    if (
      !moment.utc(reportData.examDate).isValid() ||
      reportData.reportTypeId === "" ||
      screeningId === ""
    ) {
      auxIsBtnEnabled = false;
    }

    if (
      !reportData.requester ||
      reportData.requester.name === "" ||
      reportData.requester.councilTypeId === "" ||
      reportData.requester.councilNumber === ""
    ) {
      auxIsBtnEnabled = false;
    }

    if (
      !reportData.examiner ||
      reportData.examiner.name === "" ||
      reportData.examiner.councilTypeId === "" ||
      reportData.examiner.councilNumber === ""
    ) {
      auxIsBtnEnabled = false;
    }

    if (
      !reportData.evaluator ||
      reportData.evaluator.name === "" ||
      reportData.evaluator.councilTypeId === "" ||
      reportData.evaluator.councilNumber === ""
    ) {
      auxIsBtnEnabled = false;
    }

    setIsBtnEnabled(auxIsBtnEnabled);
  };

  return (
    <GenericModal
      title="Gerar Laudo"
      setShowModal={setShowModal}
      fullScreen={false}
      noBodyPadding
    >
      <Container>
        <TopContainer>
          <ResumeTitle>Resumo</ResumeTitle>
          <ResumeSubtitle>
            {`Esta análise possui ${groupsWithDiagnosis} grupo(s) de imagens com diagnósticos distintos.`}
          </ResumeSubtitle>
        </TopContainer>

        <MiddleContainer>
          <Box sx={{ flexGrow: 1 }}>
            <Grid container spacing={4}>
              <Grid item xs="auto">
                <InputGroup>
                  <InputLabel>Data do exame</InputLabel>
                  <CustomDatePicker
                    selectedDate={reportData.examDate}
                    setSelectedDate={(date) =>
                      setReportData({ ...reportData, examDate: date })
                    }
                  />
                </InputGroup>
              </Grid>
              <Grid item xs={3}>
                <InputGroup>
                  <InputLabel>Formato laudo</InputLabel>
                  <SelectInput
                    smallSize
                    selectedOption={reportFilters?.reportTypes?.find(
                      (item) => item.typeId === reportData.reportTypeId
                    )}
                    optionLabel="typeDescription"
                    optionValue="typeId"
                    options={reportFilters?.reportTypes}
                    setSelectedOption={(e) => {
                      setReportData({ ...reportData, reportTypeId: e.typeId });
                    }}
                  />
                </InputGroup>
              </Grid>
            </Grid>
          </Box>
        </MiddleContainer>

        <BottomContainer>
          <Box sx={{ flexGrow: 1 }}>
            {/* SOLICITANTE */}
            <Grid container marginBottom={2} spacing={4}>
              <Grid item xs={6}>
                <InputGroup>
                  <InputLabel>Solicitante</InputLabel>
                  <Input
                    smallSize
                    value={reportData?.requester?.name}
                    onChange={(e) =>
                      setReportData({
                        ...reportData,
                        requester: {
                          ...reportData.requester,
                          name: e.target.value,
                        },
                      })
                    }
                  />
                </InputGroup>
              </Grid>
              <Grid item xs={3}>
                <InputGroup>
                  <InputLabel>Conselho</InputLabel>
                  <SelectInput
                    smallSize
                    selectedOption={reportFilters?.councilTypes?.find(
                      (item) =>
                        item.typeId === reportData?.requester?.councilTypeId
                    )}
                    optionLabel="typeDescription"
                    optionValue="typeId"
                    options={reportFilters?.councilTypes}
                    setSelectedOption={(e) => {
                      setReportData({
                        ...reportData,
                        requester: {
                          ...reportData.requester,
                          councilTypeId: e.typeId,
                        },
                      });
                    }}
                  />
                </InputGroup>
              </Grid>
              <Grid item xs={3}>
                <InputGroup>
                  <InputLabel>Número</InputLabel>
                  <Input
                    smallSize
                    value={reportData?.requester?.councilNumber}
                    onChange={(e) =>
                      setReportData({
                        ...reportData,
                        requester: {
                          ...reportData.requester,
                          councilNumber: e.target.value,
                        },
                      })
                    }
                  />
                </InputGroup>
              </Grid>
            </Grid>

            {/* EXAMINADOR */}
            <Grid container marginBottom={2} spacing={4}>
              <Grid item xs={6}>
                <InputGroup>
                  <InputLabel>Examinador</InputLabel>
                  <Input
                    smallSize
                    value={reportData?.examiner?.name}
                    onChange={(e) =>
                      setReportData({
                        ...reportData,
                        examiner: {
                          ...reportData.examiner,
                          name: e.target.value,
                        },
                      })
                    }
                  />
                </InputGroup>
              </Grid>
              <Grid item xs={3}>
                <InputGroup>
                  <InputLabel>Conselho</InputLabel>
                  <SelectInput
                    smallSize
                    selectedOption={reportFilters?.councilTypes?.find(
                      (item) =>
                        item.typeId === reportData?.examiner?.councilTypeId
                    )}
                    optionLabel="typeDescription"
                    optionValue="typeId"
                    options={reportFilters?.councilTypes}
                    setSelectedOption={(e) => {
                      setReportData({
                        ...reportData,
                        examiner: {
                          ...reportData.examiner,
                          councilTypeId: e.typeId,
                        },
                      });
                    }}
                  />
                </InputGroup>
              </Grid>
              <Grid item xs={3}>
                <InputGroup>
                  <InputLabel>Número</InputLabel>
                  <Input
                    smallSize
                    value={reportData?.examiner?.councilNumber}
                    onChange={(e) =>
                      setReportData({
                        ...reportData,
                        examiner: {
                          ...reportData.examiner,
                          councilNumber: e.target.value,
                        },
                      })
                    }
                  />
                </InputGroup>
              </Grid>
            </Grid>

            {/* AVALIADOR */}
            <Grid container spacing={4}>
              <Grid item xs={6}>
                <InputGroup>
                  <InputLabel>Avaliador</InputLabel>
                  <Input
                    smallSize
                    value={reportData?.evaluator?.name}
                    onChange={(e) =>
                      setReportData({
                        ...reportData,
                        evaluator: {
                          ...reportData.evaluator,
                          name: e.target.value,
                        },
                      })
                    }
                  />
                </InputGroup>
              </Grid>
              <Grid item xs={3}>
                <InputGroup>
                  <InputLabel>Conselho</InputLabel>
                  <SelectInput
                    smallSize
                    selectedOption={reportFilters?.councilTypes?.find(
                      (item) =>
                        item.typeId === reportData?.evaluator?.councilTypeId
                    )}
                    optionLabel="typeDescription"
                    optionValue="typeId"
                    options={reportFilters?.councilTypes}
                    setSelectedOption={(e) => {
                      setReportData({
                        ...reportData,
                        evaluator: {
                          ...reportData.evaluator,
                          councilTypeId: e.typeId,
                        },
                      });
                    }}
                  />
                </InputGroup>
              </Grid>
              <Grid item xs={3}>
                <InputGroup>
                  <InputLabel>Número</InputLabel>
                  <Input
                    smallSize
                    value={reportData?.evaluator?.councilNumber}
                    onChange={(e) =>
                      setReportData({
                        ...reportData,
                        evaluator: {
                          ...reportData.evaluator,
                          councilNumber: e.target.value,
                        },
                      })
                    }
                  />
                </InputGroup>
              </Grid>
            </Grid>
          </Box>
        </BottomContainer>
        <FooterContainer>
          <Button
            label="Salvar"
            onClick={handleSaveReport}
            disabled={!isBtnEnabled}
          />
        </FooterContainer>
      </Container>
    </GenericModal>
  );
}

export default ModalGenerateReport;
