import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  width: 100%;
  justify-content: end;
  user-select: none;
`;

export const NavWrapper = styled.nav``;

export const PageOrder = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
`;

export const EllipsisText = styled.p`
  margin: 0;
  padding: 0;
  font-size: 18px;
`;

export const NavigationButton = styled.button`
  height: 32px;
  width: 32px;
  background-color: ${(props) => (props.disabled ? "#F1F1F1" : "#fefefe")};
  border: ${(props) =>
    props.disabled
      ? "none"
      : props.selected
      ? "solid 1px #0073AB"
      : "solid 1px #DFE3E8"};
  border-radius: 4px;
  margin: 0px 4px 0px 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${(props) => (props.selected ? "#0073AB" : "#38393d")};
  font-weight: ${(props) => (props.selected ? "bold" : "400")};
  font-size: 14px;
`;

export const ImageArrow = styled.img`
  width: 24px;
  height: 24px;
  opacity: ${(props) => (props.disabled ? "0.4" : "1")};
  transform: ${(props) =>
    props.type === "first" || props.type === "previous"
      ? "rotate(180deg)"
      : "rotate(0deg)"};
  user-select: none;
  pointer-events: none;
`;
