/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";

// Libs
import { toast } from "react-toastify";

// Services
import DashboardService from "services/dashboard";

// Context
import { useGeneralProvider } from "contexts/general";

// Components
import ModalDiagnosis from "./components/ModalDiagnosis";
import ModalGenerateReport from "./components/ModalGenerateReport";
import Gallery from "./components/Gallery";
import BodySection from "./components/BodySection";
import GroupSection from "./components/GroupSection";
import GenericModal from "components/GenericModal";
import PlayerVideo from "../../../../components/PlayerVideo";

// Styles
import { 
  LeftContainer, 
  RightContainer,
  ContainerPlayerVideo, 
  ContainerModal,
 } from "./styles";

function ModalScientificPanel({
  showModal,
  setShowModal,
  selectedItem,
  handleUpdateInfoRequests,
}) {
  const { setLoading, globalUser } = useGeneralProvider();
  const userPassService = new DashboardService(globalUser);

  const [groups, setGroups] = useState([]);
  const [gallery, setGallery] = useState([]);
  const [galleryVideo, setGalleryVideo] = useState([]);
  const [analysedGallery, setAnalysedGallery] = useState([]);
  const [generalData, setGeneralData] = useState(null);
  const [reportData, setReportData] = useState(null);
  const [arrayVideo, setArrayVideo] = useState([]);
  const [showModalDiagnosis, setShowModalDiagnosis] = useState(false);
  const [showModalGenerateReport, setShowModalGenerateReport] = useState(false);

  const [onGrouping, setOnGrouping] = useState(false);
  const [selectedGroup, setSelectedGroup] = useState(null);
  const [groupsWithDiagnosis, setGroupsWithDiagnosis] = useState(0);

  const [showModalPlayerVideo, setShowModalPlayerVideo] = useState({
    show: false,
    value: "",
  })
  const [groupNameVideo, setGroupNameVideo] = useState(null);

  useEffect(() => {
    if (showModal) {
      handleGetModalInfo(selectedItem.screeningId);
    }
  }, [showModal]);

  const handleGetModalInfo = (id) => {
    setLoading(true);
    Promise.all([
      userPassService
        .getScientificImageGallery(id)
        .then((o) => {
          if (o.data) {
            const auxGroups = o.data?.groups || [];
            const auxGallery = o.data?.thermalImageGallery || [];
            const auxGalleryVideo = o.data?.thermalVideoGallery || [];
            const auxAnalysedGallery = o.data?.analysedImageGallery || [];

            setGroups(auxGroups);
            setGallery(auxGallery);
            setGalleryVideo(auxGalleryVideo);
            setAnalysedGallery(auxAnalysedGallery);

            handleUpdateSelectedGroupData({
              groupId: selectedGroup?.groupId,
              newGroups: auxGroups,
            });
          }
        })
        .catch(() => {
          toast.error("Falha ao carregar dados da galeria");
        }),
      userPassService
        .getScientificGridScreeningDetails(id)
        .then((o) => {
          if (o.data) {
            setGeneralData(o.data);
            handleGroupsWithDiagnosis(o.data?.diagnosticGroups);
            setReportData(o.data?.diagnosticReport);
          }
        })
        .catch(() => {
          toast.error("Falha ao carregar dados gerais");
        }),
    ])
      .catch(() => {
        setLoading(false);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleUpdateSelectedGroupData = ({ newGroups, groupId }) => {
    setTimeout(() => {
      const auxSelectedGroup =
        newGroups.find((group) => group.groupId === groupId) || null;

      setSelectedGroup({ ...auxSelectedGroup });
    }, 0);
  };

  const handleGetImageByID = (imageId, isAnalysed) => {

    const auxGallery = [...gallery];

    galleryVideo.forEach((item, index) =>  {
      item.thermalImages.forEach((item, index) => {
        auxGallery.push(item);
      })
    });
   
    if (isAnalysed && analysedGallery?.length) {
      return (
        analysedGallery.find((image) => image.imageId === imageId)
          ?.thermalImageLink || ""
      );
    }

    if (auxGallery?.length) {
      return (
        auxGallery.find((image) => image.imageId === imageId)?.thermalImageLink ||
        ""
      );
    }

  };

  const handleGetAnalysedImageInfo = (imageId) => {
    if (analysedGallery?.length) {
      return analysedGallery.find((image) => image.imageId === imageId) || "";
    }

    return "";
  };

  const handleSeeGroupByID = (groupId) => {
    const auxSelectedGroup = groups.find((group) => group.groupId === groupId) || null;    
    setOnGrouping(true);
    setSelectedGroup(auxSelectedGroup);  
  };

  const handleGroupsWithDiagnosis = (groups = []) => {
    let groupsWithDiagnosis = 0;

    groups.forEach((group) => {
      if (group.groupStatus === 1) {
        groupsWithDiagnosis += 1;
      }
    });

    setGroupsWithDiagnosis(groupsWithDiagnosis);
  };

  const addArrayVideo = (array, groupName) => {
    setArrayVideo(array);
    setShowModalPlayerVideo({show: false, value: ''})
    setGroupNameVideo(groupName)
  }

  const clearDataVideo = () => {
    setArrayVideo([]);
    setGroupNameVideo('');
  }

  return (
    <GenericModal
      title={`Visualizar detalhes${onGrouping ? " - Agrupar" : ""}`}
      setShowModal={setShowModal}
    >
      {showModalDiagnosis ? (
        <ModalDiagnosis
          setShowModal={setShowModalDiagnosis}
          group={selectedGroup}
          handleGetImageByID={handleGetImageByID}
          handleGetAnalysedImageInfo={handleGetAnalysedImageInfo}
          handleGetModalInfo={() =>
            handleGetModalInfo(selectedItem?.screeningId)
          }
          groupAnalysedImages={selectedGroup?.groupAnalysedImages}
          screeningId={selectedItem?.screeningId}
        />
      ) : null}

      {showModalGenerateReport ? (
        <ModalGenerateReport
          setShowModal={setShowModalGenerateReport}
          groupsWithDiagnosis={groupsWithDiagnosis}
          screeningId={selectedItem?.screeningId}
          reportFiltersData={reportData}
          handleGetModalInfo={() => {
            handleUpdateInfoRequests(null, true);
            setShowModal(false);
          }}
        />
      ) : null}

      <LeftContainer>
        <Gallery
          groups={groups}
          gallery={gallery}
          galleryVideo={galleryVideo}
          handleGetImageByID={handleGetImageByID}
          setShowModalDiagnosis={setShowModalDiagnosis}
          onGrouping={onGrouping}
          setOnGrouping={setOnGrouping}
          setSelectedGroup={setSelectedGroup}
        />
      </LeftContainer>

      <RightContainer>
        {onGrouping ? (
          <GroupSection
            setOnGrouping={setOnGrouping}
            selectedGroup={selectedGroup}
            setSelectedGroup={setSelectedGroup}
            gallery={gallery}
            galleryVideo={galleryVideo}
            groupNameVideo={groupNameVideo}
            addedImagesVideo={arrayVideo}
            handleGetImageByID={handleGetImageByID}
            screeningId={selectedItem?.screeningId}
            handleGetModalInfo={handleGetModalInfo}
            setShowModalDiagnosis={setShowModalDiagnosis}
            setShowModalPlayerVideo={setShowModalPlayerVideo}
            arrayVideo={arrayVideo}
            clearDataVideo={clearDataVideo}
          />
        ) : (
          <BodySection
            generalData={generalData}
            selectedItem={selectedItem}
            handleSeeGroupByID={handleSeeGroupByID}
            setShowModalGenerateReport={setShowModalGenerateReport}
          />
        )}
        {showModalPlayerVideo.show ? (
          <GenericModal modalVideo={true} title={'Visualizador de vídeo'} setShowModal={setShowModalPlayerVideo}>
            <ContainerModal>
              <ContainerPlayerVideo>
                <PlayerVideo video={showModalPlayerVideo.value} saveImage={addArrayVideo} />
              </ContainerPlayerVideo>
            </ContainerModal>
          </GenericModal>
        ): null}
      </RightContainer>
    </GenericModal>
  );
}

export default ModalScientificPanel;
