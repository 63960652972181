import React from "react";

// Components
import Input from "components/Input";
import SelectInput from "components/SelectInput";

// Constants
import { collectTypesENUM, statusENUM } from "../../../../constants";

import { FormColumn, InputGroup, InputLabel } from "./styles";

function ContactForm({ companyData, setCompanyData, isEmailvalid }) {
  return (
    <>
      <FormColumn>
        <InputGroup>
          <InputLabel>Nome</InputLabel>
          <Input
            maxLength={255}
            value={companyData.company.companyName}
            onChange={(e) =>
              setCompanyData({
                ...companyData,
                company: {
                  ...companyData.company,
                  companyName: e.target.value,
                },
              })
            }
          />
        </InputGroup>

        <InputGroup>
          <InputLabel>Pessoa Contato</InputLabel>
          <Input
            maxLength={255}
            value={companyData.company.contactName}
            onChange={(e) =>
              setCompanyData({
                ...companyData,
                company: {
                  ...companyData.company,
                  contactName: e.target.value,
                },
              })
            }
          />
        </InputGroup>

        <InputGroup>
          <InputLabel>Tipo</InputLabel>
          <SelectInput
            defaultOption={{ name: "Selecione...", id: null }}
            selectedOption={collectTypesENUM.find(
              (item) => item.id === companyData.company.companyTypeId
            )}
            optionLabel="name"
            optionValue="id"
            options={collectTypesENUM}
            setSelectedOption={(e) => {
              setCompanyData({
                ...companyData,
                company: {
                  ...companyData.company,
                  companyTypeId: e.id,
                },
              });
            }}
          />
        </InputGroup>
      </FormColumn>

      <FormColumn>
        <InputGroup>
          <InputLabel>E-mail</InputLabel>
          <Input
            inputError={!isEmailvalid(companyData.company.companyEmail)}
            maxLength={255}
            value={companyData.company.companyEmail}
            onChange={(e) =>
              setCompanyData({
                ...companyData,
                company: {
                  ...companyData.company,
                  companyEmail: e.target.value,
                },
              })
            }
          />
        </InputGroup>

        <InputGroup>
          <InputLabel>E-mail Pessoa Contato</InputLabel>
          <Input
            inputError={!isEmailvalid(companyData.company.contactEmail)}
            maxLength={255}
            value={companyData.company.contactEmail}
            onChange={(e) =>
              setCompanyData({
                ...companyData,
                company: {
                  ...companyData.company,
                  contactEmail: e.target.value,
                },
              })
            }
          />
        </InputGroup>

        <InputGroup>
          <InputLabel>Situação</InputLabel>
          <SelectInput
            defaultOption={{ name: "Selecione...", id: null }}
            selectedOption={statusENUM.find(
              (item) => item.id === companyData.company.companyStatus
            )}
            optionLabel="name"
            optionValue="id"
            options={statusENUM}
            setSelectedOption={(e) => {
              setCompanyData({
                ...companyData,
                company: {
                  ...companyData.company,
                  companyStatus: e.id,
                },
              });
            }}
          />
        </InputGroup>
      </FormColumn>
    </>
  );
}

export default ContactForm;
