/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";

// Libs
import { toast } from "react-toastify";

// Forms
import InformationForm from "./InformationForm";
import FilesForm from "./FilesForm";
import CollectTypesForm from "./CollectTypesForm";

// Components
import Button from "components/Button";
import ButtonInverted from "components/ButtonInverted";

// Services
import DashboardService from "services/dashboard";

// Constants
import { initialCustomerValues, statusENUM } from "../../../../constants";

// Context
import { useGeneralProvider } from "contexts/general";

// Imgs
import CloseIconSvg from "assets/close-icon.svg";

// Styles
import {
  BackDrop,
  Container,
  HeaderContainer,
  HeaderText,
  HeaderCloseContainer,
  ImageWrapper,
  CloseIcon,
  BodyContainer,
  FooterContainer,
  TopSection,
  MiddleSection,
  BottomSection,
  MiddleLeftSection,
  MiddleRightSection,
  SectionTitle,
  HeaderLogoImg,
  InfoHeaderContainer,
  HeaderCustomerText,
  HeaderStatusBaseText,
  HeaderStatusValueText,
} from "./styles";

function EditCustomer({
  setShowModal,
  selectedItem,
  handleCleanFilters,
  isEditingCustomer,
}) {
  const { setLoading, globalUser } = useGeneralProvider();
  const userPassService = new DashboardService(globalUser);

  const [isSaveEnabled, setIsSaveEnabled] = useState(false);
  const [companyData, setCompanyData] = useState(initialCustomerValues);

  useEffect(() => {
    handleGetCustomerAssets();
  }, []);

  useEffect(() => {
    handleEnableButtonSave();
  }, [companyData]);

  const isEmailvalid = (value) => {
    let reg = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
    if (value) {
      if (reg.test(value) === false) {
        return false;
      } else {
        return true;
      }
    } else {
      return true;
    }
  };

  const handleUpdateCompany = () => {
    setLoading(true);

    userPassService
      .updateCompany(companyData)
      .then(() => {
        toast.success("Cliente atualizado com sucesso!");
        handleCleanFilters();
        setShowModal(false);
      })
      .catch((err) => {
        setLoading(false);

        if (err?.response?.data?.message) {
          toast.error(err.response.data.message);
        } else {
          toast.error("Falha ao atualizar cliente");
        }
      });
  };

  const handleGetCustomerAssets = () => {
    setLoading(true);

    userPassService
      .getCompanyAssets(selectedItem.companyId)
      .then((o) => {
        if (o.data?.files && o.data?.collectTypes) {
          setCompanyData({
            ...companyData,
            company: { ...selectedItem },
            files: o.data.files,
            collectTypes: o.data.collectTypes,
          });
        }
      })
      .catch(() => {
        toast.error(
          `Falha ao buscar dados do cliente: ${selectedItem.companyName}`
        );
        setShowModal(false);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleEnableButtonSave = () => {
    let auxIsSaveEnabled = true;

    if (
      !companyData.company.companyName ||
      !companyData.company.companyEmail ||
      !isEmailvalid(companyData.company.companyEmail) ||
      !companyData.company.contactName ||
      !companyData.company.contactEmail ||
      !isEmailvalid(companyData.company.contactEmail)
    ) {
      auxIsSaveEnabled = false;
    }

    if (
      companyData.company.companyTypeId === null ||
      companyData.company.companyStatus === null
    ) {
      auxIsSaveEnabled = false;
    }

    if (!companyData.files.logo || !companyData.files.waterMark) {
      auxIsSaveEnabled = false;
    }

    setIsSaveEnabled(auxIsSaveEnabled);
  };

  return (
    <BackDrop>
      <Container>
        <HeaderContainer>
          <HeaderCloseContainer>
            <ImageWrapper />
            <HeaderText>
              {isEditingCustomer
                ? "Editar Informações"
                : "Visualizar Informações"}
            </HeaderText>
            <ImageWrapper onClick={() => setShowModal(false)}>
              <CloseIcon src={CloseIconSvg} />
            </ImageWrapper>
          </HeaderCloseContainer>
        </HeaderContainer>

        <BodyContainer>
          <TopSection>
            {companyData?.files?.logo ? (
              <HeaderLogoImg src={companyData?.files?.logo} alt="logo" />
            ) : null}

            <InfoHeaderContainer>
              <HeaderCustomerText
                title={companyData?.company?.companyName || ""}
              >
                {companyData?.company?.companyName || ""}
              </HeaderCustomerText>
              <HeaderStatusBaseText>Situação: </HeaderStatusBaseText>
              <HeaderStatusValueText
                active={companyData?.company?.companyStatus === 0}
              >
                {statusENUM.find(
                  (item) => item.id === companyData?.company?.companyStatus
                )?.name || ""}
              </HeaderStatusValueText>
            </InfoHeaderContainer>
          </TopSection>
          <MiddleSection>
            <MiddleLeftSection>
              <SectionTitle>Informações</SectionTitle>
              <InformationForm
                companyData={companyData}
                setCompanyData={setCompanyData}
                isEmailvalid={isEmailvalid}
                isEditingCustomer={isEditingCustomer}
              />
            </MiddleLeftSection>
            <MiddleRightSection>
              <SectionTitle>Tipo de coleta</SectionTitle>
              <CollectTypesForm
                companyData={companyData}
                setCompanyData={setCompanyData}
                isEditingCustomer={isEditingCustomer}
              />
            </MiddleRightSection>
          </MiddleSection>
          <BottomSection>
            <SectionTitle>Arquivos</SectionTitle>
            <FilesForm
              companyData={companyData}
              setCompanyData={setCompanyData}
              isEditingCustomer={isEditingCustomer}
            />
          </BottomSection>
        </BodyContainer>

        {isEditingCustomer ? (
          <FooterContainer>
            <ButtonInverted
              label={"cancelar"}
              onClick={() => setShowModal(false)}
            />
            <span />
            <Button
              label={"Salvar Alterações"}
              disabled={!isSaveEnabled}
              onClick={() => {
                handleUpdateCompany();
              }}
            />
          </FooterContainer>
        ) : null}
      </Container>
    </BackDrop>
  );
}

export default EditCustomer;
