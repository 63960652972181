import styled from "styled-components";

const CustomDivScroll = styled.div`
  ::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }
  ::-webkit-scrollbar-button {
    width: 0px;
    height: 0px;
  }
  ::-webkit-scrollbar-thumb {
    background: #0073ab;
    border-radius: 20px;
  }
  ::-webkit-scrollbar-thumb:hover {
    background: #0073ab;
  }
  ::-webkit-scrollbar-thumb:active {
    background: #0073ab;
  }
  ::-webkit-scrollbar-track {
    background: transparent;
    border-radius: 50px;
  }
  ::-webkit-scrollbar-track:hover {
    background: transparent;
  }
  ::-webkit-scrollbar-track:active {
    background: transparent;
  }
  ::-webkit-scrollbar-corner {
    background: transparent;
  }
`;

export const BackDrop = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(56, 57, 61, 0.2);
  z-index: 999;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
`;

export const Container = styled(CustomDivScroll)`
  background-color: #fefefe;
  /* width: 636px; */
  min-height: 300px;
  max-width: calc(100vw - 200px);
  max-height: calc(100vh - 100px);
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  overflow-x: auto;

  @media (max-width: 600px) {
    max-width: calc(100vw - 20px);
    max-height: calc(100vh - 20px);
  }
`;

export const HeaderContainer = styled.div`
  width: 100%;
`;

export const HeaderText = styled.p`
  font-size: 16px;
  color: #38393d;
`;

export const HeaderCloseContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  height: 30px;
  padding-top: 16px;
  padding-right: 16px;
  padding-left: 16px;
  align-items: center;
`;

export const ImageWrapper = styled.div`
  cursor: pointer;
  width: 24px;
  height: 24px;

  :hover {
    opacity: 0.7;
  }
`;

export const CloseIcon = styled.img`
  width: 24px;
  height: 24px;
  user-select: none;
  pointer-events: none;
`;

export const BodyContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const SectionTitle = styled.p`
  color: #1a1a1a;
  font-size: 18px;
  margin-bottom: 10px;
  font-weight: bold;
`;

export const TopSection = styled.div`
  padding: 16px;
  display: flex;
  align-items: center;
  height: 100px;
  max-width: 500px;
`;

export const MiddleSection = styled.div`
  display: flex;
  flex-direction: row;
  border-top: solid 1px #ded9d9;
  border-bottom: solid 1px #ded9d9;
`;

export const MiddleLeftSection = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px;
`;

export const FormContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

export const MiddleRightSection = styled.div`
  padding: 16px;
  border-left: solid 1px #ded9d9;
`;

export const BottomSection = styled.div`
  border-bottom: solid 1px #ded9d9;
  padding: 16px;
`;

export const FormColumn = styled.div`
  display: flex;
  flex-direction: column;
  width: 280px;

  :first-of-type {
    margin-right: 40px;
  }
`;

export const FooterContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 16px;

  span {
    width: 24px;
  }
`;

export const InputGroup = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 24px;

  :last-of-type {
    margin-bottom: 0;
  }
`;

export const InputLabel = styled.p`
  color: #1a1a1a;
  font-size: 14px;
  margin-bottom: 6px;
`;

export const UploadTitleText = styled.p`
  color: #525252;
  font-size: 16px;
  margin-bottom: 6px;
`;

export const UploadIconImg = styled.img`
  width: 30px;
  height: 30px;
  margin-bottom: 5px;
`;

export const UploadText = styled.p`
  font-size: 16px;
  color: ${(props) => (props.actived ? "#2773AB" : "#38393D")};
  font-weight: bold;
`;

export const ContainerFilesForm = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
`;

export const WrapperFilesGroup = styled.div`
  width: 100%;

  :first-of-type {
    margin-right: 20px;
  }
`;

export const ContainerUploadFiles = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 5px;
  border-width: 2px;
  border-radius: 2px;
  border-style: dashed;
  background-color: #fafafa;
  color: #bdbdbd;
  outline: none;
  width: 100%;
`;

export const ViewImageContainer = styled.div`
  height: 100px;
  display: flex;
  align-items: center;
  margin-bottom: 20px;
`;

export const RemoveFileText = styled.div`
  cursor: pointer;
  font-size: 14px;
  margin-left: 20px;
`;

export const ViewImage = styled.img`
  max-width: 100%;
  max-height: 100%;
`;

export const HeaderLogoImg = styled.img`
  max-width: 100%;
  max-height: 100%;
  margin-right: 10px;
`;

export const InfoHeaderContainer = styled.div`
  width: 100%;
`;

export const HeaderCustomerText = styled.div`
  font-size: 16px;
  color: #1a1a1a;
  margin-bottom: 4px;
  font-weight: bold;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
`;

export const HeaderStatusBaseText = styled.span`
  color: #a2a2a2;
  font-size: 14px;
`;

export const HeaderStatusValueText = styled.span`
  color: ${(props) => (props.active ? "#40A99E" : "#C73734 ")};
  font-size: 14px;
  font-weight: bold;
`;

export const CollectTypesContainer = styled(CustomDivScroll)`
  width: 320px;
  max-height: 360px;
  display: flex;
  flex-direction: column;

  overflow-y: auto;
`;

export const CollectTypeGroup = styled.div`
  :last-of-type {
    margin-top: 16px;
  }
`;

export const CollectTypeTitle = styled.div`
  font-size: 14px;
  margin-bottom: 8px;
  font-weight: bold;
`;

export const CollectTypeOptionsGroup = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 0px 0px;
`;

export const TypeCheckContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: fit-content;
  margin-bottom: 12px;

  :hover {
    cursor: ${(props) => (props.disabled ? "unset" : "pointer")};
    opacity: ${(props) => (props.disabled ? 1 : 0.6)};
  }
`;

export const TypeCheckImg = styled.img`
  width: 16px;
  height: 16px;
  margin-right: 6px;
`;

export const TypeCheckText = styled.p`
  color: #1a1a1a;
  font-size: 12px;
`;
