import styled from "styled-components";

export const GroupDataContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-right: 16px;
  margin-bottom: 8px;

  :last-of-type {
    margin: 0;
  }
`;

export const InfoCardContainer = styled.div`
  background-color: #f1f1f1;
  padding: 4px 6px 4px 6px;
  align-items: center;
  color: #38393d;
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  font-size: 14px;
  border-radius: 4px;
  word-break: break-all;
`;

export const ItemText = styled.p`
  color: #38393d;
  font-size: 12px;
  align-self: flex-start;
  margin-bottom: 6px;
`;

export const disabledCheck = styled.p`
  color: #38393d;
  font-size: 10px;
`;
