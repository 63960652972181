import styled from "styled-components";

export const Container = styled.div`
  height: 40px;
  width: auto;
  padding: 0 28px 0 28px;
  background-color: ${(props) => (props.isSelected ? "#0073AB" : "#f9f9f9")};
  color: ${(props) => (props.isSelected ? "#f9f9f9" : "#000000")};
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 6px 6px 0 0;
  cursor: pointer;
  user-select: none;

  :hover {
    opacity: 0.7;
  }
`;
