import React from "react";
import Select from "react-select";

import { Container } from "./styles";

function SelectInput({
  defaultOption,
  options,
  optionLabel,
  optionValue,
  selectedOption,
  setSelectedOption,
  disabled,
  isMulti,
  smallSize,
  isSearchable = false,
  maxHeight
}) {
  const customStyles = {
    control: (provided) => ({
      ...provided,
      border: "1px solid #9D9E9D",
      height: smallSize ? 40 : 48,
      boxShadow: "none",
      "&:focus": {
        border: "1px solid #9D9E9D",
      },
      "&:hover": {
        border: "1px solid #9D9E9D",
      },
    }),
    singleValue: (provided, state) => ({
      ...provided,
      color: state.isDisabled ? "#333333" : "unset",
    }),
    menuList: styles => ({
        ...styles,
        maxHeight: maxHeight || styles.maxHeight
    })
  };

  return (
    <Container>
      <Select
        isDisabled={disabled}
        isMulti={isMulti}
        value={selectedOption}
        styles={customStyles}
        defaultValue={defaultOption}
        options={options}
        getOptionLabel={(option) => option[optionLabel]}
        getOptionValue={(option) => option[optionValue]}
        onChange={setSelectedOption}
        isSearchable={isSearchable}
        noOptionsMessage={() => "Sem opções."}
        placeholder={"Selecione..."}
      />
    </Container>
  );
}

export default SelectInput;
