import React from "react";

import { Container, Text } from "./styles";

function ProgressText({ progress }) {
  return (
    <Container
      color={progress === 1 ? "#40A99E" : "#D97E3F"}
      backColor={progress === 1 ? "#ECF6F5" : "#FBF2EC"}
    >
      <Text>{progress === 1 ? "Completo" : "Incompleto"}</Text>
    </Container>
  );
}

export default ProgressText;
