/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";

// Context
import { useGeneralProvider } from "contexts/general";

// Components
import TabOption from "./components/TabOption";
import ViewScreenings from "./components/ViewScreenings";

import { Container, ContentWrapper, TabContainer } from "./styles";

function MyLists() {
  const { setSelectedPage, setCurrentSubtitle } = useGeneralProvider();
  const [selectedTab, setSelectedTab] = useState(0);

  useEffect(() => {
    setSelectedPage("/listas-salvas");
    setCurrentSubtitle("Minhas Listas");
  }, []);

  return (
    <Container>
      <ContentWrapper>
        <TabContainer>
          <TabOption
            label="Triagens"
            tabValue={0}
            selectedTab={selectedTab}
            setSelectedTab={setSelectedTab}
          />
        </TabContainer>

        {selectedTab === 0 ? <ViewScreenings /> : null}
      </ContentWrapper>
    </Container>
  );
}

export default MyLists;
