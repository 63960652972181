import React from "react";

// Libs
import { saveAs } from "file-saver";
import { MdFileDownload } from "react-icons/md";

import { Container } from "./styles";

function DownloadImage({
  handleFinalImage,
  disabled,
  setSelectedImage,
  savedImage,
  isViewingSavedImage,
}) {
  const downloadURI = (uri, name) => {
    saveAs(uri, name);
  };

  const handleDownload = async () => {
    const finalImage = await handleFinalImage();

    if (finalImage) {
      downloadURI(finalImage, "predikta-thermal-image.png");
    }
  };

  return (
    <Container
      disabled={isViewingSavedImage ? false : disabled}
      onClick={() => {
        if (isViewingSavedImage) {
          downloadURI(
            savedImage?.thermalImageLink,
            "predikta-thermal-image.png"
          );

          return;
        }

        if (!disabled) {
          setSelectedImage(null);
          setTimeout(() => {
            handleDownload();
          }, 0);
        }
      }}
    >
      <MdFileDownload />
    </Container>
  );
}

export default DownloadImage;
