import styled from "styled-components";

const CustomDivScroll = styled.div`
  ::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }
  ::-webkit-scrollbar-button {
    width: 0px;
    height: 0px;
  }
  ::-webkit-scrollbar-thumb {
    background: #0073ab;
    border-radius: 20px;
  }
  ::-webkit-scrollbar-thumb:hover {
    background: #0073ab;
  }
  ::-webkit-scrollbar-thumb:active {
    background: #0073ab;
  }
  ::-webkit-scrollbar-track {
    background: transparent;
    border-radius: 50px;
  }
  ::-webkit-scrollbar-track:hover {
    background: transparent;
  }
  ::-webkit-scrollbar-track:active {
    background: transparent;
  }
  ::-webkit-scrollbar-corner {
    background: transparent;
  }
`;

export const BackDrop = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(56, 57, 61, 0.2);
  z-index: 999;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
`;

export const Container = styled.div`
  background-color: #fefefe;
  width: ${({ fullScreen }) => (fullScreen ? "calc(100vw)" : "unset")};
  height: ${({ fullScreen }) => (fullScreen ? "calc(100vh)" : "auto")};
  border-radius: ${({ fullScreen }) => (fullScreen ? "0" : "4px")};
  display: flex;
  flex-direction: column;
`;

export const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 44px;
  padding-left: 20px;
  padding-right: 20px;
`;

export const HeaderTitle = styled.p`
  color: #38393d;
  font-size: 18px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const ImageWrapper = styled.div`
  cursor: pointer;

  :hover {
    opacity: 0.7;
  }
`;

export const GenericHeaderMargin = styled.span`
  width: 24px;
  height: 24px;
`;

export const CloseIcon = styled.img`
  width: 24px;
  height: 24px;
  user-select: none;
  pointer-events: none;
`;

export const Body = styled(CustomDivScroll)`
  display: flex;
  width: 100%;
  height: 100%;
  overflow: auto;
  justify-content: ${({ modalValue }) => (modalValue ? "right" : "center")};
  padding: ${({ noBodyPadding }) => (noBodyPadding ? 0 : "0 20px 20px 20px")};
`;
