/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";

// Libs
import { ImPlus, ImCross } from "react-icons/im";
import { MdKeyboardArrowDown, MdPlayArrow } from "react-icons/md";
import { toast } from "react-toastify";

// Services
import DashboardService from "services/dashboard";

// Context
import { useGeneralProvider } from "contexts/general";

// Components
import ModalConfirmRemove from "./components/ModalConfirmRemove";
import Input from "components/Input";
import Button from "components/Button";
import ButtonInverted from "components/ButtonInverted";
import DownloadImages from "components/DownloadImages";

// Imgs
import DefaultUser from "assets/default-user.png";

// Utils
import { getDefaultPalleteRgb } from "utils";

// Constants
import {
  minTemperatureCelsius,
  maxTemperatureCapCelsius
} from "../../../../../../components/ThermalManipulation/constants";

import {
  GlobalContainer,
  GroupContainer,
  HeaderContainer,
  InputGroup,
  InputLabel,
  SelectionContainer,
  AddedGridItem,
  AddedImageContainer,
  AddedImage,
  RemoveImageContainer,
  DiagnosisContainer,
  DiagnosisHeader,
  DiagnosisBody,
  DiagnosisTitle,
  DiagnosisSubTitle,
  CustomAccordion,
  CustomAccordionSummary,
  CustomAccordionDetails,
  GalleryContainer,
  GalleryImageContainer,
  AddImageContainer,
  GalleryImage,
  EmptyContainer,
  ContainerGalleryVideo,
  PlayVideo
} from "./styles";

const PALETTE_APPLIED_TITLE = 'Pallete applied';

function GroupSection({
  setOnGrouping,
  selectedGroup,
  setSelectedGroup,
  gallery,
  galleryVideo,
  groupNameVideo,
  addedImagesVideo,
  handleGetImageByID,
  screeningId,
  handleGetModalInfo,
  setShowModalDiagnosis,
  setShowModalPlayerVideo,
  arrayVideo,
  clearDataVideo,
}) {
  const palleteRgb = getDefaultPalleteRgb();
  const { setLoading, globalUser } = useGeneralProvider();
  const userPassService = new DashboardService(globalUser);

  const [groupName, setGroupName] = useState("");
  const [addedImages, setAddedImages] = useState([]);
  const [addedAnalysedImages, setAddedAnalysedImages] = useState([]);
  const [groupDiagnosis, setGroupDiagnosis] = useState([]);
  const [showModalConfirmRemove, setShowModalConfirmRemove] = useState({
    show: false,
    value: "",
  });

  useEffect(() => {
    if (groupNameVideo) {
      setGroupName(groupNameVideo)
      setAddedImages(addedImagesVideo)
      setTimeout(() => {
        handleCreateGroup();
      }, 500);
    }
  }, [groupNameVideo]);

  useEffect(() => {
    if (selectedGroup) {
      setGroupName(selectedGroup.groupName);
      setAddedImages([...selectedGroup.groupImages]);
      setAddedAnalysedImages([...selectedGroup.groupAnalysedImages]);
    }
  }, [selectedGroup]);

  useEffect(() => {
    if (arrayVideo) {
      arrayVideo.forEach((item) => (
        handleAddImage(item.imageId)
      ))
    }
  }, [arrayVideo])

  useEffect(() => {
    if (selectedGroup) {
      handleGetGroupDiagnostic();
    }
  }, [selectedGroup]);

  const handleGetGroupDiagnostic = () => {
    setLoading(true);

    userPassService
      .getGroupDiagnostic(selectedGroup.groupId)
      .then((o) => {
        if (o.data) {
          setGroupDiagnosis(o.data);
        } else {
          setGroupDiagnosis([]);
        }
      })
      .catch(() => {
        setGroupDiagnosis([]);
        toast.error("Falha ao listar dados de diagnóstico");
      })
      .finally(() => setLoading(false));
  };

  const handleCancel = () => {
    setSelectedGroup(null);
    setOnGrouping(false);
  };

  const handleAddImage = (imageId) => {
    if (addedImages.includes(imageId)) {
      return;
    }

    const auxAddedImages = [...addedImages];
    auxAddedImages.push(imageId);
    addedImages.push(imageId);
    setAddedImages(auxAddedImages);
  };

  const handleRemoveImage = (imageId) => {
    const auxAddedImages = [...addedImages];

    const index = auxAddedImages.indexOf(imageId);
    if (index > -1) {
      auxAddedImages.splice(index, 1);
    }

    setAddedImages([...auxAddedImages]);
  };

  const handleRemoveAnalysedImage = () => {
    const auxAddedAnalysedImages = [...addedAnalysedImages];
    const imageId = showModalConfirmRemove.value;

    const index = auxAddedAnalysedImages.indexOf(imageId);
    if (index > -1) {
      auxAddedAnalysedImages.splice(index, 1);
    }

    setAddedAnalysedImages([...auxAddedAnalysedImages]);

    setShowModalConfirmRemove({ show: false, value: "" });
  };

  const handleCreateGroup = () => {
    setLoading(true);

    const groupObject = {
      screeningId: screeningId,
      groupName: groupNameVideo ? groupNameVideo : groupName,
      groupImages: addedImages,
    };

    userPassService
      .createScreeningGroup(groupObject)
      .then(() => {
        toast.success("Grupo criado com sucesso");
        handleCancel();
        handleGetModalInfo(screeningId);
        clearDataVideo();
      })
      .catch((err) => {
        setLoading(false);
        if (err?.response?.data?.message) {
          toast.error(err.response.data.message);
        } else {
          toast.error("Falha ao criar grupo");
        }
      });
  };

  const handleEditGroup = () => {
    setLoading(true);

    const groupObject = {
      groupId: selectedGroup.groupId,
      screeningId: screeningId,
      groupName: groupName,
      groupImages: addedImages,
      groupAnalysedImages: addedAnalysedImages,
    };

    userPassService
      .updateScreeningGroup(groupObject)
      .then(() => {
        toast.success("Grupo atualizado com sucesso");
        handleCancel();
        handleGetModalInfo(screeningId);
      })
      .catch((err) => {
        setLoading(false);
        if (err?.response?.data?.message) {
          toast.error(err.response.data.message);
        } else {
          toast.error("Falha ao atualizar grupo");
        }
      });
  };

  // Extrai o "raw" da imagem com base no RGB de cada pixel e calcula a temperatura
  const handleGetTemperature = (green, blue) => {
      const raw = green * 256 + blue;

      const M = 0.02569698246480104;
      const B = 64.74694198003411;

      const temp = M * raw - B;

      return temp;
  };

  const handleInitialMapping = (imageData) => {
      const dataLength = imageData.data.length;
      const pixelLength = 4;

      const temperaturePixelArray = [];

      for (let i = 0; i <= dataLength - pixelLength; i += pixelLength) {
          const green = imageData.data[i + 1];
          const blue = imageData.data[i + 2];

          const temp = handleGetTemperature(green, blue);

          if (temp >= minTemperatureCelsius && temp <= maxTemperatureCapCelsius) {
            temperaturePixelArray.push({ t: temp, p: i });
          }
      }

      const currentImageSortedData = temperaturePixelArray.sort((a, b) => {
          if (a.t < b.t) return -1;
          if (a.t > b.t) return 1;
          return 0;
      });

      return currentImageSortedData;
  };

  const handleTemperaturePerPixel = (imageData, currentImageValues) => {
      const temperaturePixelArray = [];

      for (let i = 0; i < currentImageValues.length; i++) {
          temperaturePixelArray.push(currentImageValues[i]);
      }
      return temperaturePixelArray;
  };

  const handlePixelTransform = (imageData, currentImageValues) => {
      const temperaturePixelArray = handleTemperaturePerPixel(imageData, currentImageValues);
      const expandedPalette = [];

      const pixelsPerColor = Math.ceil(
          temperaturePixelArray.length / palleteRgb.length
      );

      const reversedPalette = [...palleteRgb].reverse();

      for (let i = 0; i < reversedPalette.length; i++) {
          for (let j = 0; j < pixelsPerColor; j++) {
              expandedPalette.push(reversedPalette[i]);
          }
      }

      if (expandedPalette.length !== 0) {
          for (let i = 0; i < temperaturePixelArray.length; i++) {
              const currentColor = expandedPalette[i] || [0, 0, 0];

              // red
              imageData.data[temperaturePixelArray[i].p] = currentColor[0];
              // green
              imageData.data[temperaturePixelArray[i].p + 1] = currentColor[1];
              // blue
              imageData.data[temperaturePixelArray[i].p + 2] = currentColor[2];
          }
      }
      return imageData;
  };

  const validURL = (str) => {
      var pattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // domain name
      '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
      '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
      '(\\#[-a-z\\d_]*)?$','i'); // fragment locator
      return !!pattern.test(str);
  };

  const getTransformedImage = async (imageUrl) => {    
      let reload = false;
      if (validURL(imageUrl.target.currentSrc)) {
          const image = await userPassService.getImageBase64ByUrl(imageUrl.target.currentSrc);
          imageUrl.target.src = image.data;
          imageUrl.target.title = null;
          reload = true;
      }

      if (!reload && imageUrl.target.title !== PALETTE_APPLIED_TITLE) {
          // Create an empty canvas element
          const canvas = document.createElement("canvas");
          canvas.width = imageUrl.target.naturalWidth;
          canvas.height = imageUrl.target.naturalHeight;

          // Copy the image contents to the canvas
          const ctx = canvas.getContext("2d");
          ctx.drawImage(imageUrl.target, 0, 0);
          const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
          const currentImageValues = handleInitialMapping(imageData);
          const newImageData = handlePixelTransform(imageData, currentImageValues);
          ctx.putImageData(newImageData, 0, 0);
          imageUrl.target.title = PALETTE_APPLIED_TITLE;
          imageUrl.target.src = canvas.toDataURL();
      }  
  };

  const getImagesUrisForDownload = () => {
    const allImageUris = [];

    addedImages.forEach(addedImage => allImageUris.push(handleGetImageByID(addedImage)));
    addedAnalysedImages.forEach(addedAnalysedImage => allImageUris.push(handleGetImageByID(addedAnalysedImage, true)));

    return allImageUris;
  };

  return (
    <GlobalContainer>
      {showModalConfirmRemove.show ? (
        <ModalConfirmRemove
          showModal={showModalConfirmRemove}
          setShowModal={setShowModalConfirmRemove}
          confirm={() => handleRemoveAnalysedImage()}
        />
      ) : null}

      <GroupContainer>
        <HeaderContainer>
          <InputGroup>
            <InputLabel>Nome do grupo</InputLabel>
            <Input
              maxLength={100}
              value={groupName}
              onChange={(e) => setGroupName(e.target.value)}
            />
          </InputGroup>

          <DownloadImages 
            name={groupName}
            uris={getImagesUrisForDownload()}
            disabled={addedImages.length === 0 && addedAnalysedImages.length === 0}
          />
          <Button
            label={
              selectedGroup
                ? "Atualizar grupo de imagens"
                : "Salvar grupo de imagens"
            }
            onClick={() => {
              if (selectedGroup) {
                handleEditGroup();
              } else {
                handleCreateGroup();
              }
            }}
            disabled={!groupName || !addedImages.length}
          />
          <ButtonInverted label="Cancelar" onClick={handleCancel} />
        </HeaderContainer>

        <SelectionContainer>
          {addedImages.map((imageId) => (
            <AddedGridItem key={`added-${imageId}`}>
              <AddedImageContainer>
                <AddedImage src={handleGetImageByID(imageId) || DefaultUser} />
                <RemoveImageContainer
                  onClick={() => handleRemoveImage(imageId)}
                >
                  <ImCross />
                </RemoveImageContainer>
              </AddedImageContainer>
            </AddedGridItem>
          ))}

          {addedAnalysedImages.map((imageId) => (
            <AddedGridItem key={`added-analysed-${imageId}`}>
              <AddedImageContainer>
                <AddedImage
                  src={handleGetImageByID(imageId, true) || DefaultUser}
                />
                <RemoveImageContainer
                  onClick={() =>
                    setShowModalConfirmRemove({
                      show: true,
                      value: imageId,
                    })
                  }
                >
                  <ImCross />
                </RemoveImageContainer>
              </AddedImageContainer>
            </AddedGridItem>
          ))}
        </SelectionContainer>

        {groupDiagnosis.length ? (
          <DiagnosisContainer>
            <DiagnosisHeader>
              <DiagnosisTitle>Diagnostico</DiagnosisTitle>

              <Button
                label="Editar"
                onClick={() => {
                  setShowModalDiagnosis(true);
                }}
              />
            </DiagnosisHeader>

            {groupDiagnosis.map((diagnosisGroup) => (
              <DiagnosisBody key={diagnosisGroup.pathologyGroupId}>
                <DiagnosisSubTitle>
                  {diagnosisGroup.pathologyGroupDescription}
                </DiagnosisSubTitle>

                {diagnosisGroup?.pathologies?.map((pathologie, index) => (
                  <CustomAccordion
                    key={`${index}-${pathologie.pathologyId}`}
                    disableGutters
                    elevation={0}
                  >
                    <CustomAccordionSummary
                      expandIcon={<MdKeyboardArrowDown />}
                    >
                      {pathologie.pathologyDescription}
                    </CustomAccordionSummary>
                    <CustomAccordionDetails>
                      {pathologie.pathologyDetail}
                    </CustomAccordionDetails>
                  </CustomAccordion>
                ))}
              </DiagnosisBody>
            ))}
          </DiagnosisContainer>
        ) : null}
      </GroupContainer>

      <GalleryContainer>
        {gallery && gallery.length !== 0 ? (
          gallery.map((image) => (
            <GalleryImageContainer key={image.imageId}>
              <AddImageContainer
                onClick={() => handleAddImage(image.imageId)}
                disabled={addedImages.includes(image.imageId)}
              >
                <ImPlus />
              </AddImageContainer>
              <GalleryImage 
                src={image.thermalImageLink || DefaultUser} 
                onLoad={getTransformedImage}
              />
            </GalleryImageContainer>
          ))
        ) : (
          <EmptyContainer>Sem imagens.</EmptyContainer>
        )}

        {galleryVideo && galleryVideo.length !== 0 ? (
          galleryVideo.map((video) => (
            <GalleryImageContainer key={video.thermalImages[0].imageId}>
              <AddImageContainer
                onClick={() => setShowModalPlayerVideo({show: true, value: video})}
                disabled={addedImages.includes(video.thermalImages[0].imageId)}
              >
                <ImPlus />
              </AddImageContainer>
              <ContainerGalleryVideo>
                <GalleryImage
                  src={video.thermalImages[0].thermalImageLink || DefaultUser}
                  onLoad={video.thermalImages[0].thermalImageLink ? (event) => getTransformedImage(event) : null}
                />
                <PlayVideo>
                  <MdPlayArrow size={80} opacity={0.8} />
                </PlayVideo>
              </ContainerGalleryVideo>
            </GalleryImageContainer>
          ))
        ) : (
          <EmptyContainer>Sem vídeos.</EmptyContainer>
        )}
      </GalleryContainer>
    </GlobalContainer>
  );
}

export default GroupSection;
