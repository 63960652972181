/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";

// Libs
import { toast } from "react-toastify";

// Components
import Input from "components/Input";
import Button from "components/Button";
import SelectInput from "components/SelectInput";

// Services
import DashboardService from "services/dashboard";

// Constants
import { initialCustomerAnydesksValues, booleanStatusENUM } from "../../../../constants";

// Context
import { useGeneralProvider } from "contexts/general";

// Imgs
import CloseIconSvg from "assets/close-icon.svg";

// Styles
import {
  BackDrop,
  Container,
  HeaderContainer,
  HeaderText,
  HeaderCloseContainer,
  ImageWrapper,
  CloseIcon,
  BodyContainer,
  FooterContainer,
  InputGroup,
  InputLabel,
} from "./styles";

function AddCustomerAnydesk({ setShowModal, handleCleanFilters, companyId }) {
  const { setLoading, globalUser } = useGeneralProvider();
  const userPassService = new DashboardService(globalUser);

  const [isSaveEnabled, setIsSaveEnabled] = useState(false);
  const [anydeskData, setAnydeskData] = useState({
    ...initialCustomerAnydesksValues,
    status: true
  });

  useEffect(() => {
    handleEnableButtonSave();
  }, [anydeskData]);

  const handleCreateAnydesk = () => {
    setLoading(true);

    userPassService
      .createCompanyAnydesk({ companyId: companyId, ...anydeskData })
      .then(() => {
        toast.success("Anydesk criado com sucesso!");
        handleCleanFilters();
        setShowModal(false);
      })
      .catch((err) => {
        setLoading(false);

        if (err?.response?.data?.message) {
          toast.error(err.response.data.message);
        } else {
          toast.error("Falha ao criar anydesk");
        }
      });
  };

  const handleEnableButtonSave = () => {
    let auxIsSaveEnabled = true;

    if (
      !anydeskData.anydeskName ||
      !anydeskData.anydeskSerial
    ) {
      auxIsSaveEnabled = false;
    }

    setIsSaveEnabled(auxIsSaveEnabled);
  };

  return (
    <BackDrop>
      <Container>
        <HeaderContainer>
          <HeaderCloseContainer>
            <ImageWrapper />
            <HeaderText>Adicionar Anydesk</HeaderText>
            <ImageWrapper onClick={() => setShowModal(false)}>
              <CloseIcon src={CloseIconSvg} />
            </ImageWrapper>
          </HeaderCloseContainer>
        </HeaderContainer>

        <BodyContainer>
          <InputGroup>
            <InputLabel>Nome</InputLabel>
            <Input
              maxLength={255}
              value={anydeskData.anydeskName}
              onChange={(e) =>
                setAnydeskData({
                  ...anydeskData,
                  anydeskName: e.target.value,
                })
              }
            />
          </InputGroup>

          <InputGroup>
            <InputLabel>Serial</InputLabel>
            <Input
              maxLength={100}
              value={anydeskData.anydeskSerial.replace(/ /g,'')}
              onChange={(e) =>
                setAnydeskData({
                  ...anydeskData,
                  anydeskSerial: e.target.value.replace(/ /g,'')
                })
              }
            />
          </InputGroup>

          <InputGroup>
            <InputLabel>Situação</InputLabel>
            <SelectInput
              defaultOption={{ id: true, name: "Ativo" }}
              selectedOption={booleanStatusENUM.find(
                (item) => item.id === anydeskData.status
              )}
              optionLabel="name"
              optionValue="id"
              options={booleanStatusENUM}
              setSelectedOption={(e) => {
                setAnydeskData({
                  ...anydeskData,
                  status: e.id,
                });
              }}
            />
          </InputGroup>
        </BodyContainer>

        <FooterContainer>
          <Button
            label={"Adicionar"}
            disabled={!isSaveEnabled}
            onClick={() => {
              handleCreateAnydesk();
            }}
          />
        </FooterContainer>
      </Container>
    </BackDrop>
  );
}

export default AddCustomerAnydesk;
