import React from "react";

// Components
import CustomTooltip from "components/CustomTooltip";

// Imgs
import TemperatureIcon from "assets/temperature-icon.svg";
import SymptomIcon from "assets/symptom-icon.svg";
import AIIcon from "assets/ai-icon.svg";
import IndexIcon from "assets/index-icon.svg";

import { Container, IconContainer, ImageWrapper, IconImage } from "./styles";

function ReasonIcon({ reasons }) {
  return (
    <Container>
      <IconContainer>
        {reasons.includes(0) ? (
          <CustomTooltip title="Temperatura">
            <ImageWrapper>
              <IconImage src={TemperatureIcon} />
            </ImageWrapper>
          </CustomTooltip>
        ) : null}
      </IconContainer>

      <IconContainer>
        {reasons.includes(1) ? (
          <CustomTooltip title="Sintoma">
            <ImageWrapper>
              <IconImage src={SymptomIcon} />
            </ImageWrapper>
          </CustomTooltip>
        ) : null}
      </IconContainer>

      <IconContainer>
        {reasons.includes(2) ? (
          <CustomTooltip title="AI">
            <ImageWrapper>
              <IconImage src={AIIcon} />
            </ImageWrapper>
          </CustomTooltip>
        ) : null}
      </IconContainer>

      <IconContainer>
        {reasons.includes(3) ? (
          <CustomTooltip title="Índice">
            <ImageWrapper>
              <IconImage src={IndexIcon} />
            </ImageWrapper>
          </CustomTooltip>
        ) : null}
      </IconContainer>
    </Container>
  );
}

export default ReasonIcon;
