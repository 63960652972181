/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";

// Components
import SelectFilter from "components/SelectFilter";
import TextFilter from "components/TextFilter";
import Button from "components/Button";
import ButtonInverted from "components/ButtonInverted";
import Pagination from "components/Pagination";

import ComplexTable from "./components/ComplexTable";
import AddCustomer from "./components/AddCustomer";
import EditCustomer from "./components/EditCustomer";

// Imgs
import AddUserIcon from "assets/add-user-icon.svg";

// Libs
import { toast } from "react-toastify";

// Context
import { useGeneralProvider } from "contexts/general";

// Services
import DashboardService from "services/dashboard";

// Constants
import {
  collectTypesENUM,
  initialFiltersCustomers,
} from "../../constants";

// Utils
import { cleanSelections } from "utils";

import {
  Container,
  ContentWrapper,
  FilterContainer,
  FooterContainer,
  ButtonsContainer,
} from "./styles";

function Customers() {
  const history = useHistory();
  const {
    setLoading,
    globalUser,
    setCurrentSubtitle,
    timerFunction,
    setSelectedPage,
    isAdmin,
  } = useGeneralProvider();
  const userPassService = new DashboardService(globalUser);
  const [data, setData] = useState([]);
  const [status, setStatus] = useState([
    { id: 0, name: "Ativo", checked: true },
    { id: 1, name: "Inativo", checked: false }
  ]);
  const [collectTypes, setCollectTypes] = useState(collectTypesENUM);
  const [searchText, setSearchText] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [selectedItem, setSelectedItem] = useState("");

  const [canBeCleaned, setCanBeCleaned] = useState(false);
  const [currentFilters, setCurrentFilters] = useState(initialFiltersCustomers);

  const [showAddCustomer, setShowAddCustomer] = useState(false);
  const [showEditCustomer, setShowEditCustomer] = useState(false);
  const [isEditingCustomer, setIsEditingCustomer] = useState(false);

  useEffect(() => {
    verifyAdminUser();
    setSelectedPage("/clientes");
    setCurrentSubtitle("");
  }, []);

  useEffect(() => {
    handleUpdateInfoRequests();
  }, [timerFunction]);

  const verifyAdminUser = () => {
    if (!isAdmin) {
      toast.error(
        "O usuário não possui acesso a esta tela. Você será redirecionado"
      );
      history.push("/");
    }
  };

  const handleUpdateInfoRequests = (updatePage, requestWithCleanFilters) => {
    setLoading(true);
    Promise.all([
      userPassService
        .getCompanies(
          requestWithCleanFilters
            ? initialFiltersCustomers
            : {
                ...currentFilters,
                page: updatePage ? updatePage : currentFilters.page,
              }
        )
        .then((o) => {
          if (o.data) {
            const response = o.data;
            if (response.data) {
              const auxData = response.data;
              setData(auxData);
            } else {
              setData([]);
            }
            if (response.totalPages) {
              setTotalPages(response.totalPages);
            } else {
              setTotalPages(1);
            }
            if (response.currentPage) {
              setCurrentPage(response.currentPage);
            } else {
              setCurrentPage(1);
            }
            return;
          }
          setData([]);
        })
        .catch(() => {
          toast.error("Falha ao buscar clientes");
        }),
    ])
      .then(() => {})
      .catch(() => {})
      .finally(() => {
        if (!requestWithCleanFilters) {
          handleManageFiltering();
        }
        setLoading(false);
      });
  };

  const handleCleanFilters = () => {
    const newFilters = {
      ...initialFiltersCustomers,
      page: currentFilters.page
    };
    setCurrentFilters(newFilters);
    setStatus([
      { id: 0, name: "Ativo", checked: true },
      { id: 1, name: "Inativo", checked: false }
    ]);
    setCollectTypes(cleanSelections(collectTypes));

    setSearchText("");
    setCanBeCleaned(false);

    handleUpdateInfoRequests(null, false);
  };

  const handleManageFiltering = () => {
    let auxCanBeCleaned = false;

    if (
      JSON.stringify(initialFiltersCustomers.status) !==
      JSON.stringify(currentFilters.status)
    ) {
      auxCanBeCleaned = true;
    }

    if (
      JSON.stringify(initialFiltersCustomers.collectTypes) !==
      JSON.stringify(currentFilters.collectTypes)
    ) {
      auxCanBeCleaned = true;
    }

    if (initialFiltersCustomers.textSearch !== currentFilters.textSearch) {
      auxCanBeCleaned = true;
    }

    setCanBeCleaned(auxCanBeCleaned);
  };

  return (
    <Container>
      {showAddCustomer ? (
        <AddCustomer
          setShowModal={setShowAddCustomer}
          handleCleanFilters={handleCleanFilters}
        />
      ) : null}

      {showEditCustomer ? (
        <EditCustomer
          setShowModal={setShowEditCustomer}
          selectedItem={selectedItem}
          handleCleanFilters={handleCleanFilters}
          isEditingCustomer={isEditingCustomer}
        />
      ) : null}

      <ContentWrapper>
        <FilterContainer>
          <SelectFilter
            label={"Situação"}
            options={status}
            setOptions={(value) => {
              let toSearchArray = [];
              setCanBeCleaned(false);

              value.forEach((item) => {
                if (item.checked) {
                  toSearchArray.push(item.id);
                }
              });

              setCurrentFilters({
                ...currentFilters,
                page: 1,
                status: toSearchArray,
              });

              setStatus(value);
            }}
          />
          <SelectFilter
            label={"Tipo"}
            options={collectTypes}
            setOptions={(value) => {
              let toSearchArray = [];
              setCanBeCleaned(false);

              value.forEach((item) => {
                if (item.checked) {
                  toSearchArray.push(item.id);
                }
              });

              setCurrentFilters({
                ...currentFilters,
                page: 1,
                collectTypes: toSearchArray,
              });

              setCollectTypes(value);
            }}
          />
          <TextFilter
            searchText={searchText}
            setSearchText={(value) => {
              setCurrentFilters({
                ...currentFilters,
                page: 1,
                textSearch: value,
              });

              setSearchText(value);
            }}
            placeHolder={
              "Busque por nome da empresa, nome da pessoa, contato ou tipo"
            }
          />
          {canBeCleaned ? (
            <Button label={"Limpar"} onClick={() => handleCleanFilters()} />
          ) : (
            <Button
              label={"Pesquisar"}
              onClick={() => handleUpdateInfoRequests()}
            />
          )}
        </FilterContainer>

        <ComplexTable
          data={data}
          setData={setData}
          setSelectedItem={setSelectedItem}
          setShowEditCustomer={setShowEditCustomer}
          setIsEditingCustomer={setIsEditingCustomer}
          handleCleanFilters={handleCleanFilters}
        />

        <FooterContainer>
          <ButtonsContainer>
            <ButtonInverted
              label="Adicionar Cliente"
              icon={AddUserIcon}
              onClick={() => setShowAddCustomer(true)}
            />
          </ButtonsContainer>

          <Pagination
            currentPage={currentPage}
            onPageChange={(page) => {
              setCurrentPage(page);
              handleUpdateInfoRequests(page);
              setCurrentFilters({
                ...currentFilters,
                page: page,
              });
            }}
            totalPages={totalPages}
          />
        </FooterContainer>
      </ContentWrapper>
    </Container>
  );
}

export default Customers;
