import styled from "styled-components";

export const BackDrop = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(56, 57, 61, 0.2);
  z-index: 9999;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
`;

export const Container = styled.div`
  background-color: #fefefe;
  width: 350px;
  height: 150px;
  border-radius: 4px;
  padding: 20px 20px 32px 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: space-between;
`;

export const ImageWrapper = styled.div`
  cursor: pointer;

  :hover {
    opacity: 0.7;
  }
`;

export const CloseIcon = styled.img`
  width: 24px;
  height: 24px;
  user-select: none;
  pointer-events: none;
`;

export const BodyText = styled.div`
  display: flex;
  margin-right: 42px;
  font-size: 16px;
  color: #38393d;
  text-align: center;
  justify-content: center;
`;

export const FooterContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-right: 28px;
  justify-content: flex-end;
`;

export const Button = styled.div`
  cursor: pointer;
  padding: 0 12px 0 12px;
  height: 40px;
  color: #ffffff;
  font-size: 16px;
  font-weight: bold;
  background-color: #0073ab;
  border-radius: 4px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  user-select: none;

  :hover {
    opacity: 0.7;
  }
`;

export const InvertedButton = styled.div`
  cursor: pointer;
  padding: 0 12px 0 12px;
  height: 40px;
  color: #0073ab;
  font-size: 16px;
  font-weight: bold;
  background-color: #fefefe;
  border: solid 1px #0073ab;
  border-radius: 4px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  user-select: none;
  margin-right: 16px;

  :hover {
    opacity: 0.7;
  }
`;
