/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";

// Libs
import { toast } from "react-toastify";

// Components
import SelectInput from "components/SelectInput";
import Button from "components/Button";
import ButtonInverted from "components/ButtonInverted";

// Services
import DashboardService from "services/dashboard";

// Constants
import { initialCustomerNewCompaniesValues } from "../../../../constants";

// Context
import { useGeneralProvider } from "contexts/general";

// Imgs
import CloseIconSvg from "assets/close-icon.svg";

// Styles
import {
  BackDrop,
  Container,
  HeaderContainer,
  HeaderText,
  HeaderCloseContainer,
  ImageWrapper,
  CloseIcon,
  BodyContainer,
  FooterContainer,
  InputGroup,
  InputLabel,
} from "./styles";

function TransferCustomerAnydesk({
  setShowModal,
  selectedItem,
  handleCleanFilters,
  companyId
}) {
  const { setLoading, globalUser } = useGeneralProvider();
  const userPassService = new DashboardService(globalUser);

  const [isSaveEnabled, setIsSaveEnabled] = useState(false);
  const [companiesData, setCompaniesData] = useState([{
    ...initialCustomerNewCompaniesValues
  }]);
  const [newCompanyData, setNewCompanyData] = useState({
    newCompanyId: companyId
  });

  useEffect(() => {
    setLoading(true);

    userPassService
      .getAllCompanies()
      .then((response) => {
        setLoading(false);
        setCompaniesData(response?.data);
      })
      .catch((err) => {
        setLoading(false);

        if (err?.response?.data?.message) {
          toast.error(err.response.data.message);
        } else {
          toast.error("Falha ao buscar dados das empresas");
        }
      });
  }, []);

  useEffect(() => {
    handleEnableButtonSave();
  }, [newCompanyData]);

  const handleUpdateAnydeskCompany = () => {
    setLoading(true);

    userPassService
      .updateCompanyAnydeskCompany({ companyId: companyId, anydeskId: selectedItem.anydeskId, ...newCompanyData })
      .then(() => {
        toast.success("Anydesk transferido com sucesso!");
        handleCleanFilters();
        setShowModal(false);
      })
      .catch((err) => {
        setLoading(false);

        if (err?.response?.data?.message) {
          toast.error(err.response.data.message);
        } else {
          toast.error("Falha ao transferir anydesk");
        }
      });
  };

  const handleEnableButtonSave = () => {
    let auxIsSaveEnabled = true;
    
    if (!newCompanyData.newCompanyId) {
      auxIsSaveEnabled = false;
    }

    setIsSaveEnabled(auxIsSaveEnabled);
  };

  return (
    <BackDrop>
      <Container>
        <HeaderContainer>
          <HeaderCloseContainer>
            <ImageWrapper />
            <HeaderText>Transferir Anydesk</HeaderText>
            <ImageWrapper onClick={() => setShowModal(false)}>
              <CloseIcon src={CloseIconSvg} />
            </ImageWrapper>
          </HeaderCloseContainer>
        </HeaderContainer>

        <BodyContainer>
          <InputGroup>
            <InputLabel>Nova Empresa</InputLabel>
            <SelectInput
              selectedOption={companiesData.find(
                (item) => item.companyId === newCompanyData.newCompanyId
              )}
              optionLabel="companyName"
              optionValue="companyId"
              options={companiesData}
              setSelectedOption={(e) => {
                setNewCompanyData({
                  ...newCompanyData,
                  newCompanyId: e.companyId
                });
              }}
              isSearchable
              maxHeight={136}
            />
          </InputGroup>
        </BodyContainer>

        <FooterContainer>
          <ButtonInverted
            label={"cancelar"}
            onClick={() => setShowModal(false)}
          />
          <span />
          <Button
            label={"Salvar Alterações"}
            disabled={!isSaveEnabled}
            onClick={() => {
              handleUpdateAnydeskCompany();
            }}
          />
        </FooterContainer>
      </Container>
    </BackDrop>
  );
}

export default TransferCustomerAnydesk;
