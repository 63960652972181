import styled from "styled-components";

export const Container = styled.div`
  height: ${(props) => (props.expanded ? "360px" : "0")};
  overflow: hidden;
  transition: all 0.3s;
  display: flex;
  flex-direction: row;
  overflow-x: auto;
  margin: 0 auto 4px auto;

  ::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }
  ::-webkit-scrollbar-button {
    width: 0px;
    height: 0px;
  }
  ::-webkit-scrollbar-thumb {
    background: #0073ab;
    border-radius: 20px;
  }
  ::-webkit-scrollbar-thumb:hover {
    background: #0073ab;
  }
  ::-webkit-scrollbar-thumb:active {
    background: #0073ab;
  }
  ::-webkit-scrollbar-track {
    background: transparent;
    border-radius: 50px;
  }
  ::-webkit-scrollbar-track:hover {
    background: transparent;
  }
  ::-webkit-scrollbar-track:active {
    background: transparent;
  }
  ::-webkit-scrollbar-corner {
    background: transparent;
  }
`;

export const ReasonContainer = styled.div`
  width: 55%;
  min-width: 600px;
  height: 244px;
  overflow-x: auto;
`;

export const ReasonBody = styled.div`
  display: flex;
  flex-direction: row;
`;

export const DoughnutChartContainer = styled.div`
  height: 200px;
  width: 200px;
`;

export const DoughnutInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 24px;
  justify-content: flex-end;
`;

export const InfoLine = styled.div`
  margin-bottom: 16px;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const InfoText = styled.p`
  display: inline-flex;
  color: #38393d;
  font-size: 18px;
  margin: ${(props) => (props.bold ? "0 8px 0 0" : "0")};
  padding: 0;
  font-family: sans-serif;
  font-weight: ${(props) => (props.bold ? "bold" : "normal")};
`;

export const ChartReasonImg = styled.img`
  width: 30px;
  height: 30px;
  margin-right: 16px;
`;

export const SymptomContainer = styled.div`
  width: 45%;
  height: 224px;
`;

export const SymptomBody = styled.div``;

export const BarChartContainer = styled.div`
  min-width: 500px;
  max-width: 600px;
  height: 100%;
`;

export const HeaderText = styled.p`
  margin: 0 0 10px 0;
  padding: 0;
  color: #38393d;
  font-size: 18px;
`;
