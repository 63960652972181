import React from "react";
import { Switch } from "react-router-dom";

// Components
import PrivateRoute from "./PrivateRoute";
import PublicRoute from "./PublicRoute";
import CustomLoading from "components/CustomLoading";
import Fallback from "components/Fallback";

// Context
import { useGeneralProvider } from "contexts/general";

// Pages
import Home from "pages/Home";
import ClientPanel from "pages/ClientPanel";
import ScientificPanel from "pages/ScientificPanel";
import CollectPanel from "pages/CollectPanel"
import RecoverPassword from "pages/RecoverPassword";
import CompleteRegistration from "pages/CompleteRegistration";
import MyLists from "pages/MyLists";
import Customers from "pages/Customers";
import CustomerUsers from "pages/CustomerUsers";
import CustomerAnydesks from "pages/CustomerAnydesks";

export default function Routes() {
  const { loading } = useGeneralProvider();
  return (
    <>
      {loading ? <CustomLoading /> : null}
      <Switch>
        <PublicRoute exact path="/recuperar-senha">
          <RecoverPassword />
        </PublicRoute>
        <PublicRoute exact path="/completar-cadastro">
          <CompleteRegistration />
        </PublicRoute>
        <PrivateRoute exact path="/">
          <Home />
        </PrivateRoute>
        <PrivateRoute exact path="/painel-cliente">
          <ClientPanel />
        </PrivateRoute>
        <PrivateRoute exact path="/painel-cientifico">
          <ScientificPanel />
        </PrivateRoute>
        <PrivateRoute exact path="/painel-laudo">
          <CollectPanel />
        </PrivateRoute>
        <PrivateRoute exact path="/listas-salvas">
          <MyLists />
        </PrivateRoute>
        <PrivateRoute exact path="/clientes">
          <Customers />
        </PrivateRoute>
        <PrivateRoute exact path="/usuarios-cliente">
          <CustomerUsers />
        </PrivateRoute>
        <PrivateRoute exact path="/anydesks-cliente">
          <CustomerAnydesks />
        </PrivateRoute>
        <PrivateRoute path="*">
          <Fallback />
        </PrivateRoute>
      </Switch>
    </>
  );
}
