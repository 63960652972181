/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";

// Img
import MainLogo from "assets/main-logo.svg";

// Context
import { useGeneralProvider } from "contexts/general";

// Styles
import { Container, LogoImg, LoadingText } from "./styles";

function Home() {
  const { setSelectedPage, setCurrentSubtitle } = useGeneralProvider();

  useEffect(() => {
    setSelectedPage("/");
    setCurrentSubtitle("");
  }, []);

  return (
    <Container>
      <LogoImg src={MainLogo} />
      <LoadingText>Predikta Dash</LoadingText>
    </Container>
  );
}

export default Home;
