/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";

// Components
import ComplexTable from "./components/ComplexTable";
import ModalScientificPanel from "./components/ModalScientificPanel";
import TableLabel from "./components/TableLabel";

import InfoCard from "components/InfoCard";
import SelectFilter from "components/SelectFilter";
import RangeDatePicker from "components/RangeDatePicker";
import TextFilter from "components/TextFilter";
import Button from "components/Button";
import ButtonInverted from "components/ButtonInverted";
import Pagination from "components/Pagination";
import GraphicsContainer from "components/GraphicsContainer";
import ModalSaveList from "components/ModalSaveList";

// Imgs
import SaveBtnIcon from "assets/save-btn-icon.svg";

// Libs
import { toast } from "react-toastify";

// Context
import { useGeneralProvider } from "contexts/general";

// Services
import DashboardService from "services/dashboard";

// Constants
import {
  resultsENUM,
  reasonsENUM,
  initialFiltersClient,
  progressENUM,
} from "../../constants";

// Utils
import { calcPercentage, cleanSelections } from "utils";

import {
  Container,
  ContentWrapper,
  InfoCardContainer,
  FilterContainer,
  FooterContainer,
  ButtonsContainer,
} from "./styles";

function ScientificPanel() {
  const {
    setLoading,
    globalUser,
    setCurrentSubtitle,
    timerFunction,
    setSelectedPage,
    updateCharts,
    setUpdateCharts,
    isAdmin
  } = useGeneralProvider();
  const userPassService = new DashboardService(globalUser);
  const [data, setData] = useState([]);
  const [screeningStatistics, setScreeningStatistics] = useState({});
  const [normalPercentage, setNormalPercentage] = useState(null);
  const [changedPercentage, setChangedPercentage] = useState(null);

  const [companyIds, setCompanyIds] = useState([]);
  const [devices, setDevices] = useState([]);
  const [results, setResults] = useState(resultsENUM);
  const [reasons, setReasons] = useState(reasonsENUM);
  const [progress, setProgress] = useState(progressENUM);
  const [searchText, setSearchText] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [selectedItem, setSelectedItem] = useState("");

  const [changedSelected, setChangedSelected] = useState(false);
  const [canBeCleaned, setCanBeCleaned] = useState(false);
  const [currentFilters, setCurrentFilters] = useState(initialFiltersClient);
  const [resetDatePicker, setResetDatePicker] = useState(false);

  const [showModalScientific, setShowModalScientific] = useState(false);
  const [showModalSaveList, setShowModalSaveList] = useState(false);

  useEffect(() => {
    setSelectedPage("/painel-cientifico");
    setCurrentSubtitle("Científico");
  }, []);

  useEffect(() => {
    handleUpdateInfoRequests();
  }, [timerFunction]);

  useEffect(() => {
    setNormalPercentage(
      calcPercentage(
        screeningStatistics.totalScreenings,
        screeningStatistics.normalScreenings
      )
    );
    setChangedPercentage(
      calcPercentage(
        screeningStatistics.totalScreenings,
        screeningStatistics.failedScreenings
      )
    );
  }, [screeningStatistics]);

  const handleUpdateInfoRequests = (updatePage, requestWithCleanFilters) => {
    setLoading(true);
    Promise.all([
      userPassService
        .getCardsData(
          requestWithCleanFilters
            ? initialFiltersClient
            : {
                ...currentFilters,
                page: updatePage ? updatePage : currentFilters.page,
              }
        )
        .then((o) => {
          if (o.data) {
            setScreeningStatistics(o.data);
            return;
          }

          setScreeningStatistics({});
        })
        .catch(() => {
          toast.error("Falha ao buscar estatísticas de triagem");
        }),
      userPassService
        .getGridData(
          requestWithCleanFilters
            ? initialFiltersClient
            : {
                ...currentFilters,
                page: updatePage ? updatePage : currentFilters.page,
              }
        )
        .then((o) => {
          if (o.data) {
            const response = o.data;
            if (response.data && response.data.length > 0) {
              const auxData = response.data;
              setData(auxData);
            } else {
              setData([]);
            }
            if (response.totalPages) {
              setTotalPages(response.totalPages);
            } else {
              setTotalPages(1);
            }
            if (response.currentPage) {
              setCurrentPage(response.currentPage);
            } else {
              setCurrentPage(1);
            }
            return;
          }
          setData([]);
        })
        .catch((err) => {
          toast.error("Falha ao buscar registros");
        }),
      userPassService
        .getGridFilters()
        .then((o) => {
          if (o.data) {
            const response = o.data;

            if (response.devices) {
              response.devices.forEach((responseDevice) => {
                if (currentFilters.devices.includes(responseDevice.id)) {
                  responseDevice.checked = true;
                }
              });

              setDevices(response.devices);
            } else {
              setDevices([]);
            }

            if (response.companies) {
              setCompanyIds(response.companies)
            } else {
              setCompanyIds([])
            }
            
            return;
          }

          setDevices([])
          setCompanyIds([]);
        })
        .catch(() => {
          toast.error("Falha ao buscar dispositivos");
        }),
    ])
      .then(() => {})
      .catch(() => {})
      .finally(() => {
        if (!requestWithCleanFilters) {
          handleManageFiltering();
        }
        setLoading(false);
        setUpdateCharts(!updateCharts);
      });
  };

  const handleCleanFilters = () => {
    setCurrentFilters({ ...initialFiltersClient });
    setCompanyIds(cleanSelections(companyIds));
    setDevices(cleanSelections(devices));
    setResults(cleanSelections(results));
    setReasons(cleanSelections(reasons));
    setProgress(cleanSelections(progress));
    setResetDatePicker(!resetDatePicker);
    setSearchText("");
    setCanBeCleaned(false);

    handleUpdateInfoRequests(null, true);
  };

  const handleManageFiltering = () => {
    let auxCanBeCleaned = false;

    if (
      JSON.stringify(initialFiltersClient.devices) !==
      JSON.stringify(currentFilters.devices)
    ) {
      auxCanBeCleaned = true;
    }

    if (initialFiltersClient.periodFrom !== currentFilters.periodFrom) {
      auxCanBeCleaned = true;
    }

    if (initialFiltersClient.periodTo !== currentFilters.periodTo) {
      auxCanBeCleaned = true;
    }

    if (
      JSON.stringify(initialFiltersClient.results) !==
      JSON.stringify(currentFilters.results)
    ) {
      auxCanBeCleaned = true;
    }

    if (
      JSON.stringify(initialFiltersClient.reasons) !==
      JSON.stringify(currentFilters.reasons)
    ) {
      auxCanBeCleaned = true;
    }

    if (
      JSON.stringify(initialFiltersClient.progress) !==
      JSON.stringify(currentFilters.progress)
    ) {
      auxCanBeCleaned = true;
    }

    if (initialFiltersClient.textSearch !== currentFilters.textSearch) {
      auxCanBeCleaned = true;
    }

    setCanBeCleaned(auxCanBeCleaned);
  };

  return (
    <Container>
      {showModalScientific ? (
        <ModalScientificPanel
          key={showModalScientific}
          showModal={showModalScientific}
          setShowModal={setShowModalScientific}
          selectedItem={selectedItem}
          handleUpdateInfoRequests={handleUpdateInfoRequests}
        />
      ) : null}

      {showModalSaveList ? (
        <ModalSaveList
          setShowModal={setShowModalSaveList}
          currentFilters={currentFilters}
          panelType={1}
        />
      ) : null}

      <ContentWrapper>
        <InfoCardContainer>
          <InfoCard
            total={screeningStatistics?.totalScreenings || 0}
            type={"analysis"}
          />
          <InfoCard
            total={screeningStatistics?.normalScreenings || 0}
            type={"normal"}
            percentage={normalPercentage}
          />
          <InfoCard
            total={screeningStatistics?.failedScreenings || 0}
            type={"changed"}
            percentage={changedPercentage}
            changedSelected={changedSelected}
            setChangedSelected={setChangedSelected}
          />
        </InfoCardContainer>

        <GraphicsContainer
          expanded={changedSelected}
          currentFilters={currentFilters}
          changedValue={screeningStatistics?.failedScreenings || 0}
        />
        {isAdmin ? (
          <FilterContainer>
            <SelectFilter
              label={"Empresas"}
              options={companyIds}
              setOptions={(value) => {
                let toSearchArray = [];
                setCanBeCleaned(false);

                value.forEach((item) => {
                  if (item.checked) {
                    toSearchArray.push(item.id);
                  }
                });

                setCurrentFilters({
                  ...currentFilters,
                  page: 1,
                  companyIds: toSearchArray,
                });

                setCompanyIds(value);
              }}
            />
          </FilterContainer>
        ) : null }
        <FilterContainer>
          <SelectFilter
            label={"Dispositivo"}
            options={devices}
            setOptions={(value) => {
              let toSearchArray = [];
              setCanBeCleaned(false);

              value.forEach((item) => {
                if (item.checked) {
                  toSearchArray.push(item.id);
                }
              });

              setCurrentFilters({
                ...currentFilters,
                page: 1,
                devices: toSearchArray,
              });

              setDevices(value);
            }}
          />
          <RangeDatePicker
            label={"Período"}
            setSelectedDate={(value) => {
              setCanBeCleaned(false);

              setCurrentFilters({
                ...currentFilters,
                page: 1,
                periodFrom: value?.start || "",
                periodTo: value?.end || "",
              });
            }}
            resetDatePicker={resetDatePicker}
          />
          <SelectFilter
            label={"Resultado"}
            options={results}
            setOptions={(value) => {
              let toSearchArray = [];
              setCanBeCleaned(false);

              value.forEach((item) => {
                if (item.checked) {
                  toSearchArray.push(item.id);
                }
              });

              setCurrentFilters({
                ...currentFilters,
                page: 1,
                results: toSearchArray,
              });

              setResults(value);
            }}
          />
          <SelectFilter
            label={"Motivo"}
            options={reasons}
            setOptions={(value) => {
              let toSearchArray = [];
              setCanBeCleaned(false);

              value.forEach((item) => {
                if (item.checked) {
                  toSearchArray.push(item.id);
                }
              });

              setCurrentFilters({
                ...currentFilters,
                page: 1,
                reasons: toSearchArray,
              });

              setReasons(value);
            }}
          />
          <SelectFilter
            label={"Progresso"}
            options={progress}
            setOptions={(value) => {
              let toSearchArray = [];
              setCanBeCleaned(false);

              value.forEach((item) => {
                if (item.checked) {
                  toSearchArray.push(item.id);
                }
              });

              setCurrentFilters({
                ...currentFilters,
                page: 1,
                progress: toSearchArray,
              });

              setProgress(value);
            }}
          />
          <TextFilter
            searchText={searchText}
            setSearchText={(value) => {
              setCurrentFilters({
                ...currentFilters,
                page: 1,
                textSearch: value,
              });

              setSearchText(value);
            }}
            placeHolder={"Busque por Análise ou Identificador"}
          />
          {canBeCleaned ? (
            <Button label={"Limpar"} onClick={() => handleCleanFilters()} />
          ) : (
            <Button
              label={"Pesquisar"}
              onClick={() => handleUpdateInfoRequests()}
            />
          )}
        </FilterContainer>

        <TableLabel />

        <ComplexTable
          data={data}
          setData={setData}
          setSelectedItem={setSelectedItem}
          setShowModal={setShowModalScientific}
        />

        <FooterContainer>
          <ButtonsContainer>
            {data && data.length !== 0 ? (
              <ButtonInverted
                label="Salvar lista"
                icon={SaveBtnIcon}
                onClick={() => setShowModalSaveList(true)}
              />
            ) : null}
          </ButtonsContainer>

          <Pagination
            currentPage={currentPage}
            onPageChange={(page) => {
              setCurrentPage(page);
              handleUpdateInfoRequests(page);
              setCurrentFilters({
                ...currentFilters,
                page: page,
              });
            }}
            totalPages={totalPages}
          />
        </FooterContainer>
      </ContentWrapper>
    </Container>
  );
}

export default ScientificPanel;
