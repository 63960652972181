import React, { useState } from "react";

// Components
import ButtonInverted from "../ButtonInverted";
import Button from "../Button";

// Libs
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";

// Services
import DashboardService from "services/dashboard";

// Context
import { useGeneralProvider } from "contexts/general";

// Imgs
import CloseIconSvg from "assets/close-icon.svg";
import CheckSuccessIcon from "assets/check-success.svg";

import {
  BackDrop,
  Container,
  ImageWrapper,
  CloseIcon,
  Header,
  Body,
  Input,
  InputLabel,
  HeaderText,
  FooterContainer,
  SuccessImg,
} from "./styles";

function ModalSaveList({ setShowModal, currentFilters, panelType }) {
  const history = useHistory();
  const { setLoading, globalUser } = useGeneralProvider();
  const userPassService = new DashboardService(globalUser);
  const [name, setName] = useState("");
  const [isSuccess, setIsSuccess] = useState(false);

  const handleSaveList = () => {
    setLoading(true);

    userPassService
      .postDownloadRequest({ ...currentFilters, reportName: name, panelType })
      .then(() => {
        setIsSuccess(true);
      })
      .catch(() => {
        toast.error("Falha ao salvar lista");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <BackDrop>
      <Container>
        {isSuccess ? (
          <>
            <Header>
              <ImageWrapper />
              <ImageWrapper />
              <ImageWrapper onClick={() => setShowModal(false)}>
                <CloseIcon src={CloseIconSvg} />
              </ImageWrapper>
            </Header>
            <Body>
              <SuccessImg src={CheckSuccessIcon} />
              <HeaderText>Sua lista foi salva em Minhas Listas</HeaderText>
            </Body>

            <FooterContainer>
              <ButtonInverted
                label="Ver depois"
                onClick={() => setShowModal(false)}
              />
              <div style={{ width: 16 }} />
              <Button
                label="Ir para listas"
                onClick={() => history.push("/listas-salvas")}
              />
            </FooterContainer>
          </>
        ) : (
          <>
            <Header>
              <ImageWrapper />
              <HeaderText>Dê um nome a sua lista</HeaderText>
              <ImageWrapper onClick={() => setShowModal(false)}>
                <CloseIcon src={CloseIconSvg} />
              </ImageWrapper>
            </Header>
            <Body>
              <InputLabel>Nome</InputLabel>
              <Input
                maxLength={255}
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </Body>
            <FooterContainer>
              <Button
                disabled={!name}
                label="Confirmar"
                onClick={() => handleSaveList()}
              />
            </FooterContainer>
          </>
        )}
      </Container>
    </BackDrop>
  );
}

export default ModalSaveList;
