import styled from "styled-components";

const CustomDivScroll = styled.div`
  ::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }
  ::-webkit-scrollbar-button {
    width: 0px;
    height: 0px;
  }
  ::-webkit-scrollbar-thumb {
    background: #0073ab;
    border-radius: 20px;
  }
  ::-webkit-scrollbar-thumb:hover {
    background: #0073ab;
  }
  ::-webkit-scrollbar-thumb:active {
    background: #0073ab;
  }
  ::-webkit-scrollbar-track {
    background: transparent;
    border-radius: 50px;
  }
  ::-webkit-scrollbar-track:hover {
    background: transparent;
  }
  ::-webkit-scrollbar-track:active {
    background: transparent;
  }
  ::-webkit-scrollbar-corner {
    background: transparent;
  }
`;

export const Container = styled(CustomDivScroll)`
  overflow-y: auto;
  padding: 0 0 20px 20px;
`;

export const SectionTitle = styled.p`
  font-size: 18px;
  font-weight: bolder;
  margin-bottom: 34px;
  color: #1a1a1a;
  padding-left: 70px;
`;

export const InputLabelContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 40px;
`;

export const LabelText = styled.p`
  font-size: 16px;
  color: #1a1a1a;
  margin-bottom: 8px;
`;

export const Header = styled.div`
  display: flex;
  flex-direction: row;
`;

export const ArrowContainer = styled.div`
  diplay: flex;
`;

export const ActionIcon = styled.img`
  width: 20px;
  height: 20px;
  pointer-events: cursor;
  overflow-x: hidden;
  transform: rotate(180deg);
  transition: all 1s;
`;
