import React, { useState, useEffect } from "react";

// Libs
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";

// Images
import LoginBG from "assets/login-bg.svg";
import LoginLogoImg from "assets/login-logo.svg";

// Components
import Input from "components/Input";
import Button from "components/Button";

// Context
import { useGeneralProvider } from "contexts/general";

// Services
import { signIn } from "services/authentication";

// Styles
import {
  Container,
  BackContainer,
  LoginContainer,
  LoginFormContainer,
  ImageBg,
  LoginLogo,
  TitleText,
  BodyText,
  LoginBottomContainer,
  ForgetPasswordText,
  ErrorText,
} from "./styles";

function Login() {
  const {
    setLoading,
    setUser,
    signInError,
    setSignInError,
    setCompleteUserData,
  } = useGeneralProvider();
  const history = useHistory();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [emailError, setEmailError] = useState(null);
  const [passwordError, setPasswordError] = useState(null);

  useEffect(() => {
    if (signInError) {
      handleSignInError(signInError);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [signInError]);

  const validateEmail = (text) => {
    let reg = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
    if (text) {
      if (reg.test(text) === false) {
        setEmail(text);
        setEmailError("E-mail inválido.");
        return false;
      } else {
        setEmail(text);
        setEmailError(null);
      }
    } else {
      setEmail(text);
      setEmailError(null);
    }
  };

  const handleSignInError = (signInError) => {
    switch (signInError) {
      case "UserNotFoundException":
        setPasswordError(
          "Login e/ou senha incorretos, favor corrigir e tentar novamente."
        );
        break;

      case "NotAuthorizedException":
        setPasswordError(
          "Login e/ou senha incorretos, favor corrigir e tentar novamente."
        );
        break;

      default:
        toast.error("Ocorreu um erro no sistema. Por favor tente novamente.");
        break;
    }

    setSignInError(null);
  };

  const handleSubmit = () => {
    setPasswordError(null);
    setEmailError(null);

    signIn(
      {
        username: email,
        password: password,
      },
      setLoading,
      setUser,
      setSignInError,
      (receivedUser) => {
        setLoading(false);
        setCompleteUserData({ email, receivedUser });
        history.push("/completar-cadastro");
      }
    );
  };

  return (
    <Container>
      <BackContainer>
        <ImageBg alt="bg" src={LoginBG} />
      </BackContainer>
      <LoginContainer>
        <LoginFormContainer
          onSubmit={(e) => {
            e.preventDefault();
            handleSubmit();
          }}
        >
          <LoginLogo alt="logo" src={LoginLogoImg} />
          <TitleText>Predikta Dash</TitleText>
          <BodyText>Tecnologia e segurança em um clique.</BodyText>
          <Input
            label={"E-mail"}
            value={email}
            onChange={(e) => {
              setPasswordError(null);
              validateEmail(e.target.value);
            }}
          />
          {emailError ? <ErrorText>{emailError}</ErrorText> : null}
          <Input
            label={"Senha"}
            value={password}
            isPassword
            onChange={(e) => {
              setPasswordError(null);

              setPassword(e.target.value);
            }}
          />
          {passwordError ? <ErrorText>{passwordError}</ErrorText> : null}
          <LoginBottomContainer>
            <ForgetPasswordText
              onClick={(e) => {
                e.preventDefault();
                history.push("/recuperar-senha");
              }}
            >
              Esqueci minha senha
            </ForgetPasswordText>
            <Button
              onClick={(e) => {
                e.preventDefault();
                handleSubmit();
              }}
              disabled={!email || !password || emailError || passwordError}
              label={"Entrar no Painel"}
            />
          </LoginBottomContainer>
        </LoginFormContainer>
      </LoginContainer>
    </Container>
  );
}

export default Login;
