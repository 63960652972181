import HttpService from "config/http";

export default class DashboardService {
  constructor(props) {
    this.api = new HttpService(props);
  }

  // ###### USER INFO ####### //
  getUserInfo = () => {
    return this.api.get(`/getUserInfo`);
  };

  // ###### PAINEL CLIENTES ###### //
  getCardsData = (filters) => {
    return this.api.post(`/getGridScreeningStatistics`, filters);
  };

  getGridData = (filters) => {
    return this.api.post(`/getGridScreeningData`, filters);
  };

  getGridFilters = () => {
    return this.api.get(`/getGridFilters`);
  };

  getGraphicsDetails = (filters) => {
    return this.api.post(`/getFailedScreeningDetails`, filters);
  };

  getGridItemDetail = (id) => {
    return this.api.get(`/getGridScreeningDetails?screeningId=${id}`);
  };

  getDownloadRequests = (filters) => {
    return this.api.post(`/getDownloadRequests`, filters);
  };

  postDownloadRequest = (filters) => {
    return this.api.post(`/postDownloadRequest`, filters);
  };

  // ###### PAINEL CIENTÍTICO ###### //
  getScientificImageGallery = (id) => {
    return this.api.get(`/getScientificImageGallery?screeningId=${id}`);
  };

  getScientificGridScreeningDetails = (id) => {
    return this.api.get(`/getScientificGridScreeningDetails?screeningId=${id}`);
  };

  getScientificImagePalletes = () => {
    return this.api.get(`/getScientificImagePalletes`);
  };

  createScreeningGroup = (body) => {
    return this.api.post(`/createScreeningGroup`, body);
  };

  updateScreeningGroup = (body) => {
    return this.api.post(`/updateScreeningGroup`, body);
  };

  getListGroupDiagnostics = (id) => {
    return this.api.get(`/getListGroupDiagnostics?groupId=${id}`);
  };

  updateListGroupDiagnostics = (body) => {
    return this.api.post(`/updateListGroupDiagnostics`, body);
  };

  getGroupDiagnostic = (id) => {
    return this.api.get(`/getGroupDiagnostic?groupId=${id}`);
  };

  getDiagnosticReportFilters = () => {
    return this.api.get(`/getDiagnosticReportFilters`);
  };

  createDiagnosticReport = (body) => {
    return this.api.post(`/createDiagnosticReport`, body);
  };

  saveScreeningGroupAnalysedImage = (body) => {
    return this.api.post(`/saveScreeningGroupAnalysedImage`, body);
  };

  // ###### PAINEL COLETA ###### //
  getCardsFlowData = (filters) => {
    return this.api.post(`/getFlowGridScreeningStatistics`, filters);
  };

  getGridFlowData = (filters) => {
    return this.api.post(`/getFlowGridScreeningData`, filters);
  };

  getFlowGridFilters = (filters) => {
    return this.api.post(`/getFlowGridFilters`, filters);
  };

  getFlowTypes = () => {
    return this.api.get(`/getFlowTypes`);
  };

  getFlowGridScreeningDetails = (id, segments) => {
    return this.api.get(`/getFlowGridScreeningDetails?screeningId=${id}&segments=${segments}`);
  };

  getFlowImageGallery = (id) => {
    return this.api.get(`/getFlowmageGallery?flowId=${id}`);
  };

  getFlowDownloadRequests = (filters) => {
    return this.api.post(`/getFlowDownloadRequests`, filters);
  };

  postFlowDownloadRequest = (filters) => {
    return this.api.post(`/postFlowDownloadRequest`, filters);
  };

  // ###### ADMINISTRAR CLIENTES ###### //
  getCompanies = (filters) => {
    return this.api.post(`/getCompanies`, filters);
  };

  getAllCompanies = () => {
    return this.api.get('/getAllCompanies');
  };

  getCompanyAssets = (companyId) => {
    return this.api.get(`/getCompanyAssets?companyId=${companyId}`);
  };

  createCompany = (body) => {
    return this.api.post(`/createCompany`, body);
  };

  updateCompany = (body) => {
    return this.api.post(`/updateCompany`, body);
  };

  updateCompanyStatus = (body) => {
    return this.api.post(`/updateCompanyStatus`, body);
  };

  getCompanyUsers = (body) => {
    return this.api.post(`/getCompanyUsers`, body);
  };

  createCompanyUser = (body) => {
    return this.api.post(`/createCompanyUser`, body);
  };

  updateCompanyUser = (body) => {
    return this.api.post(`/updateCompanyUser`, body);
  };

  updateCompanyUserStatus = (body) => {
    return this.api.post(`/updateCompanyUserStatus`, body);
  };

  updateCompanyUserPassword = (body) => {
    return this.api.post(`/updateCompanyUserPassword`, body);
  };  

  getCompanyAnydesks = (body) => {
    return this.api.post(`/getCompanyAnydesks`, body);
  };

  createCompanyAnydesk = (body) => {
    return this.api.post(`/createCompanyAnydesk`, body);
  };

  updateCompanyAnydesk = (body) => {
    return this.api.post(`/updateCompanyAnydesk`, body);
  };

  updateCompanyAnydeskStatus = (body) => {
    return this.api.post(`/updateCompanyAnydeskStatus`, body);
  };

  updateCompanyAnydeskCompany = (body) => {
    return this.api.post(`/updateCompanyAnydeskCompany`, body);
  };

  // ###### GENERAL ###### //
  getImageBase64ByUrl = (url) => {
    return this.api.get(`/getImageBase64ByUrl?url=${url}`);
  };

  getManipulatedImageBase64 = (imageUrl, palleteName, windowingMin, windowingMax) => {
    return this.api.getImage(`/getManipulatedImageBase64?imageUrl=${imageUrl}&palleteName=${palleteName}&windowingMin=${windowingMin}&windowingMax=${windowingMax}`);
  };

  getManipulatedDetailedImageBase64 = (imageUrl, palleteName, windowingMin, windowingMax) => {
    return this.api.get(`/getManipulatedDetailedImageBase64?imageUrl=${imageUrl}&palleteName=${palleteName}&windowingMin=${windowingMin}&windowingMax=${windowingMax}`);
  };
}
