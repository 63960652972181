import styled from "styled-components";

export const SelectContainer = styled.div`
  width: auto;
  margin-bottom: 4px;
`;

export const ButtonContainer = styled.div`
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 8px 0 8px;
  background-color: ${(props) => (props.isOnFocus ? "#dddddd" : "#f9f9f9")};
  border-radius: 4px;
  cursor: pointer;
  margin-right: 8px;
  user-select: none;
  font-size: 16px;
  font-weight: normal;
  color: #525252;

  :hover {
    background-color: #dddddd;
  }
`;

export const ArrowIcon = styled.img`
  width: 24px;
  height: 24px;
  user-select: none;
  pointer-events: none;
  transform: ${(props) =>
    props.isOnFocus ? "rotate(180deg)" : "rotate(0deg)"};
  transition: all 0.3s;
`;

export const OptionsContainer = styled.div`
  position: absolute;
  margin-top: 48px;
  display: flex;
  flex-direction: column;
  border-radius: 4px;
  background-color: #fefefe;
  border: 1px solid #f9f9f9;
  padding: 8px 0 8px 0;
  width: auto;
  max-height: 300px;
  overflow: auto;
  top: ${(props) => props.top && `${props.top}px`};
  left: ${(props) => props.left && `${props.left}px`};
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1);
  z-index: 999;
`;

export const OptionItemContainer = styled.div`
  user-select: none;
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  padding: 0 8px 0 8px;

  :hover {
    background-color: #f9f9f9;
  }
`;

export const CheckboxIcon = styled.img`
  width: 16px;
  height: 16px;
  user-select: none;
  pointer-events: none;
`;

export const OptionText = styled.p`
  margin: 2px 0 0 0;
  padding: 0;
  color: #38393d;
  font-size: 16px;
  margin-left: 8px;
`;
