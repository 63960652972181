import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 26px;
`;

export const SwitchContainer = styled.div`
  width: 40px;
  min-width: 40px;
  height: 24px;
  border-radius: 12px;
  background-color: ${({ actived }) => (actived ? "#0073AB" : "#A1A1A1")};
  display: flex;
  align-items: center;
  padding: 0 4px 0 4px;
  cursor: pointer;

  span {
    transition: linear 0.2s;
    margin-left: ${({ actived }) => (actived ? "16px" : "0")};
    background-color: #fefefe;
    transition: all 0.2s;
    height: 16px;
    width: 16px;
    border-radius: 50%;
  }
`;

export const LabelText = styled.p`
  margin: 0;
  padding: 0;
  color: #38393d;
  font-size: 14px;
  margin-right: 16px;
`;
