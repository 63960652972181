/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";

// Img
import MainLogo from "assets/main-logo.svg";

// Styles
import { Container, LogoImg, LoadingText } from "./styles";

function Fallback({ customText }) {
  return (
    <Container>
      <LogoImg src={MainLogo} />
      <LoadingText>
        {customText ? customText : "Página não encontrada."}
      </LoadingText>
    </Container>
  );
}

export default Fallback;
