import React from "react";

import { Container, Text } from "./styles";

function StatusText({ status }) {
  return (
    <Container
      color={status === 1 ? "#C73734" : "#40A99E"}
      backColor={status === 1 ? "#F9EBEB" : "#ECF6F5"}
    >
      <Text>{status === 1 ? "Inativo" : "Ativo"}</Text>
    </Container>
  );
}

export default StatusText;
