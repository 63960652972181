import React from "react";

import { Container, Text } from "./styles";

function StatusText({ status }) {
  return (
    <Container
      color={status ? "#40A99E" : "#C73734"}
      backColor={status ? "#ECF6F5" : "#F9EBEB"}
    >
      <Text>{status ? "Ativo" : "Inativo"}</Text>
    </Container>
  );
}

export default StatusText;
