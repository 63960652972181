/* eslint-disable react-hooks/exhaustive-deps */
import React, { useRef, useState, useEffect } from "react";

// Libs
import { Image as KonvaImage, Transformer } from "react-konva";

// Utils
import { applyGaussianBlur } from "utils";

// Constants
import {
  defaultVirtualCanvasWidth,
  defaultVirtualCanvasHeight,
  minTemperatureCelsius,
  maxTemperatureCapCelsius,
  paletteStageWidth,
  tempStageWidth
} from "../../constants";

function ImageTransformer({
  image,
  temperatures,
  isSelected,
  onSelect,
  stageImageRef,
  deltas,
  updateDeltaTemperatures,
  formRect,
  transformerRef,
  canvasDownloadPosition,
  setCanvasDownloadPosition,
  onImageDrag
}) {
  const [mappingIsDone, setMappingIsDone] = useState(false);
  const [imageInitialPosition, setImageInitialPosition] = useState({
    x: 0,
    y: 0,
  });

  const imageRef = useRef();

  useEffect(() => {
    if (image) {
      if (image.isAnalysed) {
        const imageWidth = imageRef.current.width();
        const imageHeight = imageRef.current.height();
        const baseScaleFactor = 0.5;

        imageRef.current.crop({
          x: 0,
          y: 0,
          width: imageWidth - paletteStageWidth - tempStageWidth,
          height: imageHeight
        });
        imageRef.current.setWidth(imageWidth * baseScaleFactor);
        imageRef.current.setHeight(imageHeight * baseScaleFactor);
      }
      imageRef.current.cache();
      handleImageInitialPosition();
    }
  }, [image]);

  useEffect(() => {
    if (isSelected && transformerRef.current) {
      transformerRef.current.nodes([imageRef.current]);
      transformerRef.current.getLayer().batchDraw();
    }
  }, [isSelected]);

  // "Escuta" o movimento da área 1
  useEffect(() => {
    handleCurrentAreaCalc(deltas, formRect);
  }, [deltas, formRect]);

  const handleCurrentAreaCalc = (
    deltas,
    formRect
  ) => {
    let hasChanges = false;

    const updatedDeltas = deltas.map((delta) => {
      if (
        delta.firstAreaProps &&
        handleHaveIntersection(
          handleImageProps(imageRef.current),
          delta.firstAreaProps
        )
      ) {
        const temperatureValues = handleCaptureAreaOfImageData(
          delta.firstAreaProps,
          formRect,
        )
      
        if (
          delta.firstAreaMaxTemperature != temperatureValues.areaMaxTemperature ||
          delta.firstAreaMinTemperature != temperatureValues.areaMinTemperature ||
          delta.firstAreaAvgTemperature != temperatureValues.areaAvgTemperature
        ) {
          delta.firstAreaMaxTemperature = temperatureValues.areaMaxTemperature
          delta.firstAreaMinTemperature = temperatureValues.areaMinTemperature
          delta.firstAreaAvgTemperature = temperatureValues.areaAvgTemperature
          hasChanges = true;
        }
      }
      
      if (
        delta.secondAreaProps &&
        handleHaveIntersection(
          handleImageProps(imageRef.current),
          delta.secondAreaProps
        )
      ) {
        const temperatureValues = handleCaptureAreaOfImageData(
          delta.secondAreaProps,
          formRect
        )
      
        if (
          delta.secondAreaMaxTemperature != temperatureValues.areaMaxTemperature ||
          delta.secondAreaMinTemperature != temperatureValues.areaMinTemperature ||
          delta.secondAreaAvgTemperature != temperatureValues.areaAvgTemperature
        ) {
          delta.secondAreaMaxTemperature = temperatureValues.areaMaxTemperature
          delta.secondAreaMinTemperature = temperatureValues.areaMinTemperature
          delta.secondAreaAvgTemperature =  temperatureValues.areaAvgTemperature
          hasChanges = true;
        }
      }
      
      return delta;
    })

    if (hasChanges) {
      updateDeltaTemperatures(updatedDeltas)
    }
    
  };

  // Extrai propriedades pertinentes da imagem atual
  const handleImageProps = (currentImage) => {
    const x = currentImage?.x();
    const y = currentImage?.y();
    const width = currentImage?.width() * currentImage?.scaleX();
    const height = currentImage?.height() * currentImage?.scaleY();

    return { x, y, width, height };
  };

  // Verifica se uma determinada área de captura está sobre a imagem atual
  const handleHaveIntersection = (r1, r2) => {
    return !(
      r2.x > r1.x + r1.width ||
      r2.x + r2.width < r1.x ||
      r2.y > r1.y + r1.height ||
      r2.y + r2.height < r1.y
    );
  };

  // Adiciona a imagem ao centro do canvas
  const handleImageInitialPosition = () => {
    const imageDefaultWidth = imageRef.current.width();
    const imageDefaultHeight = imageRef.current.height();

    const xPosition = defaultVirtualCanvasWidth() / 2 - imageDefaultWidth / 2;
    const yPosition = defaultVirtualCanvasHeight() / 2 - imageDefaultHeight / 2;

    setImageInitialPosition({ x: xPosition, y: yPosition });

    const currentStage = stageImageRef.current;
    const currentImage = imageRef.current;

    const box = currentImage.getClientRect();    
    const newAbsPos = getNewImageAbsolutePosition(currentStage, currentImage);

    setCanvasDownloadPosition((prevState) => {
      const indexToUpdate = canvasDownloadPosition.findIndex(item => item.imageKey === currentImage.id());

      if (indexToUpdate > -1) {
        prevState[indexToUpdate].minHeight = newAbsPos.y + box.height;
        prevState[indexToUpdate].minY = newAbsPos.y;
        return prevState;
      }
      else {
        return [...prevState, { 
          imageKey: currentImage.id(), 
          minHeight: newAbsPos.y + box.height, 
          minY: newAbsPos.y 
        }];
      }
    });
  };


  const getNewImageAbsolutePosition = (currentStage, currentImage) => {
    const box = currentImage.getClientRect();
    const absPos = currentImage.getAbsolutePosition();
    const offsetX = box.x - absPos.x;
    const offsetY = box.y - absPos.y;

    const newAbsPos = { ...absPos };
    if (box.x < 0) {
      newAbsPos.x = -offsetX;
    }
    if (box.y < 0) {
      newAbsPos.y = -offsetY;
    }
    if (box.x + box.width > currentStage.width()) {
      newAbsPos.x = currentStage.width() - box.width - offsetX;
    }
    if (box.y + box.height > currentStage.height()) {
      newAbsPos.y = currentStage.height() - box.height - offsetY;
    }
    return newAbsPos;
  };

  const handleTransformEnd = (stageRef, itemRef) => {
    const currentStage = stageRef.current;
    const currentImage = itemRef.current;

    const box = currentImage.getClientRect();    
    const newAbsPos = getNewImageAbsolutePosition(currentStage, currentImage);

    setCanvasDownloadPosition((prevState) => {
      const indexToUpdate = canvasDownloadPosition.findIndex(item => item.imageKey === currentImage.id());

      if (indexToUpdate > -1) {
        prevState[indexToUpdate].minHeight = newAbsPos.y + box.height;
        prevState[indexToUpdate].minY = newAbsPos.y;
        return prevState;
      }
      else {
        return [...prevState, { 
          imageKey: currentImage.id(), 
          minHeight: newAbsPos.y + box.height, 
          minY: newAbsPos.y 
        }];
      }
    });
  };

  // Verifica se uma imagem está arrastada para fora do canvas
  const handleDragOutOfCanvas = (stageRef, itemRef) => {
    const currentStage = stageRef.current;
    const currentImage = itemRef.current;
    
    const newAbsPos = getNewImageAbsolutePosition(currentStage, currentImage);
    currentImage.setAbsolutePosition(newAbsPos);

    const box = currentImage.getClientRect();
    
    setCanvasDownloadPosition((prevState) => {
      const indexToUpdate = canvasDownloadPosition.findIndex(item => item.imageKey === currentImage.id());

      if (indexToUpdate > -1) {
        prevState[indexToUpdate].minHeight = newAbsPos.y + box.height;
        prevState[indexToUpdate].minY = newAbsPos.y;
        return prevState;
      }
      else {
        return [...prevState, { 
          imageKey: currentImage.id(), 
          minHeight: newAbsPos.y + box.height, 
          minY: newAbsPos.y 
        }];
      }
    });
  };

  const extractArea = (matrix, formRect, areaStartX, areaStartY, areaWidth, areaHeight) => {
    const extractedValues = [];

    let x = parseInt(areaStartX)
    let y = parseInt(areaStartY)

    let width = parseInt(areaWidth)
    let height = parseInt(areaHeight)

    if (formRect == 'square') {
      if (matrix != undefined) {
        for (let i = y; i < y + height && i < matrix.length; i++) {
          let positionValue = matrix[i];
          if (positionValue != undefined) {
            for (let j = x; j < x + width && j < positionValue.length; j++) {
                extractedValues.push(positionValue[j]);
            }
          }
        }
      }
    } else {
      if (matrix != undefined) {
        let radius = parseInt(width / 2);
        let circularCenterX = x + radius;
        let circularCenterY = y + radius;
        let values = getCircularValues(matrix, circularCenterX, circularCenterY, radius);
        extractedValues.push(...values);
      }
    }

    return extractedValues;
  };

  const scaleMatrix = (matrix, scaleX, scaleY) => {
      const originalHeight = matrix.length;
      const originalWidth = matrix[0].length;

      const scaledHeight = Math.floor(originalHeight * scaleY);
      const scaledWidth = Math.floor(originalWidth * scaleX);

      const scaledMatrix = Array.from({ length: scaledHeight }, () => Array(scaledWidth).fill(null));

      for (let i = 0; i < scaledHeight; i++) {
          for (let j = 0; j < scaledWidth; j++) {
              // Find the corresponding position in the original matrix
              const originalY = Math.floor(i / scaleY);
              const originalX = Math.floor(j / scaleX);

              // Copy the value from the original matrix to the scaled matrix
              scaledMatrix[i][j] = matrix[originalY][originalX];
          }
      }

      return scaledMatrix;
  }

  const getCircularValues = (matrix, cx, cy, radius) => {
    let values = [];
    
    const height = matrix.length;
    const width = matrix[0].length;

    for (let i = Math.max(0, cy - radius); i <= Math.min(height - 1, cy + radius); i++) {
        for (let j = Math.max(0, cx - radius); j <= Math.min(width - 1, cx + radius); j++) {
            if ((i - cy) ** 2 + (j - cx) ** 2 <= radius ** 2) {
              if (matrix[i] != undefined && matrix[i][j] != undefined) {
                values.push(matrix[i][j]);
              }
            }
        }
    }
    
    return values;
  }

  // Captura uma determinada porção da imagem com base
  // na área de captura, reverte para a escala original e
  // extrai a temperatura máxima dessa região
  const handleCaptureAreaOfImageData = (
    currentAreaProps,
    formRect
  ) => {
    const currentImage = imageRef.current;
    const imageX = currentImage.x();
    const imageY = currentImage.y();
    const imageWidth = currentImage.width();
    const imageHeight = currentImage.height();
    const imageScaleX = currentImage.scaleX();
    const imageScaleY = currentImage.scaleY();

    const scaleResetX = imageWidth / (imageWidth * imageScaleX);
    const scaleResetY = imageHeight / (imageHeight * imageScaleY);

    // Dados para a área de captura da imagem
    const captureAreaStartX = (currentAreaProps?.x - imageX) * scaleResetX;
    const captureAreaStartY = (currentAreaProps?.y - imageY) * scaleResetY;

    const captureAreaWidth = currentAreaProps?.width * scaleResetX;
    const captureAreaHeight = currentAreaProps?.height * scaleResetY;

    // Cria um canva virtual que representa a imagem original
    const canvaElement = document.createElement("canvas");
    canvaElement.style.display = "none";
    canvaElement.width = imageWidth;
    canvaElement.height = imageHeight;
    document.body.appendChild(canvaElement);

    const ctx = canvaElement.getContext("2d");
    ctx.drawImage(currentImage.image(), 0, 0, imageWidth, imageHeight);

    document.body.removeChild(canvaElement);

    // const scaledTemperatures = scaleMatrix(temperatures.values, imageScaleX, imageScaleY)
    const scaledTemperatures = temperatures.values

    const extractedArea = extractArea(scaledTemperatures, formRect, captureAreaStartX, captureAreaStartY, captureAreaWidth, captureAreaHeight)

    // Calcula temperatura da região capturada
    const areaTemperatureArray = [];

    for (let i = 0; i < extractedArea.length; i++) {
      const temp = extractedArea[i];

      if (temp >= minTemperatureCelsius && temp <= maxTemperatureCapCelsius) {
        areaTemperatureArray.push(temp);
      }
    }

    let areaMaxTemperature = 0;
    let areaMinTemperature = 0;
    let areaAvgTemperature = 0;
    
    if (areaTemperatureArray.length > 0) {
      areaMaxTemperature = Math.max(...areaTemperatureArray);
      areaMinTemperature = Math.min(...areaTemperatureArray);
      const sum = areaTemperatureArray.reduce((a, b) => a + b, 0);
      areaAvgTemperature = (sum / areaTemperatureArray.length) || 0;
    }
    
    return {areaMaxTemperature, areaMinTemperature, areaAvgTemperature}
  };

  return (
    <>
      <KonvaImage
        {...imageInitialPosition}
        ref={imageRef}
        onClick={onSelect}
        onTap={onSelect}
        image={image.imageData}
        draggable
        onDragMove={() => handleDragOutOfCanvas(stageImageRef, imageRef)}
        onDragStart={onImageDrag}
        onTransformEnd={() => handleTransformEnd(stageImageRef, imageRef)}
        id={image.imageKey}
        name="image"
        lineJoin="round"
      />    
      <Transformer
        rotateEnabled={true}
        ref={transformerRef}
        anchorSize={12}
        rotationSnaps={[0, 90, 180, 270]}
      />      
    </>
  );
}

export default ImageTransformer;
