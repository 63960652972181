import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding-top: 40px;
`;

export const ContentWrapper = styled.div`
  width: calc(100% - 200px);
`;

export const FilterContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-bottom: 20px;
`;

export const FooterContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 32px 0 32px 0;
`;

export const TabContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 40px;
  border-bottom: 1px solid #d9d9d9;
  width: 100%;
`;
