import React, { useState } from "react";

// Imgs
import TrashIconSvg from "assets/trash-icon.svg";
import EditIconSvg from "assets/edit-icon.svg";
import TransferIconSvg from "assets/transfer-icon.svg";
import NotFound from "assets/not-found.svg";

// Components
import CustomTooltip from "components/CustomTooltip";
import HeaderSort from "./components/HeaderSort";
import StatusText from "./components/StatusText";
import ModalConfirmUpdate from "./components/ModalConfirmUpdate";

import {
  TableContainer,
  THead,
  TBody,
  TR,
  TH,
  TD,
  IconWrapper,
  ActionIcon,
  EmptyListContainer,
  NotFoundImg,
  EmptyListText,
  ActionsContainer,
} from "./styles";

function ComplexTable({
  data,
  setData,
  companyId,
  selectedItem,
  setSelectedItem,
  setShowEditCustomerAnydesk,
  setShowTransferCustomerAnydesk,
  handleCleanFilters,
}) {
  const [showModalAnydeskStatusConfirm, setshowModalAnydeskStatusConfirm] = useState(false);
  const [isSorted, setIsSorted] = useState(false);

  const handleSortContent = (objKey) => {
    const sortedData = isSorted
      ? data.sort((a, b) => {
          if (a[objKey] < b[objKey]) return -1;
          if (a[objKey] > b[objKey]) return 1;
          return 0;
        })
      : data.sort((a, b) => {
          if (a[objKey] > b[objKey]) return -1;
          if (a[objKey] < b[objKey]) return 1;
          return 0;
        });

    setIsSorted(!isSorted);
    setData([...sortedData]);
  };

  const handleInactivate = (item) => {
    setSelectedItem(item);
    setshowModalAnydeskStatusConfirm(true);
  };

  const handleEdit = (item) => {
    setSelectedItem(item);
    setShowEditCustomerAnydesk(true);
  };

  const handleTransfer = (item) => {
    setSelectedItem(item);
    setShowTransferCustomerAnydesk(true);
  };

  return (
    <>
      {showModalAnydeskStatusConfirm ? (
        <ModalConfirmUpdate
          setShowModal={setshowModalAnydeskStatusConfirm}
          companyId={companyId}
          anydeskId={selectedItem.anydeskId}
          handleCleanFilters={handleCleanFilters}
        />
      ) : null}

      <TableContainer>
        {/* Header */}
        <THead>
          <TR>
            <HeaderSort
              label="Nome"
              sortContent={() => handleSortContent("anydeskName")}
            />
            <HeaderSort
              label="Serial"
              sortContent={() => handleSortContent("anydeskSerial")}
            />
            <HeaderSort
              label="Situação"
              sortContent={() => handleSortContent("status")}
            />
            <TH>Ação</TH>
          </TR>
        </THead>

        {/* Body */}
        <TBody>
          {data && data.length !== 0 ? (
            data.map((item, index) => (
              <React.Fragment key={`item-${index}`}>
                <TR>
                  <TD>{item.anydeskName || "-"}</TD>
                  <TD>{item.anydeskSerial || "-"}</TD>
                  <TD>
                    <StatusText status={item.status} />
                  </TD>
                  <TD>
                    <ActionsContainer>
                      <CustomTooltip title="Inativar">
                        <IconWrapper
                          disabled={!item.status}
                          onClick={() => {
                            if (item.status) {
                              handleInactivate(item);
                            }
                          }}
                        >
                          <ActionIcon src={TrashIconSvg} />
                        </IconWrapper>
                      </CustomTooltip>

                      <CustomTooltip title="Editar">
                        <IconWrapper
                          onClick={() => {
                            handleEdit(item);
                          }}
                        >
                          <ActionIcon src={EditIconSvg} />
                        </IconWrapper>
                      </CustomTooltip>

                      <CustomTooltip title="Transferir Anydesk">
                        <IconWrapper
                          onClick={() => {
                            handleTransfer(item);
                          }}
                        >
                          <ActionIcon src={TransferIconSvg} />
                        </IconWrapper>
                      </CustomTooltip>
                    </ActionsContainer>
                  </TD>
                </TR>
              </React.Fragment>
            ))
          ) : (
            <tr>
              <td colSpan={8}>
                <EmptyListContainer>
                  <NotFoundImg src={NotFound} />
                  <EmptyListText>
                    Não encontramos resultado para sua busca.
                  </EmptyListText>
                </EmptyListContainer>
              </td>
            </tr>
          )}
        </TBody>
      </TableContainer>
    </>
  );
}

export default ComplexTable;
