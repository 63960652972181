import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  width: 38px;
  height: 38px;
  justify-content: center;
  align-items: center;
  border-radius: 6px;
  background-color: ${({ disabled }) => (disabled ? "#F1F1F1" : "#0073ab")};
  padding: 6px;
  margin-left: 12px;
  pointer-events: ${({ disabled }) => (disabled ? "none" : "auto")};

  :hover {
    cursor: ${({ disabled }) => (disabled ? "unset" : "pointer")};
    opacity: ${({ disabled }) => (disabled ? 1 : 0.8)};
  }

  svg {
    fill: #ffffff;
    width: 20px;
    height: 20px;
  }
`;
