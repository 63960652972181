import React from "react";

import { Container, Text } from "./styles";

function ResultText({ result }) {
  return (
    <Container
      color={result === 0 ? "#40A99E" : result === 1 ? "#C73734" : "none"}
      backColor={result === 0 ? "#ECF6F5" : result === 1 ? "#F9EBEB" : "none"}
    >
      <Text>{result === 0 ? "Normal" : result === 1 ? "Alterado" : ""}</Text>
    </Container>
  );
}

export default ResultText;
