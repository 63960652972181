import React from "react";

// Imgs
import CloseIconSvg from "assets/close-icon.svg";

import {
  BackDrop,
  Container,
  Header,
  HeaderTitle,
  ImageWrapper,
  CloseIcon,
  Body,
  GenericHeaderMargin,
} from "./styles";

function GenericModal({
  title,
  children,
  setShowModal,
  fullScreen = true,
  noBodyPadding,
  modalValue
}) {
  return (
    <BackDrop>
      <Container fullScreen={fullScreen}>
        <Header>
          <GenericHeaderMargin />
          <HeaderTitle>{title ? title : ""}</HeaderTitle>
            <ImageWrapper onClick={() => {
              if (modalValue) {
                setShowModal({show: false, value: ''})
              } else {
                setShowModal(false)
              }
              }
            }>
              <CloseIcon src={CloseIconSvg} />
            </ImageWrapper>
        </Header>

        <Body modalValue={modalValue} noBodyPadding={noBodyPadding}>{children}</Body>
      </Container>
    </BackDrop>
  );
}

export default GenericModal;
