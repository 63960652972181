import React from "react";

import { Container, CustomInputDatePicker } from "./styles";

function CustomDatePicker({ selectedDate, setSelectedDate }) {
  return (
    <Container>
      <CustomInputDatePicker
        onChange={(date) => setSelectedDate(date)}
        value={selectedDate}
        calendarIcon={null}
        clearIcon={null}
        format={"dd/MM/yyyy"}
        locale={"pt-BR"}
        maxDate={new Date()}
      />
    </Container>
  );
}

export default CustomDatePicker;
