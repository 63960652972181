/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";

// Libs
import { toast } from "react-toastify";

// Context
import { useGeneralProvider } from "contexts/general";

// Services
import DashboardService from "services/dashboard";

// Components
import ComplexTableListScreenings from "../../components/ComplexTableListScreenings";
import SelectFilter from "components/SelectFilter";
import RangeDatePicker from "components/RangeDatePicker";
import TextFilter from "components/TextFilter";
import Button from "components/Button";
import Pagination from "components/Pagination";

// Constants
import {
  statusMyListsENUM,
  panelTypesMyListsENUM,
  initialFiltersMyListsScreenings,
} from "../../../../constants";

// Utils
import { cleanSelections } from "utils";

import { FilterContainer, FooterContainer } from "../../styles";

function ViewScreenings() {
  const { setLoading, globalUser, timerFunction } = useGeneralProvider();
  const userPassService = new DashboardService(globalUser);
  const [resetDatePicker, setResetDatePicker] = useState(false);
  const [data, setData] = useState([]);
  const [totalPages, setTotalPages] = useState(1);

  const [status, setStatus] = useState(statusMyListsENUM);
  const [panelTypes, setPanelTypes] = useState(panelTypesMyListsENUM);
  const [searchText, setSearchText] = useState("");
  const [currentPage, setCurrentPage] = useState(1);

  const [canBeCleaned, setCanBeCleaned] = useState(false);
  const [currentFilters, setCurrentFilters] = useState(
    initialFiltersMyListsScreenings
  );

  useEffect(() => {
    getListData();
  }, [timerFunction]);

  const getListData = (updatePage, requestWithCleanFilters) => {
    setLoading(true);
    userPassService
      .getDownloadRequests(
        requestWithCleanFilters
          ? initialFiltersMyListsScreenings
          : {
              ...currentFilters,
              page: updatePage ? updatePage : currentFilters.page,
            }
      )
      .then((o) => {
        if (o.data) {
          const response = o.data;
          if (response.downloads) {
            const auxData = response.downloads;

            setData(auxData);
          } else {
            setData([]);
          }
          if (response.totalPages) {
            setTotalPages(response.totalPages);
          } else {
            setTotalPages(1);
          }
          if (response.currentPage) {
            setCurrentPage(response.currentPage);
          } else {
            setCurrentPage(1);
          }
          return;
        }
        setData([]);
      })
      .catch(() => {
        toast.error("Falha ao buscar listas");
        setData([]);
      })
      .finally(() => {
        if (!requestWithCleanFilters) {
          handleManageFiltering();
        }
        setLoading(false);
      });
  };

  const handleManageFiltering = () => {
    let auxCanBeCleaned = false;

    if (
      initialFiltersMyListsScreenings.periodFrom !== currentFilters.periodFrom
    ) {
      auxCanBeCleaned = true;
    }

    if (initialFiltersMyListsScreenings.periodTo !== currentFilters.periodTo) {
      auxCanBeCleaned = true;
    }

    if (
      JSON.stringify(initialFiltersMyListsScreenings.status) !==
      JSON.stringify(currentFilters.status)
    ) {
      auxCanBeCleaned = true;
    }

    if (
      JSON.stringify(initialFiltersMyListsScreenings.panelTypes) !==
      JSON.stringify(currentFilters.panelTypes)
    ) {
      auxCanBeCleaned = true;
    }

    if (
      initialFiltersMyListsScreenings.codeOrName !== currentFilters.codeOrName
    ) {
      auxCanBeCleaned = true;
    }

    setCanBeCleaned(auxCanBeCleaned);
  };

  const handleCleanFilters = () => {
    setCurrentFilters({ ...initialFiltersMyListsScreenings });
    setResetDatePicker(!resetDatePicker);
    setStatus(cleanSelections(status));
    setPanelTypes(cleanSelections(panelTypes));
    setSearchText("");
    setCanBeCleaned(false);

    getListData(null, true);
  };

  return (
    <>
      <FilterContainer>
        <RangeDatePicker
          label={"Período"}
          setSelectedDate={(value) => {
            setCanBeCleaned(false);

            setCurrentFilters({
              ...currentFilters,
              page: 1,
              periodFrom: value?.start || "",
              periodTo: value?.end || "",
            });
          }}
          resetDatePicker={resetDatePicker}
        />

        <SelectFilter
          label={"Status"}
          options={status}
          setOptions={(value) => {
            let toSearchArray = [];
            setCanBeCleaned(false);

            value.forEach((item) => {
              if (item.checked) {
                toSearchArray.push(item.id);
              }
            });

            setCurrentFilters({
              ...currentFilters,
              page: 1,
              status: toSearchArray,
            });

            setStatus(value);
          }}
        />

        <SelectFilter
          label={"Tipo de Painel"}
          options={panelTypes}
          setOptions={(value) => {
            let toSearchArray = [];
            setCanBeCleaned(false);

            value.forEach((item) => {
              if (item.checked) {
                toSearchArray.push(item.id);
              }
            });

            setCurrentFilters({
              ...currentFilters,
              page: 1,
              panelTypes: toSearchArray,
            });

            setPanelTypes(value);
          }}
        />

        <TextFilter
          placeHolder={"Busque por código ou nome"}
          searchText={searchText}
          setSearchText={(value) => {
            setCurrentFilters({
              ...currentFilters,
              page: 1,
              codeOrName: value,
            });

            setSearchText(value);
          }}
        />
        {canBeCleaned ? (
          <Button label={"Limpar"} onClick={() => handleCleanFilters()} />
        ) : (
          <Button label={"Pesquisar"} onClick={() => getListData()} />
        )}
      </FilterContainer>

      <ComplexTableListScreenings data={data} setData={setData} />

      <FooterContainer>
        <Pagination
          currentPage={currentPage}
          onPageChange={(page) => {
            setCurrentPage(page);
            getListData(page);
            setCurrentFilters({
              ...currentFilters,
              page: page,
            });
          }}
          totalPages={totalPages}
        />
      </FooterContainer>
    </>
  );
}

export default ViewScreenings;
