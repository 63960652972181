import styled from "styled-components";

export const TableContainer = styled.table`
  width: 100%;
  font-size: 12px;
`;

export const THead = styled.thead``;

export const TBody = styled.tbody``;

export const TR = styled.tr`
  height: 44px;
  width: 100%;
  background-color: ${({ color }) => color};
`;

export const TH = styled.th`
  background-color: #f9f9f9;
  padding-left: 8px;
  color: #000000;
  font-weight: bold;
`;

export const TD = styled.td`
  border-bottom: solid 1px #ebebeb;
  padding-left: 8px;
  color: #38393d;
`;

export const TDButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;

  div:nth-child(1) {
    margin-right: 10px;
  }
`;

export const IconWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
  cursor: pointer;

  :hover {
    opacity: 0.7;
  }
`;

export const ActionIcon = styled.img`
  width: 24px;
  height: 24px;
  user-select: none;
  pointer-events: none;
`;

export const EmptyListContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding-top: 50px;
`;

export const NotFoundImg = styled.img`
  width: 65px;
  height: 65px;
  margin-bottom: 18px;
`;

export const EmptyListText = styled.p`
  margin: 0;
  padding: 0;
  color: #000000;
  font-size: 16px;
`;
