import styled from "styled-components";
import ReactSlider from "react-slider";

export const Container = styled.div`
  color: #fff;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const Text = styled.p`
  margin: 0;
  padding: 0;
  font-size: 12px;
`;

export const GalleryImage = styled.img`
  border: 1px solid #e1e1e1;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
`;

export const ContainerCarousel = styled.div`
  display: flex;
  max-width: 350px;
`;

export const ContainerPlay = styled.div`
  align-items: center;
  display: flex;
  width: 350px;  
  border: 1px solid #e1e1e1;
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
  flex-direction: row;
`;

export const StyledSlider = styled(ReactSlider)`
  width: 100%;
  height: 25px;
  display: flex;
  align-items: center;
  margin-left: 15px;
  margin-right: 10px;
`;

const StyledThumb = styled.div`
  height: 15px;
  line-height: 20px;
  width: 15px;
  text-align: center;
  background-color: #000;
  color: #fff;
  border-radius: 999px;
  cursor: grab;
`;

export const Thumb = (props, state) => (
  <StyledThumb {...props} />
);

const StyledTrack = styled.div`
  height: 5px;
  display: flex;
  align-items: center;
  background: ${(props) =>
    props.index === 0 ? "#0073AB" : props.index === 1 ? "#ddd" : "#ddd"};
  border-radius: 999px;
    `;

export const Track = (props, state) => (
  <StyledTrack {...props} index={state.index} />
);

export const ContainerAddPhoto = styled.div`
  margin-right: 5px;

  :hover {
    opacity: 0.7;
  }
`;

export const ContainerPlayPause = styled.div`
  :hover {
    opacity: 0.7;
  }
`;

export const ContainerImageAdd = styled.div`
  position: absolute;
  display: flex;
  flex-direction: row;
  justify-content: start;
  max-height: 380px;
  width: 350px;
  right: 150px;
  top: 65px;
  margin-left: 10px;
  overflow: auto;
  flex-flow: wrap;
  cursor: pointer;
`;

export const Image = styled.img`
  width: 100px;
  height: 100px;
  object-fit: cover;
  border: 1px solid #e1e1e1;
  border-radius: 15px;
  margin: 5px;
`;

export const AddedImageContainer = styled.div`
  position: relative;
  display: flex;
`;

export const RemoveImageContainer = styled.div`
  position: absolute;
  background-color: #ff2f2f;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  top: 0px;
  right: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  svg {
    fill: #ffffff;
    width: 12px;
    height: 12px;
  }

  :hover {
    opacity: 0.7;
  }
`;

export const ContainerButton = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 350px;
  bottom: 50px;
  margin-left: 10px;
  right: 150px;
  cursor: pointer;
`;

export const InputGroup = styled.div`
  display: flex;
  flex-direction: column;
  width: 300px;
  margin-bottom: 5px;
  input {
    min-width: 300px;
    color: #1a1a1a;
  }
`;

export const InputLabel = styled.p`
  color: #1a1a1a;
  font-size: 16px;
  margin-bottom: 6px;
`;
