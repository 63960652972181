import React, {useState} from "react";

import { 
  Container, 
  GalleryImage, 
  ContainerCarousel,
  ContainerPlay,
  StyledSlider,
  Track,
  Thumb,
  ContainerAddPhoto,
  ContainerPlayPause,
  Image,
  AddedImageContainer,
  RemoveImageContainer,
  ContainerImageAdd,
  ContainerButton,
  InputGroup,
  InputLabel,
} from "./styles";

import Button from "../Button";

import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import Input from "components/Input";

import { MdPlayArrow, MdPause, MdAddAPhoto } from "react-icons/md";
import { ImCross } from "react-icons/im";

const mockVideo = [
  {imageId:183197,thermalImageLink: "https://iotcorestack-prediktascreeningframes1bfe0174-1gg7iebevl7ou.s3.us-east-2.amazonaws.com/62ad51e4-7a7a-4939-aac7-50fb4b707071/861d4e7d-eae3-4050-88bf-4808d732e63d/thermal/0.jpg"
  },{imageId:183198,thermalImageLink:"https://iotcorestack-prediktascreeningframes1bfe0174-1gg7iebevl7ou.s3.us-east-2.amazonaws.com/62ad51e4-7a7a-4939-aac7-50fb4b707071/861d4e7d-eae3-4050-88bf-4808d732e63d/thermal/1.jpg"
  },{imageId:183199,thermalImageLink:"https://iotcorestack-prediktascreeningframes1bfe0174-1gg7iebevl7ou.s3.us-east-2.amazonaws.com/62ad51e4-7a7a-4939-aac7-50fb4b707071/861d4e7d-eae3-4050-88bf-4808d732e63d/thermal/2.jpg"
},{imageId:183200,thermalImageLink:"https://iotcorestack-prediktascreeningframes1bfe0174-1gg7iebevl7ou.s3.us-east-2.amazonaws.com/62ad51e4-7a7a-4939-aac7-50fb4b707071/861d4e7d-eae3-4050-88bf-4808d732e63d/thermal/3.jpg"
},{imageId:183201,thermalImageLink:"https://iotcorestack-prediktascreeningframes1bfe0174-1gg7iebevl7ou.s3.us-east-2.amazonaws.com/62ad51e4-7a7a-4939-aac7-50fb4b707071/861d4e7d-eae3-4050-88bf-4808d732e63d/thermal/4.jpg"
},{imageId:183202,thermalImageLink:"https://iotcorestack-prediktascreeningframes1bfe0174-1gg7iebevl7ou.s3.us-east-2.amazonaws.com/62ad51e4-7a7a-4939-aac7-50fb4b707071/861d4e7d-eae3-4050-88bf-4808d732e63d/thermal/5.jpg"
},{imageId:183203,thermalImageLink:"https://iotcorestack-prediktascreeningframes1bfe0174-1gg7iebevl7ou.s3.us-east-2.amazonaws.com/62ad51e4-7a7a-4939-aac7-50fb4b707071/861d4e7d-eae3-4050-88bf-4808d732e63d/thermal/6.jpg"
},{imageId:183204,thermalImageLink:"https://iotcorestack-prediktascreeningframes1bfe0174-1gg7iebevl7ou.s3.us-east-2.amazonaws.com/62ad51e4-7a7a-4939-aac7-50fb4b707071/861d4e7d-eae3-4050-88bf-4808d732e63d/thermal/7.jpg"
},{imageId:183205,thermalImageLink:"https://iotcorestack-prediktascreeningframes1bfe0174-1gg7iebevl7ou.s3.us-east-2.amazonaws.com/62ad51e4-7a7a-4939-aac7-50fb4b707071/861d4e7d-eae3-4050-88bf-4808d732e63d/thermal/8.jpg"
},{imageId:183206,thermalImageLink:"https://iotcorestack-prediktascreeningframes1bfe0174-1gg7iebevl7ou.s3.us-east-2.amazonaws.com/62ad51e4-7a7a-4939-aac7-50fb4b707071/861d4e7d-eae3-4050-88bf-4808d732e63d/thermal/9.jpg"
},{imageId:183207,thermalImageLink:"https://iotcorestack-prediktascreeningframes1bfe0174-1gg7iebevl7ou.s3.us-east-2.amazonaws.com/62ad51e4-7a7a-4939-aac7-50fb4b707071/861d4e7d-eae3-4050-88bf-4808d732e63d/thermal/10.jpg"
},{imageId:183208,thermalImageLink:"https://iotcorestack-prediktascreeningframes1bfe0174-1gg7iebevl7ou.s3.us-east-2.amazonaws.com/62ad51e4-7a7a-4939-aac7-50fb4b707071/861d4e7d-eae3-4050-88bf-4808d732e63d/thermal/11.jpg"
},{imageId:183209,thermalImageLink:"https://iotcorestack-prediktascreeningframes1bfe0174-1gg7iebevl7ou.s3.us-east-2.amazonaws.com/62ad51e4-7a7a-4939-aac7-50fb4b707071/861d4e7d-eae3-4050-88bf-4808d732e63d/thermal/12.jpg"
},{imageId:183210,thermalImageLink:"https://iotcorestack-prediktascreeningframes1bfe0174-1gg7iebevl7ou.s3.us-east-2.amazonaws.com/62ad51e4-7a7a-4939-aac7-50fb4b707071/861d4e7d-eae3-4050-88bf-4808d732e63d/thermal/13.jpg"
},{imageId:183211,thermalImageLink:"https://iotcorestack-prediktascreeningframes1bfe0174-1gg7iebevl7ou.s3.us-east-2.amazonaws.com/62ad51e4-7a7a-4939-aac7-50fb4b707071/861d4e7d-eae3-4050-88bf-4808d732e63d/thermal/14.jpg"
},{imageId:183212,thermalImageLink:"https://iotcorestack-prediktascreeningframes1bfe0174-1gg7iebevl7ou.s3.us-east-2.amazonaws.com/62ad51e4-7a7a-4939-aac7-50fb4b707071/861d4e7d-eae3-4050-88bf-4808d732e63d/thermal/15.jpg"
},{imageId:183213,thermalImageLink:"https://iotcorestack-prediktascreeningframes1bfe0174-1gg7iebevl7ou.s3.us-east-2.amazonaws.com/62ad51e4-7a7a-4939-aac7-50fb4b707071/861d4e7d-eae3-4050-88bf-4808d732e63d/thermal/16.jpg"
},{imageId:183214,thermalImageLink:"https://iotcorestack-prediktascreeningframes1bfe0174-1gg7iebevl7ou.s3.us-east-2.amazonaws.com/62ad51e4-7a7a-4939-aac7-50fb4b707071/861d4e7d-eae3-4050-88bf-4808d732e63d/thermal/17.jpg"
},{imageId:183215,thermalImageLink:"https://iotcorestack-prediktascreeningframes1bfe0174-1gg7iebevl7ou.s3.us-east-2.amazonaws.com/62ad51e4-7a7a-4939-aac7-50fb4b707071/861d4e7d-eae3-4050-88bf-4808d732e63d/thermal/18.jpg"
},{imageId:183216,thermalImageLink:"https://iotcorestack-prediktascreeningframes1bfe0174-1gg7iebevl7ou.s3.us-east-2.amazonaws.com/62ad51e4-7a7a-4939-aac7-50fb4b707071/861d4e7d-eae3-4050-88bf-4808d732e63d/thermal/19.jpg"
},{imageId:183197,thermalImageLink:"https://iotcorestack-prediktascreeningframes1bfe0174-1gg7iebevl7ou.s3.us-east-2.amazonaws.com/62ad51e4-7a7a-4939-aac7-50fb4b707071/861d4e7d-eae3-4050-88bf-4808d732e63d/thermal/0.jpg"
},{imageId:183198,thermalImageLink:"https://iotcorestack-prediktascreeningframes1bfe0174-1gg7iebevl7ou.s3.us-east-2.amazonaws.com/62ad51e4-7a7a-4939-aac7-50fb4b707071/861d4e7d-eae3-4050-88bf-4808d732e63d/thermal/1.jpg"
},{imageId:183199,thermalImageLink:"https://iotcorestack-prediktascreeningframes1bfe0174-1gg7iebevl7ou.s3.us-east-2.amazonaws.com/62ad51e4-7a7a-4939-aac7-50fb4b707071/861d4e7d-eae3-4050-88bf-4808d732e63d/thermal/2.jpg"
},{imageId:183200,thermalImageLink:"https://iotcorestack-prediktascreeningframes1bfe0174-1gg7iebevl7ou.s3.us-east-2.amazonaws.com/62ad51e4-7a7a-4939-aac7-50fb4b707071/861d4e7d-eae3-4050-88bf-4808d732e63d/thermal/3.jpg"
},{imageId:183201,thermalImageLink:"https://iotcorestack-prediktascreeningframes1bfe0174-1gg7iebevl7ou.s3.us-east-2.amazonaws.com/62ad51e4-7a7a-4939-aac7-50fb4b707071/861d4e7d-eae3-4050-88bf-4808d732e63d/thermal/4.jpg"
},{imageId:183202,thermalImageLink:"https://iotcorestack-prediktascreeningframes1bfe0174-1gg7iebevl7ou.s3.us-east-2.amazonaws.com/62ad51e4-7a7a-4939-aac7-50fb4b707071/861d4e7d-eae3-4050-88bf-4808d732e63d/thermal/5.jpg"
},{imageId:183203,thermalImageLink:"https://iotcorestack-prediktascreeningframes1bfe0174-1gg7iebevl7ou.s3.us-east-2.amazonaws.com/62ad51e4-7a7a-4939-aac7-50fb4b707071/861d4e7d-eae3-4050-88bf-4808d732e63d/thermal/6.jpg"
},{imageId:183204,thermalImageLink:"https://iotcorestack-prediktascreeningframes1bfe0174-1gg7iebevl7ou.s3.us-east-2.amazonaws.com/62ad51e4-7a7a-4939-aac7-50fb4b707071/861d4e7d-eae3-4050-88bf-4808d732e63d/thermal/7.jpg"
},{imageId:183205,thermalImageLink:"https://iotcorestack-prediktascreeningframes1bfe0174-1gg7iebevl7ou.s3.us-east-2.amazonaws.com/62ad51e4-7a7a-4939-aac7-50fb4b707071/861d4e7d-eae3-4050-88bf-4808d732e63d/thermal/8.jpg"
},{imageId:183206,thermalImageLink:"https://iotcorestack-prediktascreeningframes1bfe0174-1gg7iebevl7ou.s3.us-east-2.amazonaws.com/62ad51e4-7a7a-4939-aac7-50fb4b707071/861d4e7d-eae3-4050-88bf-4808d732e63d/thermal/9.jpg"
},{imageId:183207,thermalImageLink:"https://iotcorestack-prediktascreeningframes1bfe0174-1gg7iebevl7ou.s3.us-east-2.amazonaws.com/62ad51e4-7a7a-4939-aac7-50fb4b707071/861d4e7d-eae3-4050-88bf-4808d732e63d/thermal/10.jpg"
},{imageId:183208,thermalImageLink:"https://iotcorestack-prediktascreeningframes1bfe0174-1gg7iebevl7ou.s3.us-east-2.amazonaws.com/62ad51e4-7a7a-4939-aac7-50fb4b707071/861d4e7d-eae3-4050-88bf-4808d732e63d/thermal/11.jpg"
},{imageId:183209,thermalImageLink:"https://iotcorestack-prediktascreeningframes1bfe0174-1gg7iebevl7ou.s3.us-east-2.amazonaws.com/62ad51e4-7a7a-4939-aac7-50fb4b707071/861d4e7d-eae3-4050-88bf-4808d732e63d/thermal/12.jpg"
},{imageId:183210,thermalImageLink:"https://iotcorestack-prediktascreeningframes1bfe0174-1gg7iebevl7ou.s3.us-east-2.amazonaws.com/62ad51e4-7a7a-4939-aac7-50fb4b707071/861d4e7d-eae3-4050-88bf-4808d732e63d/thermal/13.jpg"
},{imageId:183211,thermalImageLink:"https://iotcorestack-prediktascreeningframes1bfe0174-1gg7iebevl7ou.s3.us-east-2.amazonaws.com/62ad51e4-7a7a-4939-aac7-50fb4b707071/861d4e7d-eae3-4050-88bf-4808d732e63d/thermal/14.jpg"
},{imageId:183212,thermalImageLink:"https://iotcorestack-prediktascreeningframes1bfe0174-1gg7iebevl7ou.s3.us-east-2.amazonaws.com/62ad51e4-7a7a-4939-aac7-50fb4b707071/861d4e7d-eae3-4050-88bf-4808d732e63d/thermal/15.jpg"
},{imageId:183213,thermalImageLink:"https://iotcorestack-prediktascreeningframes1bfe0174-1gg7iebevl7ou.s3.us-east-2.amazonaws.com/62ad51e4-7a7a-4939-aac7-50fb4b707071/861d4e7d-eae3-4050-88bf-4808d732e63d/thermal/16.jpg"
},{imageId:183214,thermalImageLink:"https://iotcorestack-prediktascreeningframes1bfe0174-1gg7iebevl7ou.s3.us-east-2.amazonaws.com/62ad51e4-7a7a-4939-aac7-50fb4b707071/861d4e7d-eae3-4050-88bf-4808d732e63d/thermal/17.jpg"
},{imageId:183215,thermalImageLink:"https://iotcorestack-prediktascreeningframes1bfe0174-1gg7iebevl7ou.s3.us-east-2.amazonaws.com/62ad51e4-7a7a-4939-aac7-50fb4b707071/861d4e7d-eae3-4050-88bf-4808d732e63d/thermal/18.jpg"
},{imageId:183216,thermalImageLink:"https://iotcorestack-prediktascreeningframes1bfe0174-1gg7iebevl7ou.s3.us-east-2.amazonaws.com/62ad51e4-7a7a-4939-aac7-50fb4b707071/861d4e7d-eae3-4050-88bf-4808d732e63d/thermal/19.jpg"
}];

function PlayerVideo({ video, saveImage, selectedGroup }) {

  const [play, setPlay] = useState(false);
  const [carouselIndex, setCarouselIndex] = useState(0);
  const [imagesModalVideo, setImagesModalVideo] = useState([]);
  const [imagesVideo, setImagesVideo] = useState(video.thermalImages);
  const [videoFrameSelected, setVideoFrameSelected] = useState(imagesVideo[0]);
  const [groupName, setGroupName] = useState('');

  const changedPhoto = (index) => {
    if (index !== carouselIndex) {
      setCarouselIndex(carouselIndex + 1)
    }

    if (index === imagesVideo.length && !play) {
      setPlay(true)
    }

    if (index + 1 === imagesVideo.length) {
      setPlay(!play)
      setCarouselIndex(imagesVideo.length)
    }

    imagesVideo.map((item, i) => {
      return  (
        i === index ? setVideoFrameSelected(item) : ''
        )
    });
  }

  const addImage = (item) => {
    imagesModalVideo.push(item)
  }

  const updateCarouselIndex = () => {
    setCarouselIndex(carouselIndex + 1)
    setCarouselIndex(carouselIndex - 1)
  }

  return (
    <Container>
      <ContainerCarousel>
        <Carousel 
          autoPlay={play}
          showStatus={false} 
          showIndicators={false} 
          showArrows={false} 
          showThumbs={false}
          transitionTime={1}
          interval={500}
          swipeable={false}
          onChange={changedPhoto}
          selectedItem={carouselIndex}
        >
          {imagesVideo && imagesVideo.length !== 0 && imagesVideo.map((item, i) => {
            return (
              <GalleryImage
              key={i}
              src={item.thermalImageLink}
              />
            )})}
        </Carousel>
      </ContainerCarousel>
      <ContainerPlay>
        <ContainerPlayPause onClick={() => setPlay(!play)}>
        {!play ? (
          <MdPlayArrow color={'#000'} size={40} opacity={1} />
        ) : (
          <MdPause color={'#000'} size={40} opacity={1} />
        )}
        </ContainerPlayPause>
          <StyledSlider
            renderTrack={Track}
            renderThumb={Thumb}
            value={carouselIndex}
            onChange={(value) =>  {
              setCarouselIndex(value - 1)
            }}
            step={1}
            minDistance={1}
            min={1}
            max={imagesVideo.length}
          />
          <ContainerAddPhoto onClick={() => {
            addImage(videoFrameSelected)
            updateCarouselIndex()
          }}>
            <MdAddAPhoto color={'#000'} size={30} opacity={1} />
          </ContainerAddPhoto>
          <ContainerImageAdd>
            {imagesModalVideo && imagesModalVideo.length !== 0 
            && imagesModalVideo.map((item, index) => (
              <AddedImageContainer key={index}>
                <Image key={index} src={item.thermalImageLink}/>
                <RemoveImageContainer
                  onClick={() => {
                    imagesModalVideo.splice(index, 1)
                    updateCarouselIndex()
                  }}
                >
                  <ImCross />
                </RemoveImageContainer>
              </AddedImageContainer>
            ))}
          </ContainerImageAdd>
          {imagesModalVideo.length !== 0 ? (
            <ContainerButton>
              <InputGroup>
                <InputLabel>Nome do grupo</InputLabel>
                <Input
                  maxLength={100}
                  value={groupName}
                  onChange={(e) => setGroupName(e.target.value)}
                />
              </InputGroup>
              <Button
              label="Salvar Imagens"
              onClick={() =>  saveImage(imagesModalVideo, groupName)}
              />
            </ContainerButton>
          ) : null}
      </ContainerPlay>
    </Container>
  );
}

export default PlayerVideo;
