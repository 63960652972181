/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";

// Libs
import { toast } from "react-toastify";

// Components
import SelectInput from "components/SelectInput";
import Input from "components/Input";
import Button from "components/Button";
import ButtonInverted from "components/ButtonInverted";

// Services
import DashboardService from "services/dashboard";

// Constants
import { initialCustomerUsersValues, statusENUM } from "../../../../constants";

// Context
import { useGeneralProvider } from "contexts/general";

// Imgs
import CloseIconSvg from "assets/close-icon.svg";

// Styles
import {
  BackDrop,
  Container,
  HeaderContainer,
  HeaderText,
  HeaderCloseContainer,
  ImageWrapper,
  CloseIcon,
  BodyContainer,
  FooterContainer,
  InputGroup,
  InputLabel,
} from "./styles";

function EditCustomerUser({
  setShowModal,
  selectedItem,
  handleCleanFilters,
  companyId,
}) {
  const { setLoading, globalUser } = useGeneralProvider();
  const userPassService = new DashboardService(globalUser);

  const [isSaveEnabled, setIsSaveEnabled] = useState(false);
  const [userData, setUserData] = useState({
    ...initialCustomerUsersValues,
    ...selectedItem,
  });

  useEffect(() => {
    handleEnableButtonSave();
  }, [userData]);

  const handleUpdateUser = () => {
    setLoading(true);

    userPassService
      .updateCompanyUser({ companyId: companyId, ...userData })
      .then(() => {
        toast.success("Usuário atualizado com sucesso!");
        handleCleanFilters();
        setShowModal(false);
      })
      .catch((err) => {
        setLoading(false);

        if (err?.response?.data?.message) {
          toast.error(err.response.data.message);
        } else {
          toast.error("Falha ao atualizar usuário");
        }
      });
  };

  const handleEnableButtonSave = () => {
    let auxIsSaveEnabled = true;

    if (!userData.userName || userData.userStatus === null) {
      auxIsSaveEnabled = false;
    }

    setIsSaveEnabled(auxIsSaveEnabled);
  };

  return (
    <BackDrop>
      <Container>
        <HeaderContainer>
          <HeaderCloseContainer>
            <ImageWrapper />
            <HeaderText>Editar Informações do usuário</HeaderText>
            <ImageWrapper onClick={() => setShowModal(false)}>
              <CloseIcon src={CloseIconSvg} />
            </ImageWrapper>
          </HeaderCloseContainer>
        </HeaderContainer>

        <BodyContainer>
          <InputGroup>
            <InputLabel>Nome</InputLabel>
            <Input
              maxLength={255}
              value={userData.userName}
              onChange={(e) =>
                setUserData({
                  ...userData,
                  userName: e.target.value,
                })
              }
            />
          </InputGroup>

          <InputGroup>
            <InputLabel>E-mail</InputLabel>
            <Input disabled maxLength={255} value={userData.userEmail} />
          </InputGroup>

          <InputGroup>
            <InputLabel>Situação</InputLabel>
            <SelectInput
              defaultOption={{ name: "Selecione...", id: null }}
              selectedOption={statusENUM.find(
                (item) => item.id === userData.userStatus
              )}
              optionLabel="name"
              optionValue="id"
              options={statusENUM}
              setSelectedOption={(e) => {
                setUserData({
                  ...userData,
                  userStatus: e.id,
                });
              }}
            />
          </InputGroup>
        </BodyContainer>

        <FooterContainer>
          <ButtonInverted
            label={"cancelar"}
            onClick={() => setShowModal(false)}
          />
          <span />
          <Button
            label={"Salvar Alterações"}
            disabled={!isSaveEnabled}
            onClick={() => {
              handleUpdateUser();
            }}
          />
        </FooterContainer>
      </Container>
    </BackDrop>
  );
}

export default EditCustomerUser;
