import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: max-content;
  flex-direction: column;
  padding-top: 80px;
`;

export const LogoImg = styled.img`
  width: 200px;
  height: 200px;
  opacity: 0.5;
`;

export const LoadingText = styled.p`
  margin-top: 20px;
  font-size: 16px;
`;
