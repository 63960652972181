import React from "react";

// Constants
import { reportStatusENUM } from "../../../../constants";

import { Container, GroupContainer, ColorSquare, LabelText } from "./styles";

const LabelInfo = reportStatusENUM.slice(0, 3);

function TableLabel() {
  return (
    <Container>
      {LabelInfo.map((label) => (
        <GroupContainer key={label.color}>
          <ColorSquare color={label.color} />
          <LabelText>{label.description}</LabelText>
        </GroupContainer>
      ))}
    </Container>
  );
}

export default TableLabel;
