import React from "react";
import Select from "react-select";

import {
  Container,
  LabelText,
  DescriptionContainer,
  DescriptionText,
} from "./styles";

function SelectPalette({
  label,
  options,
  disabled,
  defaultPaletteValue,
  selectedPalette,
  setSelectedPalette,
  savedImage,
  isViewingSavedImage,
  isGalleryLeft,
}) {
  const customStyles = {
    menu: (provided) => ({
      ...provided,
      zIndex: 10,
    }),
  };

  return (
    <Container galleryLeft={isGalleryLeft}>
      {/*<LabelText>{label}</LabelText>*/}

      {isViewingSavedImage ? (
        <DescriptionContainer>
          <DescriptionText>{savedImage.palleteName}</DescriptionText>
        </DescriptionContainer>
      ) : (
        <Select
          isDisabled={disabled}
          styles={customStyles}
          defaultValue={defaultPaletteValue}
          value={selectedPalette}
          options={options}
          getOptionLabel={(option) => option.name}
          getOptionValue={(option) => option.palleteRgb}
          onChange={(e) => setSelectedPalette(e)}
          placeholder={"Selecione..."}
        />
      )}
    </Container>
  );
}

export default SelectPalette;
