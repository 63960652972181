import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 10px;
`;

export const GroupContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-right: 24px;
`;

export const ColorSquare = styled.div`
  border-radius: 4px;
  width: 16px;
  height: 16px;
  background-color: ${({ color }) => color};
  margin-right: 6px;
`;

export const LabelText = styled.p``;
