import styled from 'styled-components'
import { Popover } from '@mui/material'

export const Drop = styled(Popover)`
    .MuiPopover-paper{
        ::-webkit-scrollbar {
            width: 8px;
            height: 8px;
        }
        ::-webkit-scrollbar-button {
            width: 0px;
            height: 0px;
        }
        ::-webkit-scrollbar-thumb {
            background: #0073ab;
            border-radius: 20px;
        }
        ::-webkit-scrollbar-thumb:hover {
            background: #0073ab;
        }
        ::-webkit-scrollbar-thumb:active {
            background: #0073ab;
        }
        ::-webkit-scrollbar-track {
            background: transparent;
            border-radius: 50px;
        }
        ::-webkit-scrollbar-track:hover {
            background: transparent;
        }
        ::-webkit-scrollbar-track:active {
            background: transparent;
        }
        ::-webkit-scrollbar-corner {
            background: transparent;
        }
    }
`

export const List = styled.div`
    list-style: none;
    background-color: #FEFEFE;
    color: #fff;
    width: fit-content;
    min-width: 150px;
`

export const Item = styled.p`
    padding: 5px;
    cursor: pointer;
    transition: 100ms all;
    color: #000;

    &:hover{
        background-color: #DFEEF5;
        color: #0073AB;
    }
`