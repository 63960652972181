import React from "react";

// Libs
import { IoMdInformationCircleOutline } from "react-icons/io";

// Components
import CustomTooltip from "components/CustomTooltip";

import { Container } from "./styles";

function InfoButton() {
  return (
    <CustomTooltip
      title={
        "Ao salvar, será gerada uma única imagem e adicionada automaticamente ao grupo, com a edição da paleta."
      }
    >
      <Container>
        <IoMdInformationCircleOutline />
      </Container>
    </CustomTooltip>
  );
}

export default InfoButton;
