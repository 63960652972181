import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  flex-direction: column;
  user-select: none;
`;

export const LogoImg = styled.img`
  min-width: 300px;
  min-height: 300px;
  opacity: 0.5;
`;

export const LoadingText = styled.p`
  margin-top: 20px;
  font-size: 26px;
`;
