import styled from "styled-components";

export const Container = styled.div`
  background-color: #fefefe;
  min-height: 64px;
  flex-wrap: wrap;
  width: 100%;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #f1f1f1;
  padding: 0 22px 0 22px;
  flex-direction: row;
  justify-content: space-between;
`;

export const TitleContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const ImgLogo = styled.img`
  width: 40px;
  height: 40px;
  user-select: none;
  pointer-events: none;
`;

export const MainTitle = styled.p`
  margin: 0;
  padding: 0;
  font-size: 20px;
  color: #38393d;
  font-weight: 500;
  margin-left: 28px;
`;
