import React from "react";

// Services
import { signOut } from "services/authentication";

// Context
import { useGeneralProvider } from "contexts/general";

// Imgs
import CloseIconSvg from "assets/close-icon.svg";

import {
  BackDrop,
  Container,
  ImageWrapper,
  CloseIcon,
  BodyText,
  FooterContainer,
  Button,
  InvertedButton,
} from "./styles";

function ModalAlert({ setShowModal, message }) {

  return (
    <BackDrop>
      <Container>
        <ImageWrapper onClick={() => setShowModal(false)}>
          <CloseIcon src={CloseIconSvg} />
        </ImageWrapper>
        <BodyText>{message}</BodyText>
      </Container>
    </BackDrop>
  );
}

export default ModalAlert;
