import styled from "styled-components";

export const BackDrop = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(56, 57, 61, 0.2);
  z-index: 999;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
`;

export const Container = styled.div`
  background-color: #fefefe;
  padding: 16px;
  width: 636px;
  min-height: 300px;
  max-width: calc(100vw - 200px);
  max-height: calc(100vh - 100px);
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  overflow-x: auto;

  @media (max-width: 600px) {
    max-width: calc(100vw - 20px);
    max-height: calc(100vh - 20px);
  }

  ::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }
  ::-webkit-scrollbar-button {
    width: 0px;
    height: 0px;
  }
  ::-webkit-scrollbar-thumb {
    background: #0073ab;
    border-radius: 20px;
  }
  ::-webkit-scrollbar-thumb:hover {
    background: #0073ab;
  }
  ::-webkit-scrollbar-thumb:active {
    background: #0073ab;
  }
  ::-webkit-scrollbar-track {
    background: transparent;
    border-radius: 50px;
  }
  ::-webkit-scrollbar-track:hover {
    background: transparent;
  }
  ::-webkit-scrollbar-track:active {
    background: transparent;
  }
  ::-webkit-scrollbar-corner {
    background: transparent;
  }
`;

export const HeaderContainer = styled.div`
  width: 100%;
  margin-bottom: 32px;
`;

export const HeaderText = styled.p`
  font-size: 16px;
  color: #38393d;
`;

export const HeaderCloseContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  height: 30px;
  padding-right: 10px;
  padding-left: 10px;
  align-items: center;
`;

export const ImageWrapper = styled.div`
  cursor: pointer;
  width: 24px;
  height: 24px;

  :hover {
    opacity: 0.7;
  }
`;

export const CloseIcon = styled.img`
  width: 24px;
  height: 24px;
  user-select: none;
  pointer-events: none;
`;

export const BodyContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
`;

export const FormColumn = styled.div`
  display: flex;
  flex-direction: column;
  width: 280px;

  :first-of-type {
    margin-right: 40px;
  }
`;

export const FooterContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 32px;
`;

export const InputGroup = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 24px;
`;

export const InputLabel = styled.p`
  color: #1a1a1a;
  font-size: 14px;
  margin-bottom: 6px;
`;

export const ProgressContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: 12px;
`;

export const ProgressStepContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: ${(props) => (props.completed ? "pointer" : "unset")};

  span {
    border: ${(props) =>
      props.completed ? "2px solid #0073AB" : "2px solid #a2a2a2"};
    background-color: ${(props) => (props.completed ? "#0073AB" : "#ffffff")};
    color: ${(props) => (props.completed ? "#ffffff" : "#a2a2a2")};
  }

  p {
    color: ${(props) => (props.completed ? "#0073AB" : "#a2a2a2")};
  }
`;

export const ProgressLine = styled.div`
  width: 70px;
  background-color: ${(props) => (props.completed ? "#0073AB" : "#a2a2a2")};
  height: 1px;
  margin-top: -18px;
  margin-left: -19px;
  margin-right: -19px;
`;

export const ProgressNumber = styled.span`
  width: 26px;
  height: 26px;
  border-radius: 50%;
  border: 2px solid #a2a2a2;
  background-color: #ffffff;
  z-index: 5;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #a2a2a2;
`;

export const ProgressText = styled.p`
  font-size: 14px;
  color: #a2a2a2;
`;

export const ContainerFilesForm = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
`;

export const UploadTitleText = styled.p`
  color: #525252;
  font-size: 16px;
  margin-bottom: 6px;
`;

export const UploadIconImg = styled.img`
  width: 30px;
  height: 30px;
  margin-bottom: 5px;
`;

export const UploadText = styled.p`
  font-size: 16px;
  color: ${(props) => (props.actived ? "#2773AB" : "#38393D")};
  font-weight: bold;
`;

export const ContainerUploadFiles = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  width: 100%;
  border-width: 2px;
  border-radius: 2px;
  border-style: dashed;
  background-color: #fafafa;
  color: #bdbdbd;
  outline: none;
  transition: border 0.24s ease-in-out;
  margin-bottom: 20px;
`;

export const ViewImageContainer = styled.div`
  height: 100px;
  max-width: 50%;
  display: flex;
  align-items: center;
  margin-bottom: 20px;
`;

export const RemoveFileText = styled.div`
  cursor: pointer;
  font-size: 14px;
  margin-left: 20px;
`;

export const ViewImage = styled.img`
  max-width: 100%;
  max-height: 100%;
`;
