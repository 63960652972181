import React from "react";
import ReactDOM from "react-dom";
import Routes from "./routes";

// Libs
import { ToastContainer } from "react-toastify";
import { BrowserRouter } from "react-router-dom";

// Context
import { GeneralProvider } from "./contexts/general";

import "./global.css";
import "react-toastify/dist/ReactToastify.css";

ReactDOM.render(
  <GeneralProvider>
    <ToastContainer />
    <BrowserRouter>
      <Routes />
    </BrowserRouter>
  </GeneralProvider>,
  document.getElementById("root")
);
