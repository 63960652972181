// Libs
import DatePicker from "react-date-picker";

import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

export const CustomInputDatePicker = styled(DatePicker)`
  .react-date-picker__wrapper {
    border-radius: 4px;
    height: 40px;
    border: 1px solid #9d9e9d;
    padding: 0 8px 0 8px;
  }

  .react-calendar__month-view__days__day--weekend {
    color: #000000;
  }
`;
