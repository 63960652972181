import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100vw;
  max-width: 940px;
`;

export const ResumeTitle = styled.p`
  color: #38393d;
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 24px;
`;

export const ResumeSubtitle = styled.p`
  color: #38393d;
  font-size: 14px;
`;

export const InputGroup = styled.div`
  display: flex;
  flex-direction: column;
`;

export const InputLabel = styled.p`
  color: #1a1a1a;
  font-size: 14px;
  margin-bottom: 6px;
`;

export const TopContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  border-bottom: solid 1px #e1e1e1;
  padding: 20px;
`;

export const MiddleContainer = styled.div`
  display: flex;
  width: 100%;
  border-bottom: solid 1px #e1e1e1;
  padding: 20px 20px 0 20px;
`;

export const BottomContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px;
  width: 100%;
`;

export const FooterContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  padding: 20px;
`;
