import styled from "styled-components";

export const LeftContainer = styled.div`
  display: flex;
  border-right: solid 1px #e1e1e1;
  width: ${(props) => (props.expandLeft && props.isDiagnosisRight ? 
    '300px' : props.expandLeft ?
    '220px' : '30px')};
  overflow-x: hidden;
  transition: all 1s;
`;

export const MiddleContainer = styled.div`
  padding-left: 20px;
  padding-right: 20px;
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow: auto;
`;

export const RightContainer = styled.div`
  width: ${(props) => (props.expandRight ? "320px" : '40px')};
  min-width: ${(props) => (props.expandRight ? "320px" : '40px')};
  display: flex;
  flex-direction: column;
  border-left: solid 1px #e1e1e1;
  overflow-x: hidden;
  transition: all 1s;
`;

export const ArrowContainer = styled.div`
  diplay: flex;
`;

export const ActionIconLeft = styled.img`
  width: 20px;
  height: 20px;
  user-select: none;
  pointer-events: none;
  overflow-x: hidden;
  transform: rotate(180deg);
  transition: all 1s;
`;

export const ActionIconRight = styled.img`
  width: 20px;
  height: 20px;
  user-select: none;
  pointer-events: none;
  overflow-x: hidden;
  transition: all 1s;
`;
