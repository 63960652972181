/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";

// Libs
import { Doughnut, Bar } from "react-chartjs-2";
import { toast } from "react-toastify";

// Context
import { useGeneralProvider } from "contexts/general";

// Services
import DashboardService from "services/dashboard";

// Imgs
import Chart1 from "assets/chart-1.svg";
import Chart2 from "assets/chart-2.svg";
import Chart3 from "assets/chart-3.svg";
import Chart4 from "assets/chart-4.svg";

import {
  Container,
  ReasonContainer,
  SymptomContainer,
  HeaderText,
  ReasonBody,
  SymptomBody,
  DoughnutChartContainer,
  BarChartContainer,
  DoughnutInfoContainer,
  ChartReasonImg,
  InfoLine,
  InfoText,
} from "./styles";

function GraphicsContainer({ expanded, currentFilters, changedValue }) {
  const { setLoading, globalUser, updateCharts } = useGeneralProvider();
  const userPassService = new DashboardService(globalUser);
  const [showCharts, setShowCharts] = useState(false);
  const [expandContainer, setExpandContainer] = useState(false);
  const [reasons, setReasons] = useState([]);
  const [symptoms, setSymptoms] = useState([]);
  const [graphBarLabels, setGraphBarLabels] = useState([]);
  const [graphBarData, setGraphBarData] = useState([]);

  useEffect(() => {
    if (expanded) {
      getGraphicsData();
    } else {
      setShowCharts(false);
      setExpandContainer(false);
    }
  }, [expanded]);

  useEffect(() => {
    if (expanded) {
      setShowCharts(false);
      getGraphicsData();
    }
  }, [updateCharts]);

  const getGraphicsData = () => {
    setLoading(true);
    userPassService
      .getGraphicsDetails(currentFilters)
      .then((o) => {
        setExpandContainer(true);
        setShowCharts(true);

        if (o.data) {
          const response = o.data;

          if (response.reasons) {
            setReasons(response.reasons);
          } else {
            setReasons([]);
          }

          if (response.symptoms) {
            handleGraphBarSymptoms(response.symptoms);
            setSymptoms(response.symptoms);
          } else {
            setSymptoms([]);
          }
          return;
        }
      })
      .catch(() => {
        toast.error("Falha ao buscar dados de 'Alteradas'");
        setShowCharts(false);
        setExpandContainer(false);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleGraphBarSymptoms = (symptoms) => {
    let auxLabels = [];
    let auxData = [];

    symptoms.forEach((item) => {
      auxLabels.push(item.name || "");
      auxData.push(item.users || 0);
    });

    setGraphBarLabels(auxLabels);
    setGraphBarData(auxData);
  };

  return (
    <Container expanded={expandContainer}>
      <ReasonContainer>
        <HeaderText>Motivo</HeaderText>
        <ReasonBody>
          {showCharts ? (
            <>
              {" "}
              <DoughnutChartContainer>
                <Doughnut
                  data={{
                    datasets: [
                      {
                        data: [
                          reasons[0]?.users || 0,
                          reasons[1]?.users || 0,
                          reasons[2]?.users || 0,
                          reasons[3]?.users || 0,
                        ],
                        backgroundColor: [
                          "#5AD0D8",
                          "#94E7A7",
                          "#FF6782",
                          "#D6ACFF",
                        ],
                        borderWidth: 0,
                        cutout: 60,
                      },
                    ],
                  }}
                  plugins={[
                    {
                      beforeDraw(chart) {
                        const width = chart.width,
                          height = chart.height,
                          ctx = chart.ctx;

                        ctx.restore();
                        const fontSize = (height / 100).toFixed(2);
                        ctx.font = fontSize + "em sans-serif";
                        ctx.textBaseline = "top";

                        const text = changedValue,
                          textX = Math.round(
                            (width - ctx.measureText(text).width) / 2
                          ),
                          textY = height / 2.2;

                        ctx.fillText(text, textX, textY);
                        ctx.save();
                      },
                    },
                  ]}
                />
              </DoughnutChartContainer>
              <DoughnutInfoContainer>
                <InfoLine>
                  <ChartReasonImg src={Chart1} />
                  <InfoText bold>{`Temperatura ${
                    reasons[0]?.percentage || "0%"
                  }`}</InfoText>
                  <InfoText>{`(${reasons[0]?.users || 0} pessoas)`}</InfoText>
                </InfoLine>
                <InfoLine>
                  <ChartReasonImg src={Chart2} />
                  <InfoText bold>{`Sintoma ${
                    reasons[1]?.percentage || "0%"
                  }`}</InfoText>
                  <InfoText>{`(${reasons[1]?.users || 0} pessoas)`}</InfoText>
                </InfoLine>
                <InfoLine>
                  <ChartReasonImg src={Chart3} />
                  <InfoText bold>{`AI ${
                    reasons[2]?.percentage || "0%"
                  }`}</InfoText>
                  <InfoText>{`(${reasons[2]?.users || 0} pessoas)`}</InfoText>
                </InfoLine>
                <InfoLine>
                  <ChartReasonImg src={Chart4} />
                  <InfoText bold>{`Índice ${
                    reasons[3]?.percentage || "0%"
                  }`}</InfoText>
                  <InfoText>{`(${reasons[3]?.users || 0} pessoas)`}</InfoText>
                </InfoLine>
              </DoughnutInfoContainer>
            </>
          ) : null}
        </ReasonBody>
      </ReasonContainer>
      <SymptomContainer>
        <HeaderText>Sintoma</HeaderText>
        <SymptomBody>
          <BarChartContainer>
            {showCharts ? (
              <Bar
                data={{
                  labels: graphBarLabels,
                  datasets: [
                    {
                      data: graphBarData,
                      barPercentage: 1,
                      backgroundColor: "#3AAFFC",
                      borderWidth: 0,
                      borderRadius: 4,
                      barThickness: 18,
                    },
                  ],
                }}
                options={{
                  plugins: {
                    tooltip: {
                      callbacks: {
                        label: (tooltipItem) => {
                          return `${
                            symptoms[tooltipItem.dataIndex]?.percentage || "0%"
                          } (${tooltipItem.formattedValue})`;
                        },
                      },
                    },
                    legend: {
                      display: false,
                    },
                  },
                  scales: {
                    x: {
                      grid: {
                        display: false,
                      },
                    },
                    y: {
                      grid: {
                        display: true,
                      },
                    },
                  },
                }}
              />
            ) : null}
          </BarChartContainer>
        </SymptomBody>
      </SymptomContainer>
    </Container>
  );
}

export default GraphicsContainer;
