import React from "react";

// Imgs
import CloseIconSvg from "assets/close-icon.svg";

import {
  BackDrop,
  Container,
  Header,
  ImageWrapper,
  CloseIcon,
  BodyText,
  FooterContainer,
  Button,
  InvertedButton,
} from "./styles";

function ModalConfirm({ setShowModal, confirm }) {
  return (
    <BackDrop>
      <Container>
        <Header>
          <ImageWrapper onClick={() => setShowModal(false)}>
            <CloseIcon src={CloseIconSvg} />
          </ImageWrapper>
        </Header>
        <BodyText>
          A análise atual não foi salva, deseja sair mesmo assim?
        </BodyText>
        <FooterContainer>
          <InvertedButton onClick={() => setShowModal(false)}>
            Cancelar
          </InvertedButton>
          <Button
            onClick={() => {
              confirm();
              setShowModal(false);
            }}
          >
            Sim, quero sair
          </Button>
        </FooterContainer>
      </Container>
    </BackDrop>
  );
}

export default ModalConfirm;
