import React from "react";

// Services
import { signOut } from "services/authentication";

// Context
import { useGeneralProvider } from "contexts/general";

// Imgs
import CloseIconSvg from "assets/close-icon.svg";

import {
  BackDrop,
  Container,
  ImageWrapper,
  CloseIcon,
  BodyText,
  FooterContainer,
  Button,
  InvertedButton,
} from "./styles";

function ModalSignout({ setShowModal }) {
  const { setLoading, setUser } = useGeneralProvider();

  return (
    <BackDrop>
      <Container>
        <ImageWrapper onClick={() => setShowModal(false)}>
          <CloseIcon src={CloseIconSvg} />
        </ImageWrapper>
        <BodyText>Tem certeza que quer sair da plataforma?</BodyText>
        <FooterContainer>
          <InvertedButton onClick={() => setShowModal(false)}>
            Cancelar
          </InvertedButton>
          <Button onClick={() => signOut(setLoading, setUser)}>
            Sim, quero sair
          </Button>
        </FooterContainer>
      </Container>
    </BackDrop>
  );
}

export default ModalSignout;
