import React, { useState, useRef, useEffect } from "react";

// Imgs
import ArrowDown from "assets/arrow-down.svg";
import UnChecked from "assets/unchecked.svg";
import Checked from "assets/checked.svg";

import {
  SelectContainer,
  ButtonContainer,
  ArrowIcon,
  OptionsContainer,
  OptionItemContainer,
  OptionText,
  CheckboxIcon,
} from "./styles";

function SelectFilter({ options, setOptions, label }) {
  const selectRef = useRef(null);
  const btnRef = useRef(null);

  const [isOnFocus, setIsOnFocus] = useState(false);
  const [optionsPosition, setOptionsPosition] = useState({
    top: 0,
    left: 0,
  });

  useEffect(() => {
    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const handleClickOutside = (event) => {
    if (selectRef.current && !selectRef.current.contains(event.target)) {
      setIsOnFocus(false);
    }
  };

  const handleClickButton = () => {
    const top = selectRef.current.getBoundingClientRect().top | 0;
    const left = selectRef.current.getBoundingClientRect().left | 0;

    setOptionsPosition({
      top: top,
      left: left,
    });

    setIsOnFocus(!isOnFocus);
  };

  const handleOptionSelection = (selectedId) => {
    let auxOptions = [...options];

    let index = auxOptions.findIndex((x) => x.id === selectedId);

    auxOptions.findIndex(x => {
      if (x.checked === true) {
        x.checked = false;
      }
    });

    auxOptions[index].checked = !auxOptions[index].checked;

    setOptions(auxOptions);
    
  };

  return (
    <SelectContainer ref={selectRef}>
      <ButtonContainer
        ref={btnRef}
        onClick={handleClickButton}
        isOnFocus={isOnFocus}
      >
        {label}
        <ArrowIcon src={ArrowDown} isOnFocus={isOnFocus} />
      </ButtonContainer>

      {isOnFocus ? (
        <OptionsContainer top={optionsPosition.top} left={optionsPosition.left}>
          {options.map((option, index) => (
            <OptionItemContainer
              key={`${label}-${index}`}
              onClick={() => {
                handleOptionSelection(option.id);
              }}
            >
              <CheckboxIcon src={option.checked ? Checked : UnChecked} />
              <OptionText>{option.name}</OptionText>
            </OptionItemContainer>
          ))}
        </OptionsContainer>
      ) : null}
    </SelectContainer>
  );
}

export default SelectFilter;
