import React, { useEffect } from "react";

// Libs
import { Route } from "react-router-dom";

// Context
import { useGeneralProvider } from "contexts/general";

// Components
import Login from "pages/Login";
import AppContainer from "components/AppContainer";

function PrivateRoute(props) {
  const { getUser, globalUser } = useGeneralProvider();

  useEffect(() => {
    getUser();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (globalUser?.token) {
    return (
      <AppContainer>
        <Route {...props}>{props.children}</Route>
      </AppContainer>
    );
  }

  return (
    <Route {...props}>
      <Login />
    </Route>
  );
}

export default PrivateRoute;
