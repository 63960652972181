import React from "react";

// Context
import { useGeneralProvider } from "contexts/general";

import { Container, LabelText, SwitchContainer } from "./styles";

function SwitchButton() {
  const { automaticUpdate, setAutomaticUpdate, handleResetTimeout } =
    useGeneralProvider();

  return (
    <Container>
      <LabelText>Atualização automática</LabelText>
      <SwitchContainer
        actived={automaticUpdate}
        onClick={() => {
          handleResetTimeout();
          setAutomaticUpdate(!automaticUpdate);
        }}
      >
        <span />
      </SwitchContainer>
    </Container>
  );
}

export default SwitchButton;
