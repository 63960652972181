import React, { useState } from "react";

// Libs
import { MdAddCircle } from "react-icons/md";
import { BiExpand } from "react-icons/bi";

//Icons
import DoubleArrow from "assets/double-arrow.svg";
import { IoTriangleOutline, IoSquareOutline, IoRadioButtonOff } from "react-icons/io5";

// Imgs
import DefaultUser from "assets/default-user.png";

//Components
import SelectPalette from "../../../../../../../../components/ThermalManipulation/components/SelectPalette";
import Button from "../../../../../../../../components/Button";
import ButtonToggle from "../../../../../../../../components/ButtonToggle";

// Services
import DashboardService from "services/dashboard";

// Context
import { useGeneralProvider } from "contexts/general";

// Utils
import { getDefaultPalleteRgb, applyGaussianBlur } from "utils";

// Constants
import {
  minTemperatureCelsius,
  maxTemperatureCapCelsius
} from "../../../../../../../../components/ThermalManipulation/constants";

import {
  Container,
  GalleryText,
  ToolsText,
  GalleryContainer,
  GalleryImageContainer,
  GalleryImage,
  ExpandContainer,
  Header,
  ToogleDrawer,
  ActionIcon,
  DeltaBtnWrapper,
  CleanDeltaTextBtn,
  ContainerButtonToggle,
} from "./styles";

const PALETTE_APPLIED_TITLE = 'Pallete applied';

function Gallery({
  handleImageClick,
  groupImages,
  handleGetImageByID,
  addedImagesIds,
  setIsViewingSavedImage,
  groupAnalysedImages,
  toggleLeft,
  setIsShowingDeltaCalc,
  isShowingDeltaCalc,
  setFormRect,
  formRect,
  setIsSelectedForm,
  isSelectedForm,
  paletteOptions,
  selectedPalette,
  setSelectedPalette,
  clearDelta,
  addNewDelta
}) {
  const palleteRgb = getDefaultPalleteRgb();
  const { _, globalUser } = useGeneralProvider();
  const userPassService = new DashboardService(globalUser);

  const addImageToKonva = (imageId, isAnalysed) => {
      setIsViewingSavedImage(false);
      handleImageClick({
        imageUrl: handleGetImageByID(imageId, isAnalysed),
        imageId: imageId,
        isAnalysed: isAnalysed
      });
  };

  // Extrai o "raw" da imagem com base no RGB de cada pixel e calcula a temperatura
  const handleGetTemperature = (green, blue) => {
    const raw = green * 256 + blue;

    const M = 0.02569698246480104;
    const B = 64.74694198003411;

    const temp = M * raw - B;

    return temp;
  };

  const handleInitialMapping = (imageData) => {
    const dataLength = imageData.data.length;
    const pixelLength = 4;

    const temperaturePixelArray = [];

    for (let i = 0; i <= dataLength - pixelLength; i += pixelLength) {
      const green = imageData.data[i + 1];
      const blue = imageData.data[i + 2];

      const temp = handleGetTemperature(green, blue);

      if (temp >= minTemperatureCelsius && temp <= maxTemperatureCapCelsius) {
        temperaturePixelArray.push({ t: temp, p: i });
      }
    }

    const currentImageSortedData = temperaturePixelArray.sort((a, b) => {
      if (a.t < b.t) return -1;
      if (a.t > b.t) return 1;
      return 0;
    });

    return currentImageSortedData;
  };

  const handleTemperaturePerPixel = (imageData, currentImageValues) => {
    const temperaturePixelArray = [];

    for (let i = 0; i < currentImageValues.length; i++) {
      temperaturePixelArray.push(currentImageValues[i]);
    }
    return temperaturePixelArray;
  };

  const handlePixelTransform = (imageData, currentImageValues) => {
    const temperaturePixelArray = handleTemperaturePerPixel(imageData, currentImageValues);
    const expandedPalette = [];

    const pixelsPerColor = Math.ceil(
      temperaturePixelArray.length / palleteRgb.length
    );

    const reversedPalette = [...palleteRgb].reverse();

    for (let i = 0; i < reversedPalette.length; i++) {
      for (let j = 0; j < pixelsPerColor; j++) {
          expandedPalette.push(reversedPalette[i]);
      }
    }

    if (expandedPalette.length !== 0) {
      for (let i = 0; i < temperaturePixelArray.length; i++) {
          const currentColor = expandedPalette[i] || [0, 0, 0];

          // red
          imageData.data[temperaturePixelArray[i].p] = currentColor[0];
          // green
          imageData.data[temperaturePixelArray[i].p + 1] = currentColor[1];
          // blue
          imageData.data[temperaturePixelArray[i].p + 2] = currentColor[2];
      }
    }
    return imageData;
  };

  const validURL = (str) => {
    var pattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
    '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // domain name
    '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
    '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
    '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
    '(\\#[-a-z\\d_]*)?$','i'); // fragment locator
    return !!pattern.test(str);
  }

  const getTransformedImage = async (imageUrl) => {    
    let reload = false;
    if (validURL(imageUrl.target.currentSrc)) {
      const image = await userPassService.getImageBase64ByUrl(imageUrl.target.currentSrc);
      imageUrl.target.src = image.data;
      imageUrl.target.title = null;
      reload = true;
    }

    if (!reload && imageUrl.target.title !== PALETTE_APPLIED_TITLE) {
      // Create an empty canvas element
      const canvas = document.createElement("canvas");
      canvas.width = imageUrl.target.naturalWidth;
      canvas.height = imageUrl.target.naturalHeight;

      // Copy the image contents to the canvas
      const ctx = canvas.getContext("2d");
      ctx.drawImage(imageUrl.target, 0, 0);
      const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
      const currentImageValues = handleInitialMapping(imageData);
      const newImageData = handlePixelTransform(imageData, currentImageValues);
      applyGaussianBlur(imageData);
      ctx.putImageData(newImageData, 0, 0);
      imageUrl.target.title = PALETTE_APPLIED_TITLE;
      imageUrl.target.src = canvas.toDataURL();
    }  
  };  

  return (
    <Container>
      <Header>
        <ToolsText>Ferramentas</ToolsText>
        <ToogleDrawer 
          onClick={() => { 
            toggleLeft(false);
          }}>
            <ActionIcon src={DoubleArrow} />
        </ToogleDrawer>
      </Header>
        <SelectPalette
          label="Paleta"
          options={paletteOptions}
          selectedPalette={selectedPalette}
          setSelectedPalette={setSelectedPalette}
          isGalleryLeft={true}
        />

      <DeltaBtnWrapper>
        <Button
          onClick={() => {
              addNewDelta()
          }}
          disabled={!addedImagesIds.length}
          icon={<IoTriangleOutline size={24} />}
          menuIsLeft={true}
        />
        <ContainerButtonToggle>
          <ButtonToggle
            onClick={() => {
              setFormRect('circle');
              setIsSelectedForm(!isSelectedForm)
            }}
            icon={<IoRadioButtonOff size={20} />}
            selected={formRect === 'circle' ? true : false}
          />
          <ButtonToggle
            onClick={() => {
              setFormRect('square');
              setIsSelectedForm(!isSelectedForm)
            }}
            icon={<IoSquareOutline size={20} />}
            selected={formRect === 'square' ? true : false}
          />
        </ContainerButtonToggle>
      </DeltaBtnWrapper>
      {isShowingDeltaCalc ? (
          <CleanDeltaTextBtn
            onClick={() => {
              clearDelta();
              setIsShowingDeltaCalc(false);
            }}
          >
            Limpar Delta
          </CleanDeltaTextBtn>
        ) : null}
      <GalleryText>Galeria</GalleryText>
      <GalleryContainer>
        {groupImages &&
          groupImages.length !== 0 &&
          groupImages.map(imageId => (
            <GalleryImageContainer
              key={imageId}
              onClick={() => addImageToKonva(imageId)}
            >
              <GalleryImage 
                src={handleGetImageByID(imageId) || DefaultUser}
                onLoad={getTransformedImage}
              />
              <ExpandContainer>
                <MdAddCircle />
              </ExpandContainer>
            </GalleryImageContainer>
          ))}

        {groupAnalysedImages &&
          groupAnalysedImages.length !== 0 &&
          groupAnalysedImages.map((imageId) => (
            <GalleryImageContainer
              key={imageId}
              onClick={() => addImageToKonva(imageId, true)}
            >
              <GalleryImage
                src={handleGetImageByID(imageId, true) || DefaultUser}                
              />
              <ExpandContainer>
                <MdAddCircle />
              </ExpandContainer>
            </GalleryImageContainer>
          ))}
      </GalleryContainer>
    </Container>
  );
}

export default Gallery;
