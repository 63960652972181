/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";

// Libs
import { toast } from "react-toastify";

// Forms
import ContactForm from "./ContactForm";
import FilesForm from "./FilesForm";

// Services
import DashboardService from "services/dashboard";

// Constants
import { initialCustomerValues } from "../../../../constants";

// Context
import { useGeneralProvider } from "contexts/general";

// Imgs
import CloseIconSvg from "assets/close-icon.svg";

// Styles
import {
  BackDrop,
  Container,
  HeaderContainer,
  HeaderText,
  HeaderCloseContainer,
  ImageWrapper,
  CloseIcon,
  BodyContainer,
  FooterContainer,
  ProgressContainer,
  ProgressStepContainer,
  ProgressLine,
  ProgressNumber,
  ProgressText,
} from "./styles";
import Button from "components/Button";

function AddCustomer({ setShowModal, handleCleanFilters }) {
  const { setLoading, globalUser } = useGeneralProvider();
  const userPassService = new DashboardService(globalUser);

  const [isNextEnabled, setIsNextEnabled] = useState(false);
  const [isConcludeEnabled, setIsConcludeEnabled] = useState(false);
  const [companyData, setCompanyData] = useState(initialCustomerValues);
  const [stepProgress, setStepProgress] = useState({
    1: true,
    2: false,
  });
  const [currentStep, setCurrentStep] = useState(1);

  useEffect(() => {
    handleEnableButtonNext();
    handleEnableButtonConclude();
  }, [companyData]);

  const isEmailvalid = (value) => {
    let reg = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
    if (value) {
      if (reg.test(value) === false) {
        return false;
      } else {
        return true;
      }
    } else {
      return true;
    }
  };

  const handleCreateCompany = () => {
    setLoading(true);
    userPassService
      .createCompany(companyData)
      .then(() => {
        toast.success("Cliente adicionado com sucesso!");
        handleCleanFilters();
        setShowModal(false);
      })
      .catch((err) => {
        setLoading(false);
        if (err?.response?.data?.message) {
          toast.error(err.response.data.message);
        } else {
          toast.error("Falha ao adicionar cliente");
        }
      });
  };

  const handleEnableButtonNext = () => {
    let auxIsNextEnabled = true;

    if (
      !companyData.company.companyName ||
      !companyData.company.companyEmail ||
      !isEmailvalid(companyData.company.companyEmail) ||
      !companyData.company.contactName ||
      !companyData.company.contactEmail ||
      !isEmailvalid(companyData.company.contactEmail)
    ) {
      auxIsNextEnabled = false;
    }

    if (
      companyData.company.companyTypeId === null ||
      companyData.company.companyStatus === null
    ) {
      auxIsNextEnabled = false;
    }

    setIsNextEnabled(auxIsNextEnabled);
  };

  const handleEnableButtonConclude = () => {
    let auxIsConcludeEnabled = true;

    if (!companyData.files.logo || !companyData.files.waterMark) {
      auxIsConcludeEnabled = false;
    }

    setIsConcludeEnabled(auxIsConcludeEnabled);
  };

  return (
    <BackDrop>
      <Container>
        <HeaderContainer>
          <HeaderCloseContainer>
            <ImageWrapper />
            <HeaderText>Adicionar Cliente</HeaderText>
            <ImageWrapper onClick={() => setShowModal(false)}>
              <CloseIcon src={CloseIconSvg} />
            </ImageWrapper>
          </HeaderCloseContainer>

          <ProgressContainer>
            <ProgressStepContainer
              completed={stepProgress[1]}
              onClick={() => {
                setStepProgress({
                  1: true,
                  2: false,
                });
                setCurrentStep(1);
              }}
            >
              <ProgressNumber>1</ProgressNumber>
              <ProgressText>Contato</ProgressText>
            </ProgressStepContainer>

            <ProgressLine completed={stepProgress[2]} />

            <ProgressStepContainer completed={stepProgress[2]}>
              <ProgressNumber>2</ProgressNumber>
              <ProgressText>Arquivos</ProgressText>
            </ProgressStepContainer>
          </ProgressContainer>
        </HeaderContainer>

        <BodyContainer>
          {currentStep === 1 ? (
            <ContactForm
              companyData={companyData}
              setCompanyData={setCompanyData}
              isEmailvalid={isEmailvalid}
            />
          ) : currentStep === 2 ? (
            <FilesForm
              companyData={companyData}
              setCompanyData={setCompanyData}
            />
          ) : null}
        </BodyContainer>

        <FooterContainer>
          {currentStep === 1 ? (
            <Button
              label={"Avançar"}
              disabled={!isNextEnabled}
              onClick={() => {
                setStepProgress({
                  1: true,
                  2: true,
                });
                setCurrentStep(2);
              }}
            />
          ) : null}

          {currentStep === 2 ? (
            <Button
              label={"Concluir"}
              disabled={!isConcludeEnabled}
              onClick={() => {
                handleCreateCompany();
              }}
            />
          ) : null}
        </FooterContainer>
      </Container>
    </BackDrop>
  );
}

export default AddCustomer;
