import React from "react";
import { toast } from "react-toastify";
import { MdEdit, MdPlayArrow } from "react-icons/md";

// Components
import Button from "components/Button";

// Imgs
import DefaultUser from "assets/default-user.png";

// Services
import DashboardService from "services/dashboard";

// Context
import { useGeneralProvider } from "contexts/general";

// Utils
import { getDefaultPalleteRgb, applyGaussianBlur } from "utils";

// Constants
import {
  minTemperatureCelsius,
  maxTemperatureCapCelsius
} from "../../../../../../components/ThermalManipulation/constants";

import {
  Container,
  HeaderText,
  GalleryContainer,
  GalleryImageContainer,
  GalleryImage,
  EmptyContainer,
  GroupContainer,
  GroupHeader,
  EditGroupIconContainer,
  GroupTitle,
  GroupImagesContainer,
  GroupImageWrapper,
  MoreInfoGroupImage,
  GroupImage,
  HeaderContainer,
  PlayVideo,
  ContainerGalleryVideo
} from "./styles";

const PALETTE_APPLIED_TITLE = 'Pallete applied';

function Gallery({
  groups,
  gallery,
  galleryVideo,
  handleGetImageByID,
  setShowModalDiagnosis,
  onGrouping,
  setOnGrouping,
  setSelectedGroup,
}) {
  const palleteRgb = getDefaultPalleteRgb();
  const { _, globalUser } = useGeneralProvider();
  const userPassService = new DashboardService(globalUser);

  const handleEditGroup = (group) => {
    setSelectedGroup(group);
    setOnGrouping(true);
  };

  const handleNewGroup = () => {
    setSelectedGroup(null);
    setOnGrouping(true);
  };

  const handleModalDiagnosis = (group) => {
    setOnGrouping(false);
    setShowModalDiagnosis(true);
    setSelectedGroup(group);
  };

  // Extrai o "raw" da imagem com base no RGB de cada pixel e calcula a temperatura
  const handleGetTemperature = (green, blue) => {
    const raw = green * 256 + blue;

    const M = 0.02569698246480104;
    const B = 64.74694198003411;

    const temp = M * raw - B;

    return temp;
  };

  const handleInitialMapping = (imageData) => {
    const dataLength = imageData.data.length;
    const pixelLength = 4;

    const temperaturePixelArray = [];

    for (let i = 0; i <= dataLength - pixelLength; i += pixelLength) {
      const green = imageData.data[i + 1];
      const blue = imageData.data[i + 2];

      const temp = handleGetTemperature(green, blue);

      if (temp >= minTemperatureCelsius && temp <= maxTemperatureCapCelsius) {
        temperaturePixelArray.push({ t: temp, p: i });
      }
    }

    const currentImageSortedData = temperaturePixelArray.sort((a, b) => {
      if (a.t < b.t) return -1;
      if (a.t > b.t) return 1;
      return 0;
    });

    return currentImageSortedData;
  };

  const handleTemperaturePerPixel = (imageData, currentImageValues) => {
    const temperaturePixelArray = [];

    for (let i = 0; i < currentImageValues.length; i++) {
      temperaturePixelArray.push(currentImageValues[i]);
    }
    return temperaturePixelArray;
  };

  const handlePixelTransform = (imageData, currentImageValues) => {
    const temperaturePixelArray = handleTemperaturePerPixel(imageData, currentImageValues);
    const expandedPalette = [];

    const pixelsPerColor = Math.ceil(
      temperaturePixelArray.length / palleteRgb.length
    );

    const reversedPalette = [...palleteRgb].reverse();

    for (let i = 0; i < reversedPalette.length; i++) {
      for (let j = 0; j < pixelsPerColor; j++) {
        expandedPalette.push(reversedPalette[i]);
      }
    }

    if (expandedPalette.length !== 0) {
      for (let i = 0; i < temperaturePixelArray.length; i++) {
        const currentColor = expandedPalette[i] || [0, 0, 0];

        // red
        imageData.data[temperaturePixelArray[i].p] = currentColor[0];
        // green
        imageData.data[temperaturePixelArray[i].p + 1] = currentColor[1];
        // blue
        imageData.data[temperaturePixelArray[i].p + 2] = currentColor[2];
      }
    }
    return imageData;
  };

  const validURL = (str) => {
    var pattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // domain name
      '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
      '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
      '(\\#[-a-z\\d_]*)?$','i'); // fragment locator
    return !!pattern.test(str);
  }

  const getTransformedImage = async (imageUrl) => {    
    let reload = false;
    if (validURL(imageUrl.target.currentSrc)) {
      const image = await userPassService.getImageBase64ByUrl(imageUrl.target.currentSrc);
      imageUrl.target.src = image.data;
      imageUrl.target.title = null;
      reload = true;
    }

    if (!reload && imageUrl.target.title !== PALETTE_APPLIED_TITLE) {
      // Create an empty canvas element
      const canvas = document.createElement("canvas");
      canvas.width = imageUrl.target.naturalWidth;
      canvas.height = imageUrl.target.naturalHeight;

      // Copy the image contents to the canvas
      const ctx = canvas.getContext("2d");
      ctx.drawImage(imageUrl.target, 0, 0);
      const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
      const currentImageValues = handleInitialMapping(imageData);
      const newImageData = handlePixelTransform(imageData, currentImageValues);
      applyGaussianBlur(imageData);
      ctx.putImageData(newImageData, 0, 0);
      imageUrl.target.title = PALETTE_APPLIED_TITLE;
      imageUrl.target.src = canvas.toDataURL();
    }  
  };

  return (
    <Container>
      <HeaderContainer>
        <HeaderText>Galeria</HeaderText>
        <Button
          label="Agrupar"
          onClick={handleNewGroup}
          disabled={onGrouping}
        />
      </HeaderContainer>

      <GalleryContainer>
        {groups &&
          groups.length !== 0 &&
          groups.map((group) => (
            <GroupContainer key={group.groupId}>
              <GroupHeader>
                <GroupTitle>{group.groupName}</GroupTitle>
                <EditGroupIconContainer
                  title="Editar"
                  onClick={() => {
                    handleEditGroup(group);
                  }}
                >
                  <MdEdit />
                </EditGroupIconContainer>
              </GroupHeader>

              <GroupImagesContainer onClick={() => handleModalDiagnosis(group)}>
                {group.groupImages &&
                  group.groupImages.slice(0, 4).map((imageId, index) => (
                    <GroupImageWrapper key={imageId}>
                      <GroupImage
                        src={handleGetImageByID(imageId) || DefaultUser}
                        onLoad={getTransformedImage}
                      />
                      {group.groupImages.length > 4 ? (
                        <MoreInfoGroupImage index={index}>
                          {`+${group.groupImages.length - 4}`}
                        </MoreInfoGroupImage>
                      ) : null}
                    </GroupImageWrapper>
                  ))}
              </GroupImagesContainer>
            </GroupContainer>
          ))}

        {gallery && gallery.length !== 0 ? (
          gallery.map((image) => (
            <GalleryImageContainer key={image.imageId}>
              <GalleryImage
                onClick={() =>
                  toast.warn(
                    "Imagens não agrupadas não estão habilitadas para diagnóstico."
                  )
                }
                src={image.thermalImageLink || DefaultUser}
                onLoad={getTransformedImage}
              />
            </GalleryImageContainer>
          ))
        ) : (
          <EmptyContainer>Sem imagens.</EmptyContainer>
        )}
        {galleryVideo && galleryVideo.length !== 0 ? (
          galleryVideo.map((video) => (
            <GalleryImageContainer key={video.videoId}>
              <ContainerGalleryVideo>
                <GalleryImage
                  onClick={() =>
                    toast.warn(
                      "Imagens não agrupadas não estão habilitadas para diagnóstico."
                    )
                  }
                  src={video.thermalImages[0].thermalImageLink || DefaultUser}
                  onLoad={video.thermalImages[0].thermalImageLink ? (event) => getTransformedImage(event) : null}
                />
                <PlayVideo 
                  onClick={() =>
                    toast.warn(
                      "Imagens não agrupadas não estão habilitadas para diagnóstico."
                    )
                  }>
                  <MdPlayArrow size={80} opacity={0.8} />
                </PlayVideo>
              </ContainerGalleryVideo>
            </GalleryImageContainer>
          ))
        ) : (
          <EmptyContainer>Sem vídeos.</EmptyContainer>
        )}
      </GalleryContainer>
    </Container>
  );
}

export default Gallery;
