import React from "react";

// Context
import { useGeneralProvider } from "contexts/general";

// Imgs
import ReloadIcon from "assets/reload-icon.svg";

import { Container, LabelText, ReloadImg } from "./styles";

function ReloadButton() {
  const { updateManually } = useGeneralProvider();

  return (
    <Container onClick={() => updateManually()}>
      <LabelText>Atualizar manual</LabelText>
      <ReloadImg src={ReloadIcon} />
    </Container>
  );
}

export default ReloadButton;
