import styled from "styled-components";

export const Container = styled.div`
  width: 38px;
  height: 38px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 10px;
  cursor: pointer;

  svg {
    fill: #a3a3a3;
    width: 28px;
    height: 28px;
  }

  :hover {
    opacity: 0.7;
  }
`;
