/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";

// Components
import SelectFilter from "components/SelectFilter";
import TextFilter from "components/TextFilter";
import Button from "components/Button";
import ButtonInverted from "components/ButtonInverted";
import Pagination from "components/Pagination";

import ComplexTable from "./components/ComplexTable";
import AddCustomerUser from "./components/AddCustomerUser";
import EditCustomerUser from "./components/EditCustomerUser";

// Imgs
import AddUserIcon from "assets/add-user-icon.svg";
import BackArrowIcon from "assets/back-arrow-icon.svg";

// Libs
import { toast } from "react-toastify";

// Context
import { useGeneralProvider } from "contexts/general";

// Services
import DashboardService from "services/dashboard";

// Constants
import { initialFiltersCustomerUsers } from "../../constants";

import {
  Container,
  ContentWrapper,
  FilterContainer,
  FooterContainer,
  ButtonsContainer,
  HeaderBackSection,
  HeaderBackArrowImg,
  HeaderBackText,
} from "./styles";

function CustomerUsers() {
  const history = useHistory();
  const location = useLocation();
  const {
    setLoading,
    globalUser,
    setCurrentSubtitle,
    setSelectedPage,
    isAdmin,
  } = useGeneralProvider();
  const userPassService = new DashboardService(globalUser);
  const [data, setData] = useState([]);
  const [status, setStatus] = useState([
    { id: 1, name: "Ativo", checked: true },
    { id: 0, name: "Inativo", checked: false }
  ]);
  const [searchText, setSearchText] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [selectedItem, setSelectedItem] = useState("");
  const [companyId, setCompanyId] = useState();
  const [companyName, setCompanyName] = useState("");

  const [canBeCleaned, setCanBeCleaned] = useState(false);
  const [currentFilters, setCurrentFilters] = useState(
    initialFiltersCustomerUsers
  );

  const [showAddCustomerUser, setShowAddCustomerUser] = useState(false);
  const [showEditCustomerUser, setShowEditCustomerUser] = useState(false);

  useEffect(() => {
    verifyAdminUser();
    setSelectedPage("/clientes");
    setCurrentSubtitle("");
  }, []);

  useEffect(() => {
    const { companyId, companyName } = location.state;

    if (companyId && companyName) {
      setCompanyId(companyId);
      setCompanyName(companyName);
    } else {
      toast.error(
        "Não foi possível identificar o cliente. Você será redirecionado"
      );
      history.push("/clientes");
    }
  }, [location]);

  useEffect(() => {
    if (companyId) {
      handleUpdateInfoRequests();
    }
  }, [companyId]);

  const verifyAdminUser = () => {
    if (!isAdmin) {
      toast.error(
        "O usuário não possui acesso a esta tela. Você será redirecionado"
      );
      history.push("/");
    }
  };

  const handleUpdateInfoRequests = (updatePage, requestWithCleanFilters) => {
    setLoading(true);
    Promise.all([
      userPassService
        .getCompanyUsers(
          requestWithCleanFilters
            ? { ...initialFiltersCustomerUsers, companyId: companyId }
            : {
                ...currentFilters,
                companyId: companyId,
                page: updatePage ? updatePage : currentFilters.page,
              }
        )
        .then((o) => {
          if (o.data) {
            const response = o.data;
            if (response.data) {
              const auxData = response.data;
              setData(auxData);
            } else {
              setData([]);
            }
            if (response.totalPages) {
              setTotalPages(response.totalPages);
            } else {
              setTotalPages(1);
            }
            if (response.currentPage) {
              setCurrentPage(response.currentPage);
            } else {
              setCurrentPage(1);
            }
            return;
          }
          setData([]);
        })
        .catch(() => {
          toast.error("Falha ao buscar usuários do cliente");
        }),
    ])
      .then(() => {})
      .catch(() => {})
      .finally(() => {
        if (!requestWithCleanFilters) {
          handleManageFiltering();
        }
        setLoading(false);
      });
  };

  const handleCleanFilters = () => {
    const newFilters = {
      ...initialFiltersCustomerUsers,
      page: currentFilters.page
    };
    setCurrentFilters(newFilters);
    setStatus([
      { id: 1, name: "Ativo", checked: true },
      { id: 0, name: "Inativo", checked: false }
    ]);

    setSearchText("");
    setCanBeCleaned(false);

    handleUpdateInfoRequests(null, false, currentFilters.companyId);
  };

  const handleManageFiltering = () => {
    let auxCanBeCleaned = false;

    if (
      JSON.stringify(initialFiltersCustomerUsers.status) !==
      JSON.stringify(currentFilters.status)
    ) {
      auxCanBeCleaned = true;
    }

    if (initialFiltersCustomerUsers.textSearch !== currentFilters.textSearch) {
      auxCanBeCleaned = true;
    }

    setCanBeCleaned(auxCanBeCleaned);
  };

  return (
    <Container>
      {showAddCustomerUser ? (
        <AddCustomerUser
          setShowModal={setShowAddCustomerUser}
          handleCleanFilters={handleCleanFilters}
          companyId={companyId}
        />
      ) : null}

      {showEditCustomerUser ? (
        <EditCustomerUser
          setShowModal={setShowEditCustomerUser}
          selectedItem={selectedItem}
          handleCleanFilters={handleCleanFilters}
          companyId={companyId}
        />
      ) : null}

      <ContentWrapper>
        <HeaderBackSection>
          <HeaderBackArrowImg
            src={BackArrowIcon}
            onClick={() => {
              history.goBack();
            }}
          />
          <HeaderBackText>{`${companyName} • Usuários`}</HeaderBackText>
        </HeaderBackSection>

        <FilterContainer>
          <SelectFilter
            label={"Situação"}
            options={status}
            setOptions={(value) => {
              let toSearchArray = [];
              setCanBeCleaned(false);

              value.forEach((item) => {
                if (item.checked) {
                  toSearchArray.push(item.id);
                }
              });

              setCurrentFilters({
                ...currentFilters,
                page: 1,
                status: toSearchArray,
              });

              setStatus(value);
            }}
          />
          <TextFilter
            searchText={searchText}
            setSearchText={(value) => {
              setCurrentFilters({
                ...currentFilters,
                page: 1,
                textSearch: value,
              });

              setSearchText(value);
            }}
            placeHolder={"Busque por Nome ou E-mail"}
          />
          {canBeCleaned ? (
            <Button label={"Limpar"} onClick={() => handleCleanFilters()} />
          ) : (
            <Button
              label={"Pesquisar"}
              onClick={() => handleUpdateInfoRequests()}
            />
          )}
        </FilterContainer>

        <ComplexTable
          data={data}
          setData={setData}
          companyId={companyId}
          selectedItem={selectedItem}
          setSelectedItem={setSelectedItem}
          setShowEditCustomerUser={setShowEditCustomerUser}
          handleCleanFilters={handleCleanFilters}
        />

        <FooterContainer>
          <ButtonsContainer>
            <ButtonInverted
              label="Adicionar Usuário"
              icon={AddUserIcon}
              onClick={() => setShowAddCustomerUser(true)}
            />
          </ButtonsContainer>

          <Pagination
            currentPage={currentPage}
            onPageChange={(page) => {
              setCurrentPage(page);
              handleUpdateInfoRequests(page);
              setCurrentFilters({
                ...currentFilters,
                page: page,
              });
            }}
            totalPages={totalPages}
          />
        </FooterContainer>
      </ContentWrapper>
    </Container>
  );
}

export default CustomerUsers;
