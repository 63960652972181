import React from "react";

// Libs
import { useHistory } from "react-router-dom";

// Imgs
import SuccessImg from "assets/success-img.svg";

// Components
import Button from "components/Button";

import {
  SuccessImgTag,
  TextSuccessHeader,
  TextSuccessBody,
  LoginBottomContainer,
} from "./styles";

function SuccessPassword() {
  const history = useHistory();

  return (
    <>
      <SuccessImgTag src={SuccessImg} />

      <TextSuccessHeader>A sua nova senha foi criada!</TextSuccessHeader>
      <TextSuccessBody>
        Agora é só fazer o login de novo para acessar sua conta.
      </TextSuccessBody>
      <LoginBottomContainer>
        <Button onClick={() => history.push("/")} label={"Login"} />
      </LoginBottomContainer>
    </>
  );
}

export default SuccessPassword;
