/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";

// Components
import ComplexTable from "./components/ComplexTable";
import ModalCollectPanel from "./components/ModalCollectPanel";

import InfoCard from "components/InfoCard";
import SelectFilter from "components/SelectFilter";
import SelectFilterSimple from "components/SelectFilterSimple"
import RangeDatePicker from "components/RangeDatePicker";
import TextFilter from "components/TextFilter";
import Button from "components/Button";
import ButtonInverted from "components/ButtonInverted";
import Pagination from "components/Pagination";
import ModalSaveList from "components/ModalSaveList";

// Imgs
import SaveBtnIcon from "assets/save-btn-icon.svg";

// Libs
import { toast } from "react-toastify";

// Context
import { useGeneralProvider } from "contexts/general";

// Services
import DashboardService from "services/dashboard";

// Constants
import {
  resultsENUM,
  reportGenerationStatusENUM,
  initialFlowFiltersClient,
} from "../../constants";

// Utils
import { calcPercentage, cleanSelections } from "utils";

import {
  Container,
  ContentWrapper,
  InfoCardContainer,
  FilterContainer,
  FooterContainer,
  ButtonsContainer,
} from "./styles";

function ScientificPanel() {
  const {
    setLoading,
    globalUser,
    setCurrentSubtitle,
    timerFunction,
    setSelectedPage,
    updateCharts,
    setUpdateCharts,
    isAdmin
  } = useGeneralProvider();
  const userPassService = new DashboardService(globalUser);
  const [data, setData] = useState([]);
  const [screeningCollect, setScreeningCollect] = useState({});
  const [normalPercentage, setNormalPercentage] = useState(null);
  const [changedPercentage, setChangedPercentage] = useState(null);

  const [segments, setSegments] = useState([]);
  const [companyIds, setCompanyIds] = useState([]);
  const [reportStatuses, setReportStatuses] = useState(reportGenerationStatusENUM);
  const [devices, setDevices] = useState([]);
  const [results, setResults] = useState(resultsENUM);
  const [searchText, setSearchText] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [selectedItem, setSelectedItem] = useState("");
  const [gridItem, setGridItem] = useState([]);

  const [canBeCleaned, setCanBeCleaned] = useState(false);
  const [currentFilters, setCurrentFilters] = useState(initialFlowFiltersClient);
  const [resetDatePicker, setResetDatePicker] = useState(false);
  
  const [showModalCollect, setShowModalCollect] = useState(false);
  const [showModalSaveList, setShowModalSaveList] = useState(false);
  const [showInfoCards, setShowInfoCards] = useState(false);
  const [showFilterDevice, setShowFilterDevice] = useState(false);
  const [showFilterPeriod, setShowFilterPeriod] = useState(false);
  const [segmentSelected, setSegmentSelected] = useState(0);

  useEffect(() => {
    setSelectedPage("/painel-laudo");
    setCurrentSubtitle("Laudo");
  }, []);

  useEffect(() => {
    handleUpdateInfoRequests();
  }, [timerFunction]);

  useEffect(() => {
    setNormalPercentage(
      calcPercentage(
        screeningCollect.totalScreenings,
        screeningCollect.normalScreenings
      )
    );
    setChangedPercentage(
      calcPercentage(
        screeningCollect.totalScreenings,
        screeningCollect.failedScreenings
      )
    );
  }, [screeningCollect]);

  const showFilters = () => {

    setShowFilterPeriod(false);
    setShowFilterDevice(false);

    gridItem.forEach((item) => {
      if (item.content === 'period') {
        setShowFilterPeriod(true);
      }
      if (item.content === 'device') {
        setShowFilterDevice(true);
      }
    })

  }

  const showSegmentsFilter = () => {
    if (currentFilters.segments[0] === 1) {
      setShowInfoCards(true);
    } else {
      setShowInfoCards(false);
    }
  }

  const handleUpdateInfoRequests = (updatePage, requestWithCleanFilters) => {
    setLoading(true);

    showSegmentsFilter();
    showFilters();

    Promise.all([
      userPassService
        .getCardsFlowData(
          requestWithCleanFilters
            ? initialFlowFiltersClient
            : {
                ...currentFilters,
                page: updatePage ? updatePage : currentFilters.page,
              }
        )
        .then((o) => {
          if (o.data) {
            setScreeningCollect(o.data);
            return;
          }

          setScreeningCollect({});
        })
        .catch(() => {
          toast.error("Falha ao buscar estatísticas de triagem");
        }),
      userPassService
        .getGridFlowData(
          requestWithCleanFilters
            ? initialFlowFiltersClient
            : {
                ...currentFilters,
                page: updatePage ? updatePage : currentFilters.page,
              }
        )
        .then((o) => {
          if (o.data) {
            const response = o.data;
            if (response.data && response.data.length > 0) {
              const auxData = response.data;
              const auxGridItem = []

              setData(auxData);

              if (auxData[0].device !== undefined) {
                auxGridItem.push({label: 'Dispositivo', content: 'device'})
              } if (auxData[0].identifier !== undefined) {
                auxGridItem.push({label: 'Identificador', content: 'identifier'})
              } if (auxData[0].period !== undefined) {
                auxGridItem.push({label: 'Data e Hora', content: 'period'})
              }

              setGridItem(auxGridItem);
            } else {
              setData([]);
            }
            if (response.totalPages) {
              setTotalPages(response.totalPages);
            } else {
              setTotalPages(1);
            }
            if (response.currentPage) {
              setCurrentPage(response.currentPage);
            } else {
              setCurrentPage(1);
            }
            return;
          }
          setData([]);
        })
        .catch((err) => {
          toast.error("Falha ao buscar registros");
        }),
      userPassService
        .getFlowGridFilters(segmentSelected !== 0 ? {segments: [segmentSelected]} : {segments: []})
        .then((o) => {
          if (o.data) {
            const response = o.data;

            if (response.devices) {
              response.devices.forEach((responseDevice) => {
                if (currentFilters.devices.includes(responseDevice.id)) {
                  responseDevice.checked = true;
                }
              });

              setDevices(response.devices);
            } else {
              setDevices([]);
            }

            if (response.companies) {
              setCompanyIds(response.companies);
            } else {
              setCompanyIds([])
            }

            return;
          }

          setDevices([]);
          setCompanyIds([]);
        })
        .catch(() => {
          toast.error("Falha ao buscar dispositivos");
        }),
      userPassService
        .getFlowTypes()
        .then((o) => {
          if (o.data) {
            const response = o.data;
            if (response) {

              setSegments(response);
            } else {

              setSegments([]);
            }

            return;
          }

          setSegments([]);
        })
        .catch(() => {
          toast.error("Falha ao buscar segmentos");
        }),
    ])
      .then(() => {})
      .catch(() => {})
      .finally(() => {
        if (!requestWithCleanFilters) {
          handleManageFiltering();
        }
        setLoading(false);
        setUpdateCharts(!updateCharts);
      });
  };

  const handleCleanFilters = () => {
    setCurrentFilters({ ...initialFlowFiltersClient });
    setDevices(cleanSelections(devices));
    setSegments(cleanSelections(segments));
    setReportStatuses(cleanSelections(reportStatuses));
    setResults(cleanSelections(results));
    setResetDatePicker(!resetDatePicker);
    setSearchText("");
    setCanBeCleaned(false);

    handleUpdateInfoRequests(null, true);
  };

  const handleManageFiltering = () => {
    let auxCanBeCleaned = false;

    if (
      JSON.stringify(initialFlowFiltersClient.devices) !==
      JSON.stringify(currentFilters.devices)
    ) {
      auxCanBeCleaned = true;
    }

    if (initialFlowFiltersClient.periodFrom !== currentFilters.periodFrom) {
      auxCanBeCleaned = true;
    }

    if (initialFlowFiltersClient.periodTo !== currentFilters.periodTo) {
      auxCanBeCleaned = true;
    }

    if (
      JSON.stringify(initialFlowFiltersClient.results) !==
      JSON.stringify(currentFilters.results)
    ) {
      auxCanBeCleaned = true;
    }

    if (
      JSON.stringify(initialFlowFiltersClient.reasons) !==
      JSON.stringify(currentFilters.reasons)
    ) {
      auxCanBeCleaned = true;
    }

    if (
      JSON.stringify(initialFlowFiltersClient.progress) !==
      JSON.stringify(currentFilters.progress)
    ) {
      auxCanBeCleaned = true;
    }

    if (initialFlowFiltersClient.textSearch !== currentFilters.textSearch) {
      auxCanBeCleaned = true;
    }

    setCanBeCleaned(auxCanBeCleaned);
  };

  return (
    <Container>
      {showModalCollect ? (
        <ModalCollectPanel
          key={showModalCollect}
          showModal={showModalCollect}
          setShowModal={setShowModalCollect}
          selectedItem={selectedItem}
          handleUpdateInfoRequests={handleUpdateInfoRequests}
          segments={selectedItem.screeningSegment}
        />
      ) : null}

      {showModalSaveList ? (
        <ModalSaveList
          setShowModal={setShowModalSaveList}
          currentFilters={currentFilters}
          panelType={1}
        />
      ) : null}

      <ContentWrapper>
        <FilterContainer>
          <SelectFilterSimple
              label={"Segmentos"}
              options={segments}
              setOptions={(value) => {
                let toSearchArray = [];
                setCanBeCleaned(false);

                value.forEach((item) => {
                  if (item.checked) {
                    toSearchArray.push(item.id);
                    setSegmentSelected(item.id);
                  }
                });

                setCurrentFilters({
                  ...currentFilters,
                  page: 1,
                  segments: toSearchArray,
                });

                setSegments(value);
              }}
            />
          {isAdmin ? (
            <SelectFilter
              label={"Empresas"}
              options={companyIds}
              setOptions={(value) => {
                let toSearchArray = [];
                setCanBeCleaned(false);

                value.forEach((item) => {
                  if (item.checked) {
                    toSearchArray.push(item.id);
                  }
                });

                setCurrentFilters({
                  ...currentFilters,
                  page: 1,
                  companyIds: toSearchArray,
                });

                setCompanyIds(value);
              }}
            />
        ) : null }
          <SelectFilter
              label={"Laudo Gerado?"}
              options={reportStatuses}
              setOptions={(value) => {
                let toSearchArray = [];
                setCanBeCleaned(false);

                value.forEach((item) => {
                  if (item.checked) {
                    toSearchArray.push(item.id);
                    setReportStatuses(item.id);
                  }
                });

                setCurrentFilters({
                  ...currentFilters,
                  page: 1,
                  reportStatuses: toSearchArray,
                });

                setReportStatuses(value);
              }}
            />
        </FilterContainer>
        
        {showInfoCards ? (
        <InfoCardContainer>
          <InfoCard
            total={screeningCollect?.totalScreenings || 0}
            type={"analysis"}
          />
          <InfoCard
            total={screeningCollect?.normalScreenings || 0}
            type={"normal"}
            percentage={normalPercentage}
          />
          <InfoCard
            total={screeningCollect?.failedScreenings || 0}
            type={"changedNoSelected"}
            percentage={changedPercentage}
          />
        </InfoCardContainer>
        ) : null}

        <FilterContainer>
          {showFilterDevice ? (
            <SelectFilter
              label={"Dispositivo"}
              options={devices}
              setOptions={(value) => {
                let toSearchArray = [];
                setCanBeCleaned(false);

                value.forEach((item) => {
                  if (item.checked) {
                    toSearchArray.push(item.id);
                  }
                });

                setCurrentFilters({
                  ...currentFilters,
                  page: 1,
                  devices: toSearchArray,
                });

                setDevices(value);
              }}
            />
          ) : null}

          {showFilterPeriod ? (
            <RangeDatePicker
              label={"Período"}
              setSelectedDate={(value) => {
                setCanBeCleaned(false);

                setCurrentFilters({
                  ...currentFilters,
                  page: 1,setCurrentFilters,
                  periodFrom: value?.start || "",
                  periodTo: value?.end || "",
                });
              }}
              resetDatePicker={resetDatePicker}
            />
          ) : null}

          <TextFilter
            searchText={searchText}
            setSearchText={(value) => {
              setCurrentFilters({
                ...currentFilters,
                page: 1,
                textSearch: value,
              });

              setSearchText(value);
            }}
            placeHolder={"Busque por Análise ou Identificador"}
          />
          
          {canBeCleaned ? (
            <Button label={"Limpar"} onClick={() => handleCleanFilters()} />
          ) : (
            <Button
              label={"Pesquisar"}
              onClick={() => handleUpdateInfoRequests()}
            />
          )}
        </FilterContainer>

        <ComplexTable
          data={data}
          setData={setData}
          setSelectedItem={setSelectedItem}
          setShowModal={setShowModalCollect}
          gridItem={gridItem}
        />

        <FooterContainer>
          <ButtonsContainer>
            {data && data.length !== 0 ? (
              <ButtonInverted
                label="Salvar lista"
                icon={SaveBtnIcon}
                onClick={() => setShowModalSaveList(true)}
              />
            ) : null}
          </ButtonsContainer>

          <Pagination
            currentPage={currentPage}
            onPageChange={(page) => {
              setCurrentPage(page);
              handleUpdateInfoRequests(page);
              setCurrentFilters({
                ...currentFilters,
                page: page,
              });
            }}
            totalPages={totalPages}
          />
        </FooterContainer>
      </ContentWrapper>
    </Container>
  );
}

export default ScientificPanel;
