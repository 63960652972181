import styled from "styled-components";

export const Container = styled.div`
  color: ${(props) => props.color && props.color};
  background-color: ${(props) => props.backColor && props.backColor};
  width: 100px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 24px;
`;

export const Text = styled.p`
  margin: 0;
  padding: 0;
  font-size: 12px;
`;
