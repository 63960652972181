import styled from "styled-components";

export const BackDrop = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(56, 57, 61, 0.2);
  z-index: 999;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
`;

export const Container = styled.div`
  background-color: #fefefe;
  width: 440px;
  border-radius: 4px;
  padding: 20px 20px 24px 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  width: 100%;
  margin-bottom: 30px;
`;

export const Body = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Input = styled.input`
  outline: none;
  border-radius: 4px;
  height: 40px;
  width: 264px;
  border: 1px solid #e0e0e0;
  padding: 0 8px 0 8px;
`;

export const InputLabel = styled.p`
  color: #1a1a1a;
  font-size: 14px;
  margin-bottom: 6px;
`;

export const ImageWrapper = styled.div`
  cursor: pointer;
  width: 24px;

  :hover {
    opacity: 0.7;
  }
`;

export const CloseIcon = styled.img`
  width: 24px;
  height: 24px;
  user-select: none;
  pointer-events: none;
`;

export const HeaderText = styled.div`
  font-size: 16px;
  color: #38393d;
`;

export const FooterContainer = styled.div`
  margin-top: 30px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
`;

export const SuccessImg = styled.img`
  width: 50px;
  height: 59px;
  align-self: center;
  margin-bottom: 10px;
`;
