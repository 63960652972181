import styled from "styled-components";

const CustomDivScroll = styled.div`
  ::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }
  ::-webkit-scrollbar-button {
    width: 0px;
    height: 0px;
  }
  ::-webkit-scrollbar-thumb {
    background: #0073ab;
    border-radius: 20px;
  }
  ::-webkit-scrollbar-thumb:hover {
    background: #0073ab;
  }
  ::-webkit-scrollbar-thumb:active {
    background: #0073ab;
  }
  ::-webkit-scrollbar-track {
    background: transparent;
    border-radius: 50px;
  }
  ::-webkit-scrollbar-track:hover {
    background: transparent;
  }
  ::-webkit-scrollbar-track:active {
    background: transparent;
  }
  ::-webkit-scrollbar-corner {
    background: transparent;
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

export const GalleryText = styled.p`
  color: #38393d;
  font-size: 18px;
  font-weight: bolder;
  color: #1a1a1a;
  align-self: flex-start;
  margin-bottom: 15px;
`;

export const ToolsText = styled.p`
  font-size: 18px;
  font-weight: bolder;
  margin-bottom: 34px;
  color: #1a1a1a;
  align-self: flex-start;
  margin-bottom: 6px;
`;

export const GalleryContainer = styled(CustomDivScroll)`
  height: 100%;
  width: 100%;
  min-width: 168px;
  padding-right: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;

  overflow-y: auto;
`;

export const GalleryImage = styled.img`
  width: 138px;
  height: 138px;
  object-fit: cover;
  position: absolute;
  overflow-x: hidden;
  transition: all 1s;

  :last-of-type {
    margin: 0;
  }
`;

export const GalleryImageContainer = styled.div`
  border-radius: 20px;
  min-width: 138px;
  min-height: 138px;
  margin-bottom: 20px;
  overflow: hidden;
  border: 1px solid #e1e1e1;
  position: relative;
  user-select: none;
  overflow-x: hidden;
  transition: all 1s;

  opacity: ${({ disabled }) => (disabled ? 0.4 : 1)};
  pointer-events: ${({ disabled }) => (disabled ? "none" : "unset")};
`;

export const ExpandContainer = styled.div`
  background-color: rgba(0, 0, 0, 0.4);
  display: flex;
  width: 138px;
  height: 138px;
  justify-content: center;
  align-items: center;
  position: absolute;
  opacity: 0;
  transition: all 0.2s;

  :hover {
    opacity: 1;
    cursor: pointer;
  }

  svg {
    fill: #ffffff;
    width: 34px;
    height: 34px;
  }
`;

export const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: start;
`;

export const ToogleDrawer = styled.div`
  display: flex;
  padding-right: 10px;
  text-align: center;
`;

export const ActionIcon = styled.img`
  width: 20px;
  height: 20px;
  pointer-events: cursor;
  overflow-x: hidden;
  transition: all 1s;
`;

export const DeltaBtnWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 10px;
  margin-top: 10px;
`;

export const CleanDeltaTextBtn = styled.p`
  font-size: 14px;
  cursor: pointer;
  text-decoration: underline;
  margin-bottom: 10px;
`;

export const ContainerButtonToggle = styled.div`
  display: flex;
  padding-left: 20px;
`;
