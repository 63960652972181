import styled from "styled-components";

export const Container = styled.div`
  /* display: flex; */
  flex-direction: row;
  display: inline-flex;
`;

export const IconContainer = styled.div`
  width: 28px;
  height: 28px;
  margin-right: 6px;
`;

export const IconImage = styled.img`
  width: 28px;
  height: 28px;
  user-select: none;
  pointer-events: none;
`;

export const ImageWrapper = styled.div``;
