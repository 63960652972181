import styled from "styled-components";

export const Container = styled.div`
  width: auto;
  margin-bottom: 4px;
`;

export const ButtonContainer = styled.div`
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 8px 0 8px;
  background-color: ${(props) => (props.isOnFocus ? "#dddddd" : "#f9f9f9")};
  border-radius: 4px;
  cursor: pointer;
  margin-right: 8px;
  user-select: none;

  :hover {
    background-color: #dddddd;
  }
`;

export const TextButton = styled.p`
  margin: 0;
  padding: 0;
  font-size: 16px;
  font-weight: normal;
  color: #525252;
  white-space: nowrap;
`;

export const ArrowIcon = styled.img`
  width: 24px;
  height: 24px;
  user-select: none;
  transform: ${(props) =>
    props.isOnFocus ? "rotate(180deg)" : "rotate(0deg)"};
  transition: all 0.3s;
`;
