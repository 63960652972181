import styled from "styled-components";

export const Container = styled.div`
  padding: 0 4px;
  min-width: fit-content;
`;

export const CustomButton = styled.button`
  cursor: ${(props) => (props.disabled ? "default" : "pointer")};
  padding: ${(props) => (props.menuIsLeft ? "0 10px 0 10px" : "0 24px 0 24px")}; ;
  height: 40px;
  color: ${(props) => (props.disabled ? "#a2a2a2" : "#ffffff")};
  font-size: 16px;
  font-weight: bold;
  background-color: ${(props) => (props.disabled ? "#F1F1F1" : "#0073AB")};
  border: none;
  outline: none;
  border-radius: 4px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  user-select: none;
  min-width: fit-content;

  :hover {
    background-color: ${(props) => (props.disabled ? "#F1F1F1" : "#1b6091")};
  }
`;
