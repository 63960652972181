import React from 'react'
import * as Styled from './styled'

// DOC https://mui.com/pt/material-ui/api/popover/

export const DropDown = ({ items, title, ...props }) => {
    return (
        <Styled.Drop {...props} >
            {title && title}
            <Styled.List>
                {items && Array.from(items).map((x) => {
                    return (
                        <Styled.Item key={x.id} onClick={x.onClick} >{x.label}</Styled.Item>
                    )
                })}
            </Styled.List>
        </Styled.Drop>
    )
}