import React from "react";

import { TH } from "../../styles";
import { IconImg, Container } from "./styles";

import SortIcon from "assets/sort-solid.svg";

function HeaderSort({ label, sortContent }) {
  return (
    <TH>
      <Container onClick={() => sortContent()}>
        {label}
        <IconImg src={SortIcon} />
      </Container>
    </TH>
  );
}

export default HeaderSort;
