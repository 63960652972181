/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable default-case */
import React, { useRef, useState, useEffect } from "react";

// Libs
import { Layer, Line, Rect, Text, Transformer } from "react-konva";

const FIRST_CAPTURE_AREA = {
  stroke: "red",
  width: 40,
  height: 40,
  draggable: true,
  x: 100,
  y: 100,
};

const SECOND_CAPTURE_AREA = {
  stroke: "red",
  width: 40,
  height: 40,
  draggable: true,
  x: 300,
  y: 100,
};

const RESULT_AREA = {
  stroke: "red",
  width: 100,
  height: 30,
  draggable: true,
  x: 180,
  y: 300,
};

function DeltaCalculation({
  setFirstAreaProps,
  setSecondAreaProps,
  clearSelected,
  firstAreaMaxTemperature,
  firstAreaMinTemperature,
  firstAreaAvgTemperature,
  secondAreaMaxTemperature,
  secondAreaMinTemperature,
  secondAreaAvgTemperature,
  formRect,
}) {
  const [firstAreaCurrentInfo, setFirstAreaCurrentInfo] =
    useState(FIRST_CAPTURE_AREA);
  const [secondAreaCurrentInfo, setSecondAreaCurrentInfo] =
    useState(SECOND_CAPTURE_AREA);
  const [resultAreaProps, setResultAreaProps] = useState(RESULT_AREA);
  const [resultAreaValue, setResultAreaValue] = useState(0);
  const [selectedRectFirst, setSelectedRectFirst] = useState(null);
  const [selectedRectSecond, setSelectedRectSecond] = useState(null);

  const firstCollectorRef = useRef(null);
  const secondCollectorRef = useRef(null);
  const resultShapeRef = useRef(null);
  const transformerRef = useRef(null);

  useEffect(() => {
    if (clearSelected) {
      setSelectedRectSecond(false);
      setSelectedRectFirst(false);
    }
  }, [clearSelected]);

  useEffect(() => {
    if (selectedRectFirst && transformerRef.current) {
      transformerRef.current.nodes([firstCollectorRef.current]);
      transformerRef.current.getLayer().batchDraw();
    }
  }, [selectedRectFirst]);

  useEffect(() => {
    if (selectedRectSecond && transformerRef.current) {
      transformerRef.current.nodes([secondCollectorRef.current]);
      transformerRef.current.getLayer().batchDraw();
    }
  }, [selectedRectSecond]);

  useEffect(() => {
    if (firstAreaMaxTemperature && secondAreaMaxTemperature) {
      handleResultAreaValue();
    } else {
      setResultAreaValue(0);
    }
  }, [firstAreaMaxTemperature, secondAreaMaxTemperature]);

  const handleDrag = (e, oldProps, setProps) => {
    const x = e.target.x();
    const y = e.target.y();
    const width = e.target.width();
    const height = e.target.height();

    setProps({ ...oldProps, x, y, width, height });
  };

  const handleResultAreaValue = () => {
    let highestValue = firstAreaMaxTemperature;
    let lowerValue = secondAreaMaxTemperature;

    if (secondAreaMaxTemperature > firstAreaMaxTemperature) {
      highestValue = secondAreaMaxTemperature;
      lowerValue = firstAreaMaxTemperature;
    }

    setResultAreaValue(highestValue - lowerValue);
  };

  const TEXT_SIZE = 12;
  const Y_POSITION_OFFSET = 15;

  function infoTemp(position, areaMaxTemp, areaMinTemp, areaAvgTemp, leftAlign) {
    const xAxisPosition = leftAlign 
      ? position.x - 130
      : position.x + position.width;

    let area = "";

    if (formRect == 'square') {
      area = position ? position.width.toFixed(0) + '² px' : 0 + '² px'
    } else {
      area = position ? 'π ' + (position.width / 2).toFixed(0) + '² px' : 0 + '² px'
    }

    return (
      <div>
        <div>
          <div>
            <Text
              x={xAxisPosition}
              y={position.y}
              text={'Max'}
              fontSize={TEXT_SIZE}
              height={TEXT_SIZE}
              stroke="black"
              strokeWidth={2}
              fill="white"
              fillAfterStrokeEnabled={true}
            />
            </div>
          <div>
            <Text
              x={xAxisPosition + 80}
              y={position.y}
              text={areaMaxTemp ? areaMaxTemp.toFixed(1) + '°C' : 0.0 + '°C'}
              fontSize={TEXT_SIZE}
              height={TEXT_SIZE}
              stroke="black"
              strokeWidth={4}
              fill="white"
              fillAfterStrokeEnabled={true}
            />
          </div>
        </div>
        <div>
          <div>
            <Text
              x={xAxisPosition}
              y={position.y + Y_POSITION_OFFSET}
              text={'Min'}
              fontSize={TEXT_SIZE}
              height={TEXT_SIZE}
              stroke="black"
              strokeWidth={2}
              fill="white"
              fillAfterStrokeEnabled={true}
            />
          </div>
          <div>
            <Text
              x={xAxisPosition + 80}
              y={position.y + Y_POSITION_OFFSET}
              text={areaMinTemp ? areaMinTemp.toFixed(1) + '°C' : 0.0 + '°C'}
              fontSize={TEXT_SIZE}
              height={TEXT_SIZE}
              stroke="black"
              strokeWidth={4}
              fill="white"
              fillAfterStrokeEnabled={true}
            />
          </div>
        </div>
        <div>
          <div>
            <Text
              x={xAxisPosition}
              y={position.y + 2*Y_POSITION_OFFSET}
              text={'Average'}
              fontSize={TEXT_SIZE}
              height={TEXT_SIZE}
              stroke="black"
              strokeWidth={2}
              fill="white"
              fillAfterStrokeEnabled={true}
            />
          </div>
          <div>
            <Text
              x={xAxisPosition + 80}
              y={position.y + 2*Y_POSITION_OFFSET}
              text={areaAvgTemp ? areaAvgTemp.toFixed(1) + '°C' : 0.0 + '°C'}
              fontSize={TEXT_SIZE}
              height={TEXT_SIZE}
              stroke="black"
              strokeWidth={4}
              fill="white"
              fillAfterStrokeEnabled={true}
            />
          </div>
        </div>
        <div>
          <div>
            <Text
              x={xAxisPosition}
              y={position.y + 3*Y_POSITION_OFFSET}
              text={'Pixels'}
              fontSize={TEXT_SIZE}
              height={TEXT_SIZE}
              stroke="black"
              strokeWidth={2}
              fill="white"
              fillAfterStrokeEnabled={true}
            />
          </div>
          <div>
            <Text
              x={xAxisPosition + 80}
              y={position.y + 3*Y_POSITION_OFFSET}
              text={
                area
              }
              fontSize={TEXT_SIZE}
              height={TEXT_SIZE}
              stroke="black"
              strokeWidth={4}
              fill="white"
              fillAfterStrokeEnabled={true}
            />
          </div>
        </div>
      </div>
    )
  };

  return (
    <Layer>
      {/* Linhas de conexão */}
      <Line
        ref={firstCollectorRef}
        points={[
          resultAreaProps.x + resultAreaProps.width / 2,
          resultAreaProps.y,
          firstAreaCurrentInfo.x + firstAreaCurrentInfo.width / 2,
          firstAreaCurrentInfo.y + firstAreaCurrentInfo.height,
        ]}
        stroke="red"
      />
      <Line
        ref={secondCollectorRef}
        points={[
          resultAreaProps.x + resultAreaProps.width / 2,
          resultAreaProps.y,
          secondAreaCurrentInfo.x + secondAreaCurrentInfo.width / 2,
          secondAreaCurrentInfo.y + secondAreaCurrentInfo.height,
        ]}
        stroke="red"
      />

      {/* Área de captura 1 */}

      {/*Marcação central do círculo - mira*/}
      <Rect
        ref={firstCollectorRef}
        {...{  fill: 'red',
        width: 1,
        height: 8,
        draggable: true,
        x: firstAreaCurrentInfo.x + firstAreaCurrentInfo.width/2,
        y: firstAreaCurrentInfo.y + firstAreaCurrentInfo.height/2 - 4,}}
        cornerRadius={100}
      />
      <Rect
        ref={firstCollectorRef}
        {...{  fill: 'red',
        width: 8,
        height: 1,
        draggable: true,
        x: firstAreaCurrentInfo.x + firstAreaCurrentInfo.width/2 - 4,
        y: firstAreaCurrentInfo.y + firstAreaCurrentInfo.height/2,}}
        cornerRadius={100}
      />
      <Rect
        ref={firstCollectorRef}
        {...firstAreaCurrentInfo}

        onDragMove={(e) =>
          handleDrag(e, firstAreaCurrentInfo, setFirstAreaCurrentInfo)
        }
        onDragEnd={(e) => {
          setFirstAreaProps({ ...e.target.attrs });
        }}
        onClick={(e) => {
          setSelectedRectFirst(true);
          setSelectedRectSecond(false);
        }}
        onMouseOver={() => (document.body.style.cursor = "grab")}
        onMouseOut={() => (document.body.style.cursor = "default")}
        cornerRadius={formRect === 'circle' ? 100 : 0}
      />
      {selectedRectFirst ? (
        <Transformer
            rotateEnabled={false}
            ref={transformerRef}
            anchorSize={5}
            centeredScaling={true}
            boundBoxFunc={(oldBox, newBox) => {
              let auxFirstInfo = {...firstAreaCurrentInfo};
              const heightOffset = newBox.height + 20;
              const widthOffset = newBox.width + 20;

              auxFirstInfo.height = heightOffset;
              auxFirstInfo.width = widthOffset;

              if (heightOffset > 150) {
                auxFirstInfo.height = 150;
              } 
              if (widthOffset > 150) {
                auxFirstInfo.width = 150;
              }              
              if (newBox.width < 15 || newBox.height < 15) {
                auxFirstInfo.height = 15;
                auxFirstInfo.width = 15;
              }

              setFirstAreaCurrentInfo(auxFirstInfo);
              return oldBox;
          }}
        />
      ) : null}
      {/* Área de captura 2 */}

      {/*Marcação central do círculo - mira*/}
      <Rect
        ref={secondCollectorRef}
        {...{  fill: 'red',
        width: 1,
        height: 8,
        draggable: true,
        x: secondAreaCurrentInfo.x + secondAreaCurrentInfo.width/2,
        y: secondAreaCurrentInfo.y + secondAreaCurrentInfo.height/2 - 4,}}
        cornerRadius={100}
      />
      <Rect
        ref={secondCollectorRef}
        {...{  fill: 'red',
        width: 8,
        height: 1,
        draggable: true,
        x: secondAreaCurrentInfo.x + secondAreaCurrentInfo.width/2 - 4,
        y: secondAreaCurrentInfo.y + secondAreaCurrentInfo.height/2,}}
        cornerRadius={100}
      />
      <Rect
        ref={secondCollectorRef}
        {...secondAreaCurrentInfo}
        onDragMove={(e) =>
          handleDrag(e, secondAreaCurrentInfo, setSecondAreaCurrentInfo)
        }
        onDragEnd={(e) => {
          setSecondAreaProps({ ...e.target.attrs });
        }}
        onClick={(e) => {
          setSelectedRectSecond(true);
          setSelectedRectFirst(false);
        }}
        onMouseOver={() => (document.body.style.cursor = "grab")}
        onMouseOut={() => (document.body.style.cursor = "default")}
        cornerRadius={formRect === 'circle' ? 100 : 0}
      />
      {selectedRectSecond ? (
        <Transformer
            rotateEnabled={false}
            ref={transformerRef}
            anchorSize={5}
            centeredScaling={true}
            rotationSnaps={[0, 0, 0, 0]}
            boundBoxFunc={(oldBox, newBox) => {
              let auxSecondInfo = {...secondAreaCurrentInfo};
              const heightOffset = newBox.height + 20;
              const widthOffset = newBox.width + 20;

              auxSecondInfo.height = heightOffset;
              auxSecondInfo.width = widthOffset;

              if (heightOffset > 150) {
                auxSecondInfo.height = 150;
              }
              if (widthOffset > 150) {
                auxSecondInfo.width = 150;
              }
              if (newBox.width < 15 || newBox.height < 15) {
                auxSecondInfo.height = 15;
                auxSecondInfo.width = 15;
              }

              setSecondAreaCurrentInfo(auxSecondInfo);
              return oldBox;
          }}
        />
      ) : null}
      {/* Texto área 1 */}
      {infoTemp(firstAreaCurrentInfo, firstAreaMaxTemperature, firstAreaMinTemperature, firstAreaAvgTemperature, true)}
      {/* Texto área 2 */}
      {infoTemp(secondAreaCurrentInfo, secondAreaMaxTemperature, secondAreaMinTemperature, secondAreaAvgTemperature, false)}
      {/* Texto área de resultado */}
      <Text
        x={resultAreaProps.x + 22}
        y={resultAreaProps.y + resultAreaProps.height - 20}
        text={`var: ${resultAreaValue ? resultAreaValue.toFixed(2) : 0.0}`}
        fontSize={1.2*TEXT_SIZE}
        height={TEXT_SIZE}
        stroke="black"
        strokeWidth={3}
        fill="white"
        fillAfterStrokeEnabled={true}
      />
      {/* Área de resultado */}
      <Rect
        ref={resultShapeRef}
        {...resultAreaProps}
        onDragMove={(e) => handleDrag(e, resultAreaProps, setResultAreaProps)}
        onMouseOver={() => (document.body.style.cursor = "grab")}
        onMouseOut={() => (document.body.style.cursor = "default")}
        onClick={() => {
          setSelectedRectFirst(false);
          setSelectedRectSecond(false);
        }}
        width={100}
      />
    </Layer>
  );
}

export default DeltaCalculation;
