import React from "react";

import { Container, CustomButton } from "./styles";

function ButtonToggle({ onClick, selected, icon }) {
  return (
    <Container>
      <CustomButton onClick={onClick} selected={selected}>
        {icon ? icon : null}
      </CustomButton>
    </Container>
  );
}

export default ButtonToggle;
