import styled from "styled-components";

export const Container = styled.div`
  height: 40px;
  padding: 0 16px 0 16px;
  align-items: center;
  display: flex;
  flex-direction: row;
  background-color: #f9f9f9;
  margin-right: 8px;
  flex-grow: 1;
  min-width: 350px;
  margin-bottom: 4px;
`;

export const SearchIcon = styled.img`
  width: 24px;
  height: 24px;
  user-select: none;
  pointer-events: none;
`;

export const Input = styled.input`
  border: none;
  height: 40px;
  background-color: transparent;
  padding-left: 12px;
  color: #525252;
  font-size: 16px;
  width: 100%;
`;
