import React from "react";

// Imgs
import UnChecked from "assets/unchecked.svg";
import Checked from "assets/checked.svg";

import {
  CollectTypesContainer,
  CollectTypeTitle,
  CollectTypeGroup,
  CollectTypeOptionsGroup,
  TypeCheckContainer,
  TypeCheckImg,
  TypeCheckText,
} from "./styles";

function CollectTypesForm({ companyData, setCompanyData, isEditingCustomer }) {
  return (
    <CollectTypesContainer>
      {companyData?.collectTypes?.map((collectType, index) => (
        <CollectTypeGroup key={`collectType-${index}`}>
          <CollectTypeTitle>{collectType.description}</CollectTypeTitle>
          <CollectTypeOptionsGroup>
            {collectType?.types?.map((type, index) => (
              <TypeCheckContainer
                key={`type-${index}`}
                disabled={!isEditingCustomer}
                onClick={() => {
                  if (isEditingCustomer) {
                    type.selected = !type.selected;
                    setCompanyData({ ...companyData });
                  }
                }}
              >
                <TypeCheckImg src={type.selected ? Checked : UnChecked} />
                <TypeCheckText>{type.description}</TypeCheckText>
              </TypeCheckContainer>
            ))}
          </CollectTypeOptionsGroup>
        </CollectTypeGroup>
      ))}
    </CollectTypesContainer>
  );
}

export default CollectTypesForm;
